import React, { useState , useEffect } from "react";
// import "../Styles/_FilterModal.scss";
import "../Styles/elemenets/_filters-grid.scss";
import "../Styles/elemenets/_filter_product_element.scss";
import "../Styles/elemenets/_filters-modal.scss";
import "../Styles/elemenets/_clear-filters.scss";
import "../Styles/elemenets/_checkbox.scss";
// import '../Styles/elemenets/_gdpr.scss'
// import '../Styles/elemenets/_flash.scss'
import "../Styles/elemenets/_cookie-notice.scss";
import "../Styles/elemenets/_presskit.scss";
import "../Styles/elemenets/_select.scss";
import "../Styles/_odameter.scss";
import "../Styles/_global.scss";
import "../Styles/_select2.scss";
// import '../Styles/lato.scss'
import "../Styles/elemenets/_flash.scss";
import "../Styles/elemenets/_input.scss";
import "../Styles/elemenets/_product-list.scss";
import "../Styles/elemenets/_related-products.scss";
import "../Styles/elemenets/_slick-dots.scss";
import "../Styles/elemenets/_table.scss";
import "../Styles/elemenets/_textarea.scss";
import "../Styles/elemenets/_tiles-content.scss";
import "../Styles/elemenets/_tiles-grid.scss";
import { toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import MetaData from "../Components/MetaData";

const FilterModal = () => {
  let navigate = useNavigate();
  const [isClose, setIsClose] = useState(false);

  const [Pictures, setPictures] = useState({});

  useEffect(() => {
    handleAllPicture();
  }, []);
  const handleAllPicture = async (e) => {
    try {
    //   setLoading(true);
      const { data } = await axios.get("/homePicture");
      if (data?.error) {
        // setLoading(false);
        toast.error(data.error, { position: "top-center", autoClose: 3000 });
      } else {
        console.log(data.Picture);
        setPictures(data.Picture);
      }
    } catch (err) {
      // setLoading(false);
      console.log(err);
    }
  };


  // const [uploading, setUploading] = useState(false);
  // const [Image, setImage] = useState("");




  return (
    <div id="filters-modal" className={isClose ? "" : "visible"}>
          <MetaData title='ProductsModel'/>

      <div className="filters-container">
        <div className="close-modal-container wrapper">
          <button
            className="close-modal"
            onClick={() => {
              setIsClose(true);
              navigate("/");
            }}
          >
            Close <span>×</span>
          </button>
        </div>
        <div className="filters-modal-content">
          <div className="filters-modal-wrap">
            <div className="wrapper">
              <div className="filters-nav">
                <ul className="inline-list">
                  <li className="active">
                    <Link
                      style={{ textDecoration: "none"}}
                      to="/productsmodal"
                      data-item="ziaja"
                    >
                      ziaja
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ textDecoration: "none", color: "gray" }}
                      to="/productsmodal-ziajamed"
                      data-item="ziaja"
                      onMouseEnter={(e) => (e.target.style.color = "#73aa43")} // Change text color to grey on hover
                      onMouseLeave={(e) => (e.target.style.color = "gray")}
                    >
                      ziaja MED
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ textDecoration: "none", color: "gray" }}
                      to="/productsmodal-dp"
                      data-item="ziaja"
                      onMouseEnter={(e) => (e.target.style.color = "#73aa43")} // Change text color to grey on hover
                      onMouseLeave={(e) => (e.target.style.color = "gray")}
                    >
                      DENOVA pro
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="filters">
                <form
                  name="filter-popup"
                  method="get"
                  action="/products/line/ziaja"
                  id="filter-modal"
                >
                  <div className="filters-form-items">
                    <div className="filter-items">
                      <div className="filters-row filters-row-checkbox">
                        <div className="form-group">
                          <label hidden="hidden" className="control-label">
                            Category
                          </label>{" "}
                          <div id="filter-popup_category" placeholder="">
                            <div className="checkbox">
                              <label
                                htmlFor="filter-popup_category_10010"
                                className=""
                                data-count={160}
                              >
                                <input
                                  type="checkbox"
                                  id="filter-popup_category_10010"
                                  name="filter-popup[category][]"
                                  data-count={160}
                                  defaultValue={10010}
                                />{" "}
                                body care
                              </label>
                            </div>
                            <div className="checkbox">
                              <label
                                htmlFor="filter-popup_category_10011"
                                className=""
                                data-count={171}
                              >
                                <input
                                  type="checkbox"
                                  id="filter-popup_category_10011"
                                  name="filter-popup[category][]"
                                  data-count={171}
                                  defaultValue={10011}
                                />{" "}
                                face care
                              </label>
                            </div>
                            <div className="checkbox">
                              <label
                                htmlFor="filter-popup_category_10013"
                                className=""
                                data-count={49}
                              >
                                <input
                                  type="checkbox"
                                  id="filter-popup_category_10013"
                                  name="filter-popup[category][]"
                                  data-count={49}
                                  defaultValue={10013}
                                />{" "}
                                hair care
                              </label>
                            </div>
                            <div className="checkbox">
                              <label
                                htmlFor="filter-popup_category_10312"
                                className=""
                                data-count={8}
                              >
                                <input
                                  type="checkbox"
                                  id="filter-popup_category_10312"
                                  name="filter-popup[category][]"
                                  data-count={8}
                                  defaultValue={10312}
                                />{" "}
                                oral hygiene
                              </label>
                            </div>
                            <div className="checkbox">
                              <label
                                htmlFor="filter-popup_category_10358"
                                className=""
                                data-count={316}
                              >
                                <input
                                  type="checkbox"
                                  id="filter-popup_category_10358"
                                  name="filter-popup[category][]"
                                  data-count={316}
                                  defaultValue={10358}
                                />{" "}
                                vegan
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="filters-row">
                        <div>
                          <div className="form-group">
                            <label
                              className="control-label"
                              htmlFor="filter-popup_type"
                            >
                              product Type
                            </label>{" "}
                            <select
                              id="filter-popup_type"
                              name="filter-popup[type][]"
                              placeholder="Search filters..."
                              className="form-control select2-hidden-accessible"
                              multiple=""
                              data-placeholder="Search filters..."
                              tabIndex={-1}
                              aria-hidden="true"
                            >
                              <option value={13649} data-count={4}>
                                after-shave balm
                              </option>
                              <option value={10262} data-count={7}>
                                anti-perspirants
                              </option>
                              <option value={10271} data-count={1}>
                                antibacterial and disinfecting
                              </option>
                              <option value={11348} data-count={4}>
                                bath jelly soaps
                              </option>
                              <option value={10255} data-count={5}>
                                body balms
                              </option>
                              <option value={10256} data-count={6}>
                                body butters
                              </option>
                              <option value={11873} data-count={5}>
                                bubble baths
                              </option>
                              <option value={10260} data-count={9}>
                                cleansing gels
                              </option>
                              <option value={10277} data-count={3}>
                                cleansing milks
                              </option>
                              <option value={10264} data-count={10}>
                                eye creams
                              </option>
                              <option value={10265} data-count={60}>
                                face creams
                              </option>
                              <option value={10266} data-count={27}>
                                face masks
                              </option>
                              <option value={10267} data-count={4}>
                                foot care
                              </option>
                              <option value={10269} data-count={11}>
                                hair conditioners
                              </option>
                              <option value={10270} data-count={8}>
                                hair masks
                              </option>
                              <option value={11865} data-count={20}>
                                hand creams
                              </option>
                              <option value={10272} data-count={6}>
                                hand soaps
                              </option>
                              <option value={10273} data-count={15}>
                                intimate care
                              </option>
                              <option value={10275} data-count={4}>
                                lip balms
                              </option>
                              <option value={13710} data-count={4}>
                                lip gloss
                              </option>
                              <option value={13713} data-count={4}>
                                lip scrub
                              </option>
                              <option value={10257} data-count={19}>
                                lotions
                              </option>
                              <option value={10276} data-count={17}>
                                make-up removers
                              </option>
                              <option value={11350} data-count={3}>
                                mists
                              </option>
                              <option value={11346} data-count={6}>
                                mousses
                              </option>
                              <option value={10313} data-count={2}>
                                mouthwashes
                              </option>
                              <option value={10278} data-count={7}>
                                oils
                              </option>
                              <option value={10279} data-count={8}>
                                peels
                              </option>
                              <option value={10280} data-count={18}>
                                scrubs
                              </option>
                              <option value={10281} data-count={14}>
                                serums
                              </option>
                              <option value={10282} data-count={27}>
                                shampoos
                              </option>
                              <option value={10284} data-count={44}>
                                shower gels
                              </option>
                              <option value={10285} data-count={8}>
                                special
                              </option>
                              <option value={10286} data-count={11}>
                                sprays
                              </option>
                              <option value={10287} data-count={8}>
                                sun care
                              </option>
                              <option value={10288} data-count={11}>
                                toners
                              </option>
                              <option value={10289} data-count={7}>
                                toothpastes
                              </option>
                            </select>
                            <span
                              className="select2 select2-container select2-container--default select2-container--below"
                              dir="ltr"
                              style={{ width: 346 }}
                            >
                              <span className="selection">
                                <span
                                  className="select2-selection select2-selection--multiple"
                                  role="combobox"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  tabIndex={-1}
                                >
                                  <ul className="select2-selection__rendered">
                                    <li className="select2-search select2-search--inline">
                                      <input
                                        className="select2-search__field"
                                        type="search"
                                        tabIndex={0}
                                        autoComplete="off"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        spellCheck="false"
                                        role="textbox"
                                        aria-autocomplete="list"
                                        placeholder="Search filters..."
                                        style={{ width: 339 }}
                                      />
                                    </li>
                                  </ul>
                                </span>
                              </span>
                              <span
                                className="dropdown-wrapper"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                        </div>
                        <div>
                          <div className="form-group">
                            <label
                              className="control-label"
                              htmlFor="filter-popup_skin"
                            >
                              skin
                            </label>{" "}
                            <select
                              id="filter-popup_skin"
                              name="filter-popup[skin][]"
                              placeholder="Search filters..."
                              className="form-control select2-hidden-accessible"
                              multiple=""
                              data-placeholder="Search filters..."
                              tabIndex={-1}
                              aria-hidden="true"
                            >
                              <option value={10079} data-count={7}>
                                acne prone
                              </option>
                              <option value={10080} data-count={165}>
                                all types
                              </option>
                              <option value={10082} data-count={1}>
                                atopic
                              </option>
                              <option value={10314} data-count={32}>
                                combination
                              </option>
                              <option value={10084} data-count={1}>
                                cracked
                              </option>
                              <option value={10085} data-count={35}>
                                dehydrated
                              </option>
                              <option value={10086} data-count={5}>
                                dilated capillaries
                              </option>
                              <option value={10087} data-count={108}>
                                dry
                              </option>
                              <option value={10324} data-count={8}>
                                dull
                              </option>
                              <option value={10088} data-count={12}>
                                irritated
                              </option>
                              <option value={10089} data-count={41}>
                                mature / wrinkle prone
                              </option>
                              <option value={10090} data-count={5}>
                                non-elastic
                              </option>
                              <option value={10091} data-count={70}>
                                normal
                              </option>
                              <option value={10315} data-count={33}>
                                oily
                              </option>
                              <option value={10093} data-count={9}>
                                pigmentation spots
                              </option>
                              <option value={10096} data-count={39}>
                                sensitive
                              </option>
                              <option value={10098} data-count={12}>
                                tired
                              </option>
                              <option value={10099} data-count={12}>
                                very dry
                              </option>
                            </select>
                            <span
                              className="select2 select2-container select2-container--default"
                              dir="ltr"
                              style={{ width: 344 }}
                            >
                              <span className="selection">
                                <span
                                  className="select2-selection select2-selection--multiple"
                                  role="combobox"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  tabIndex={-1}
                                >
                                  <ul className="select2-selection__rendered">
                                    <li className="select2-search select2-search--inline">
                                      <input
                                        className="select2-search__field"
                                        type="search"
                                        tabIndex={0}
                                        autoComplete="off"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        spellCheck="false"
                                        role="textbox"
                                        aria-autocomplete="list"
                                        placeholder="Search filters..."
                                        style={{ width: 337 }}
                                      />
                                    </li>
                                  </ul>
                                </span>
                              </span>
                              <span
                                className="dropdown-wrapper"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                        </div>
                        <div>
                          <div className="form-group">
                            <label
                              className="control-label"
                              htmlFor="filter-popup_series"
                            >
                              Line
                            </label>{" "}
                            <select
                              id="filter-popup_series"
                              name="filter-popup[series][]"
                              placeholder="Search filters..."
                              className="form-control select2-hidden-accessible"
                              multiple=""
                              data-placeholder="Search filters..."
                              tabIndex={-1}
                              aria-hidden="true"
                            >
                              <option value={10305} data-count={8}>
                                acai berry
                              </option>
                              <option value={10021} data-count={3}>
                                aloe
                              </option>
                              <option value={10022} data-count={4}>
                                anti-perspirants
                              </option>
                              <option value={14321} data-count={4}>
                                antioxidant hair care
                              </option>
                              <option value={10023} data-count={8}>
                                argan oil
                              </option>
                              <option value={10024} data-count={3}>
                                avocado
                              </option>
                              <option value={12498} data-count={6}>
                                baltic home spa vitality
                              </option>
                              <option value={11345} data-count={9}>
                                baltic home spa fit
                              </option>
                              <option value={12360} data-count={7}>
                                baltic home spa wellness
                              </option>
                              <option value={10025} data-count={7}>
                                BB &amp; CC creams
                              </option>
                              <option value={10028} data-count={7}>
                                cashmere
                              </option>
                              <option value={10030} data-count={3}>
                                ceramides
                              </option>
                              <option value={10031} data-count={13}>
                                cocoa butter
                              </option>
                              <option value={10032} data-count={3}>
                                coconut{" "}
                              </option>
                              <option value={14382} data-count={8}>
                                coconut &amp; orange vibes
                              </option>
                              <option value={10035} data-count={5}>
                                cucumber{" "}
                              </option>
                              <option value={10036} data-count={3}>
                                cupuacu
                              </option>
                              <option value={11315} data-count={12}>
                                delicious skin care
                              </option>
                              <option value={10038} data-count={4}>
                                eye creams &amp; gels
                              </option>
                              <option value={10306} data-count={11}>
                                face masks
                              </option>
                              <option value={13734} data-count={4}>
                                face masks and scrubs with acids
                              </option>
                              <option value={10066} data-count={5}>
                                family shampoos
                              </option>
                              <option value={10039} data-count={4}>
                                foot care
                              </option>
                              <option value={10326} data-count={11}>
                                gdanskin
                              </option>
                              <option value={10359} data-count={10}>
                                gift sets
                              </option>
                              <option value={10041} data-count={20}>
                                goat's milk
                              </option>
                              <option value={10044} data-count={7}>
                                hand care
                              </option>
                              <option value={10045} data-count={4}>
                                intimate creamy wash
                              </option>
                              <option value={10307} data-count={3}>
                                intimate foam washes
                              </option>
                              <option value={10046} data-count={2}>
                                intimate wash gel basic
                              </option>
                              <option value={10047} data-count={4}>
                                intimate wash gel herbal
                              </option>
                              <option value={10048} data-count={6}>
                                jasmine
                              </option>
                              <option value={10367} data-count={16}>
                                jeju
                              </option>
                              <option value={10049} data-count={4}>
                                lifting solution
                              </option>
                              <option value={13704} data-count={9}>
                                lip care
                              </option>
                              <option value={10050} data-count={8}>
                                make-up remover
                              </option>
                              <option value={10051} data-count={5}>
                                manuka tree
                              </option>
                              <option value={10052} data-count={2}>
                                marigold
                              </option>
                              <option value={10053} data-count={5}>
                                marine algae spa
                              </option>
                              <option value={10054} data-count={2}>
                                massage oils
                              </option>
                              <option value={10075} data-count={7}>
                                men
                              </option>
                              <option value={12507} data-count={8}>
                                men shower gels and after-shave balms
                              </option>
                              <option value={10309} data-count={6}>
                                mintperfect
                              </option>
                              <option value={10056} data-count={2}>
                                multivitamin
                              </option>
                              <option value={12482} data-count={14}>
                                natural care
                              </option>
                              <option value={10059} data-count={4}>
                                olive leaf
                              </option>
                              <option value={10057} data-count={16}>
                                olive oil
                              </option>
                              <option value={10060} data-count={4}>
                                orange butter{" "}
                              </option>
                              <option value={12376} data-count={8}>
                                pineapple skin care
                              </option>
                              <option value={10062} data-count={4}>
                                rose butter
                              </option>
                              <option value={10063} data-count={4}>
                                saffron
                              </option>
                              <option value={10065} data-count={6}>
                                sensitive skin
                              </option>
                              <option value={10040} data-count={8}>
                                shower gels &amp; bubble baths
                              </option>
                              <option value={10067} data-count={7}>
                                silk
                              </option>
                              <option value={10069} data-count={2}>
                                subtle bronze
                              </option>
                              <option value={10072} data-count={1}>
                                vaseline
                              </option>
                              <option value={13679} data-count={8}>
                                vitamin c.b3 niacinamide
                              </option>
                              <option value={10073} data-count={16}>
                                ziaja baby
                              </option>
                              <option value={10074} data-count={4}>
                                ziaja kids
                              </option>
                              <option value={10076} data-count={2}>
                                ziaja mum
                              </option>
                              <option value={10077} data-count={9}>
                                ziaja sun
                              </option>
                            </select>
                            <span
                              className="select2 select2-container select2-container--default"
                              dir="ltr"
                              style={{ width: 350 }}
                            >
                              <span className="selection">
                                <span
                                  className="select2-selection select2-selection--multiple"
                                  role="combobox"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  tabIndex={-1}
                                >
                                  <ul className="select2-selection__rendered">
                                    <li className="select2-search select2-search--inline">
                                      <input
                                        className="select2-search__field"
                                        type="search"
                                        tabIndex={0}
                                        autoComplete="off"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        spellCheck="false"
                                        role="textbox"
                                        aria-autocomplete="list"
                                        placeholder="Search filters..."
                                        style={{ width: 343 }}
                                      />
                                    </li>
                                  </ul>
                                </span>
                              </span>
                              <span
                                className="dropdown-wrapper"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="summary ">
                      <div className="summary-box">
                        <div className="found bg-dark">
                          <p> 
                             <span className="found-label">products Found </span>{" "}
                            <span
                              className="number odometer odometer-auto-theme"
                              data-max={400}
                            />
                            {/* {
                              console.log("object"),
                            <a href={Pictures.Picture1}>  <img src={Pictures.Picture1} alt="" /></a>
                            } */}
                          </p> 
                           <div className="odometer-inside">
                            <span className="odometer-digit">
                              <span className="odometer-digit-spacer"></span>
                              <span className="odometer-digit-inner">
                                <span className="odometer-ribbon">
                                  <span className="odometer-ribbon-inner">
                                    <span className="odometer-value">4</span>
                                  </span>
                                </span>
                              </span>
                            </span>
                            <span className="odometer-digit">
                              <span className="odometer-digit-spacer"></span>
                              <span className="odometer-digit-inner">
                                <span className="odometer-ribbon">
                                  <span className="odometer-ribbon-inner">
                                    <span className="odometer-value">0</span>
                                  </span>
                                </span>
                              </span>
                            </span>
                            <span className="odometer-digit">
                              <span className="odometer-digit-spacer"></span>
                              <span className="odometer-digit-inner">
                                <span className="odometer-ribbon">
                                  <span className="odometer-ribbon-inner">
                                    <span className="odometer-value">0</span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </div> 
                          <p />
                        </div>
                        <div
                          className="chosen-filters"
                          style={{ display: "none" }}
                        >
                          <h3>Active filters</h3>
                        </div>
                        <div
                          className="filters-clear filter-row"
                          style={{ display: "none" }}
                        >
                          <a href="#" className="clear-form">
                            <span className="filter-value">
                              Clear all filters
                              <span className="filter-value-remove">×</span>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="summary-actions">
                        <p>Adjust search criteria or</p>
                        <button type="submit">
                          <Link
                            style={{ textDecoration: "none", color: "white" }}
                            to="https://en.ziaja.com/products/line/ziaja?"
                          >
                            Show products
                          </Link>
                        </button>
                        <a style={{textDecoration:"none"}} className="close-modal" href="/">
                          Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
