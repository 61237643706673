import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  ProgressBar,
} from "react-bootstrap";
import "../../Styles/MyInvestment.css";
import MetaData from "../../Components/MetaData";
import { useAuth } from "../../context/auth";
import axios from "axios";
import { Avatar } from "antd";

import { toast } from "react-toastify";
import { AiTwotoneDollarCircle } from "react-icons/ai";
import { PiSortDescendingBold } from "react-icons/pi";
import { TbFileDescription } from "react-icons/tb";
// import {Avatar} from "antd";
import Resizer from "react-image-file-resizer";

import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link, useLocation } from "react-router-dom";
import "../../Styles/Login.css";


import  { Fragment, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { BiMoneyWithdraw } from "react-icons/bi";
import { SiWebmoney } from "react-icons/si";
import { FaUsers } from "react-icons/fa";
import { MdWifiProtectedSetup } from "react-icons/md";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime); // fromNow()  // 4 hours ago

const Chat = () => {




  const navigate = useNavigate();
  const location = useLocation();
  const [uploading, setUploading] = useState(false);
  const [Image, setImage] = useState("");
  const [username, setUserName] = useState("");
  const [title, setTitle] = useState("");
  const [issue, setIssue] = useState("");

  const [investment, setInvestment] = useState([{}]);

  const [auth, setAuth] = useAuth();
  const [loading1, setLoading1] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading1(true);
      const { data } = await axios.post(`/chat`, {
        picture: Image,
        username,
        title,
        issue,
      });
      if (data?.error) {
        toast.error(data.error, { position: "top-center", autoClose: 3000 });
        setLoading1(false);
      } else {
        // localStorage.setItem("investment", JSON.stringify(data.investment));
        // setAuth(data);/
        setLoading1(false);
        console.log(data);
        toast.info("Your Message send Successfully", {
          position: "top-center",
          autoClose: 3000,
        });
        // setImage("")
        setUserName("");
        setTitle("");
        setIssue("");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong. Try again!!");
      setLoading1(false);
    }
  };


  const [loading, setLoading] = useState(true);
  const [chat, setChat] = useState([]);
  useEffect(() => {
    handleUserWithDraw();
  }, []);
  // const containerStyle = {
  //   top: 0,
  //   left: 0,
  //   width: "95vw",
  //   height: "95vh",
  //   backgroundColor: "white",
  //   // overflow: "hidden",
  // };
  const handleUserWithDraw = async (e) => {
    try {
      setLoading(true);
      const { data } = await axios.get("/singleChatHistory");
      if (data?.error) {
        setLoading(false);
        toast.error(data.error, { position: "top-center", autoClose: 3000 });
      } else {
        console.log(data.singleChats);
        setChat(data.singleChats);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  
  const setSingleUserWithDraws = () => {
    const data = {
      columns: [
      
        {
            label: (
              <span
                style={{
                  color: "black",
                  textDecorationLine: "underline",
                }}
              >
                UserName
              </span>
            ),
            field: "UserName",
            sort: "asc",
          },
          {
            label: (
              <span
                style={{
                  color: "black",
                  textDecorationLine: "underline",
                }}
              >
                Picture
              </span>
            ),
            field: "Picture",
            sort: "asc",
          },
        
        // {
        //   label: "Transaction Id",
        //   field: "TransactionId",
        // },
        {
          label: (
            <span
              style={{
                color: "black",
                textDecorationLine: "underline",
              }}
            >
              Description
            </span>
          ),
          field: "issue",
          sort: "asc",
        },
        
        {
          label: (
            <span
              style={{
                color: "black",
                textDecorationLine: "underline",
              }}
            >
              Status
            </span>
          ),
          field: "Status",
          sort: "asc",
        },
        {
          label: (
            <span
              style={{
                color: "black",
                textDecorationLine: "underline",
              }}
            >
              Reply
            </span>
          ),
          field: "Reply",
          sort: "asc",
        },
        {
          label: (
            <span
              style={{
                color: "black",
                textDecorationLine: "underline",
              }}
            >
              Time
            </span>
          ),
          field: "Time",
        },
      ],
      rows: [],
    };
    chat?.forEach((invest) => {
      data.rows.push({
        // Name: `${invest?.chatUserId?.first_name} ${invest?.chatUserId?.last_name} `,
        UserName: `${invest?.username} `,
        // TransactionId: invest?.transactionId,
        // AccountId: invest?.AccountId,
        Status: invest?.status,
        issue: invest?.issue,
        Reply:invest?.reply,
        Time: dayjs(invest?.createdAt).fromNow(),
        Picture: (
          <a href={invest?.picture?.Location}>
            (
            <Avatar
              src={invest?.picture?.Location}
              // alt={invest.picture?[0].Key}
              shape="square"
              size="72"
              className="mx-1"
              // onClick={()=> deleteImageUpload()}
            />
            ){" "}
          </a>
        ),
        // Reason: invest?.Reason,
      });
    });
    return data;
  };


  const handleImageUpload = async (e) => {
    try {
      // const file = e.target.files[0];
      // const formData = new FormData();
      // formData.append("file", file);
      // const config = {
      //   headers: {
      //     "content-type": "multipart/form-data",
      //   },
      // };
      // const { data } = await axios.post("/upload", formData, config);
      // console.log(data);
      // setImage(data);
      // setLoading1(false);
      let file = e.target.files[0];
      if (file) {
        setUploading(true);
        new Promise(() => {
          Resizer.imageFileResizer(
            file,
            200,
            200,
            "JPEG",
            100,
            0,
            async (uri) => {
              console.log("UPLOAD Image:", uri);
              const { data } = await axios.post("/upload-image", {
                image: uri,
              });
              console.log(data);
              setImage(data);
              setUploading(false);
            },
            "base64"
          );
        });
      }
    } catch (err) {
      console.log(err);
      // 'setUploading'(false);
    }
  };


  return (
    <Container>
      <MetaData title="My-Investments" />

      <div className="row">
        <div className="col-12 col-md-12 col-sm-12">
          <Fragment>
            <h1
              className=" text-center "
              style={{
                color: "black",
                textDecorationLine: "underline",
                // textShadow: "2px 2px 4px #73aa43",
              }}
            >
            <b> CHAT HISTORY </b>
            </h1>
            <MDBDataTable
              data={setSingleUserWithDraws()}
              className="px-3 py-3 box-cart purple"
              bordered
              striped
              hover
            />
          </Fragment>
        </div>
      </div>
      <div className="row justify-content-between d-flex gap-5">
        
        {/* <div className="card col " style={{ width: "18rem" }}>
          <img
            class="card-img-top"
            src="./originalLogo.png"
            alt="Card image cap"
          />
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
          </div>
        </div> */}
      </div>
      <Row className="my-4">
        
      
        {/* <Col md={8}> */}
        {/* <Card  className="mb-3">
              <Card.Header> <b>STATUS : </b>{investment.Status}</Card.Header>
              {/* <ListGroup variant="flush">
                <ListGroup.Item>
                  Value: ${investment.value.toLocaleString()}
                  <ProgressBar
                    now={investment.progress}
                    label={`${investment.progress}%`}
                    className="mt-2"
                  />
                </ListGroup.Item>
              </ListGroup> */}
        {/* </Card> */}
        {/* </Col> */}
        <div className="align-items-center d-flex justify-content-center row mt-4">
          <form
            className="form_main h-100 fs-3 w-75 col-md-12"
            onSubmit={handleLogin}
          >
            <p
              className="display-3 fs-3 fw-bold"
              style={{
                color: "black",
                textDecorationLine: "underline",
                // textShadow: "2px 2px 4px #73aa43",
              }}
            >
              {" "}
              SUPPORT CHAT
            </p>
            {loading1 && (
              <p
                className="text-center"
                style={{ color: "#73aa43", marginRight: "75px" }}
              >
                {" "}
                <div className="spinner mb-4"></div>{" "}
              </p>
            )}
           

            <div className="inputContainer">
          <svg
            viewBox="0 0 24 24"
            fill="#2e2e2e"
            height="14"
            width="14"
            xmlns="http://www.w3.org/2000/svg"
            className="inputIcon"
          >
            <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM12 6C9.79 6 8 7.79 8 10C8 11.86 9.25 13.47 11 13.88V17H13V13.88C14.75 13.47 16 11.86 16 10C16 7.79 14.21 6 12 6Z" />
          </svg>

              <input
                placeholder="UserName"
                id="username"
                className="inputField"
                type="text"
                // disabled={auth?.user?.role.includes("Admin")}
                value={username}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
              />
            </div>
            <div className="inputContainer">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                height={20}
                width={20}
                xmlns="http://www.w3.org/2000/svg"
                className="inputIcon"
              >
                <PiSortDescendingBold />
              </svg>

              <input
                placeholder="Title"
                id="text"
                className="inputField"
                type="text"
                // disabled={auth?.user?.role.includes("Admin")}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            

            {/* <input
              defaultChecked="checked"
              type="checkbox"
              className="justify-content-start"
            />
            <div className="checkmark" /> */}

            <div className="inputContainer">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                height={20}
                width={20}
                strokeLinecap="round"
                strokeLinejoin="round"
                // disabled={auth?.user?.role.includes("Admin")}
                xmlns="http://www.w3.org/2000/svg"
                className="inputIcon"
              >
                <TbFileDescription />
              </svg>

              <input
                placeholder="Description"
                id="password"
                className="inputField"
                type="text"
                disabled={auth?.user?.role.includes("Admin")}
                value={issue}
                onChange={(e) => setIssue(e.target.value)}
              />
            </div>
            <div className="inputContainer">
              <label className="upload mb-3">
                {uploading ? "Processing..." : "Upload Picture"}
                <input
                  hidden
                  type="file"
                  accept="image/*"
                  multiple
                  disabled={auth?.user?.role.includes("Admin")}
                  onChange={handleImageUpload}
                />
              </label>
              <a href={Image?.Location} className="mx-3">
                (
                <Avatar
                  src={Image?.Location}
                  // alt={invest.picture?[0].Key}
                  shape="square"
                  size="72"
                  className="mx-1"
                  // onClick={()=> deleteImageUpload()}
                />
                ){" "}
              </a>
            </div>
            <button
              id="button"
              type="submit"
              className="text-white"
              disabled={loading1}
            >
              {loading1 ? "..Loading" : "Submit"}
            </button>
          </form>
        </div>
      </Row>
    </Container>
  );
};

export default Chat;
