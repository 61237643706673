import React, { useState, useEffect } from "react";
import "../Styles/_header.scss";
import "./header.css";
import "../Styles/elemenets/_submenu.scss";
import "../Styles/elemenets/_leaf-heading.scss";
import { useNavigate, Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  Form,
  FormControl,
  Button,
  InputGroup,
} from "react-bootstrap";
import { useAuth } from "../context/auth";

const NewHeader = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [Login, setIsLoggedIn] = useState(true);
  const [auth, setAuth] = useAuth();

  const logout = () => {
    setAuth({ user: null, token: "", refreshToken: "" });
    localStorage.removeItem("auth");
    navigate("/login");
  };
  const navigate = useNavigate();

  const loggedIn =
    auth.user !== null && auth.token !== "" && auth.refreshToken !== "";

  const investmentHandler = () => {
    if (loggedIn) {
      navigate("/investment");
    } else {
      navigate("/login", {
        state: `/investment`,
      });
    }
  };

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    }

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial call to set initial state

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    
    <Navbar
    collapseOnSelect
    expand="lg"
    bg="white"
    variant="light"
    // style={{ maxHeight: "9rem" }}
    className="mb-2"
  >
    <Navbar.Brand href="/">
      <img
        style={{ backgroundColor: "transparent" }}
        // src={require("../media/Logo-removebg1.png")}
        src={("./ogo.png")}
        width="100" // Set the width you desire
        height="auto" // Height will be set automatically
        className="d-inline-block align-top ms-5"
        alt="Ziaja International"
      />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="me-auto">
        <Navbar.Text
        // href="/productsmodal"
        >
          <Link
            onMouseEnter={(e) => (e.target.style.color = "lightgray")} // Change text color to grey on hover
            onMouseLeave={(e) => (e.target.style.color = "black")} // Change text color back to black on mouse leave
            data-item="ziaja"
            to="/productsmodal"
            style={{
              textDecoration: "none",
              color: "black",
              margin: "1em",
              fontWeight: "",
            }}
          >
            {/* <i className="mobile-icon icon-grid" aria-hidden="true" /> */}
            PRODUCTS
          </Link>
        </Navbar.Text>
        <Navbar.Text>
          <Link
          className=" ms-lg-0 ms-4"
            onMouseEnter={(e) => (e.target.style.color = "lightgray")} // Change text color to grey on hover
            onMouseLeave={(e) => (e.target.style.color = "black")} // Change text color back to black on mouse leave
            to="/contact"
            //   href="https://en.ziaja.com/contact"
            style={{ textDecoration: "none", color: "black" }}
          >
            CONTACT
          </Link>
        </Navbar.Text>
      
          <Navbar.Text  onClick={investmentHandler}>
            {" "}
            <Link
            
              onMouseEnter={(e) => (e.target.style.color = "lightgray")} // Change text color to grey on hover
              onMouseLeave={(e) => (e.target.style.color = "black")} // Change text color back to black on mouse leave
              to="/investment"
              style={{
                textDecoration: "none",
                color: "black",
                margin: "1em",
              }}
            >
              INVESTMENT
            </Link>
          </Navbar.Text>
      
       
         { loggedIn &&  <Navbar.Text>
            {" "}
            <Link
              onMouseEnter={(e) => (e.target.style.color = "lightgray")} // Change text color to grey on hover
              onMouseLeave={(e) => (e.target.style.color = "black")} // Change text color back to black on mouse leave
              to="/admin/dashboard"
              style={{
                textDecoration: "none",
                color: "black",
                margin: "1em",
              }}
            >
           {  auth?.user?.role.includes("Admin") && "ADMIN DASHBOARD"}
            </Link>
          </Navbar.Text>}
     
        {/* Add other links as needed */}
      </Nav>
      <nav id="nav">
        <ul className="secondary-nav inline-list">
          <li>
            <Link
            className=" ms-lg-0 ms-4"
              onMouseEnter={(e) => (e.target.style.color = "lightgray")} // Change text color to grey on hover
              onMouseLeave={(e) => (e.target.style.color = "black")} // Change text color back to black on mouse leave
             
              to="https://en.ziaja.com/where-to-buy"
              style={{ textDecoration: "none", color: "black" }}
            >
              {/* <img
                className="desktop-marker"
                src="/images/theme/marker.png?v0p2R2ENe"
              />
              <i className="mobile-icon icon-location" aria-hidden="true" />{" "} */}
              WHERE TO BUY
            </Link>
          </li>
        </ul>
      </nav>{" "}
      <Form inline style={{ position: "relative", margin: "1em" }}>
        <div style={{ position: "relative", width: "100%" }}>
          <Form.Control
            style={{ borderRadius: "80px", fontSize: "15px" }}
            className="display-4"
            placeholder="SEARCH ON SITE"
            // aria-label="Username"
            aria-describedby="basic-addon1"
          />
          <span
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <i
              className="fa fa-search mb-2"
              style={{ fontSize: "12px", cursor: "pointer" }}
            ></i>
          </span>
        </div>
      </Form>
      {/* <form className="search-form" action="/search/results">
        <input
          type="search"
          required="required"
          name="q"
          placeholder="Search on site"
          defaultValue=""
        />
        <button type="submit">
          <i className="fa fa-search" aria-hidden="true" />
        </button>
        <div className="autocomplete-modal" />
      </form> */}
      <div className="lang-switcher">
        <Link
        className=" ms-lg-0 ms-4"
          onMouseEnter={(e) => (e.target.style.color = "lightgray")} // Change text color to grey on hover
          onMouseLeave={(e) => (e.target.style.color = "black")} // Change text color back to black on mouse leave
          to="/language"
          style={{
            textDecoration: "none",
            color: "black",
            // marginLeft: "1em",
          }}
        >
          <span title="United Kingdom">EN</span>
        </Link>
      </div>
      {/* <Form inline>
        <FormControl
          type="text"
          placeholder="Search on site"
          className="mr-sm-2"
        />
        <Button type="submit">Search</Button>
      </Form>
      <Nav>
        <Nav.Link href="/language">EN</Nav.Link> */}
    { !loggedIn ?  <Navbar.Text href="/login">
        <Link
          onMouseEnter={(e) => (e.target.style.color = "lightgray")} // Change text color to grey on hover
          onMouseLeave={(e) => (e.target.style.color = "black")} // Change text color back to black on mouse leave
          to="/login"
          style={{
            textDecoration: "none",
            color: "black",
            margin: "1em",
          }}
        >
          LOGIN
        </Link>
      </Navbar.Text> :  <Navbar.Text href="/login" onClick={logout}>
        <Link
          onMouseEnter={(e) => (e.target.style.color = "lightgray")} // Change text color to grey on hover
          onMouseLeave={(e) => (e.target.style.color = "black")} // Change text color back to black on mouse leave
          to="/login"
          style={{
            textDecoration: "none",
            color: "black",
            margin: "1em",
          }}
        >
          Logout
        </Link>
      </Navbar.Text>}
      {!loggedIn ? <Navbar.Text href="/sign-up" className="mx-3">
       <Link
          to="/sign-Up"
          style={{ textDecoration: "none", color: "black" }}
          onMouseEnter={(e) => (e.target.style.color = "lightgray")} // Change text color to grey on hover
          onMouseLeave={(e) => (e.target.style.color = "black")} // Change text color back to black on mouse leave
        >
          SIGN UP
        </Link>
      </Navbar.Text> : <Navbar.Text href="/sign-up" className="mx-3">
        <Link
         onMouseEnter={(e) => (e.target.style.color = "lightgray")} // Change text color to grey on hover
         onMouseLeave={(e) => (e.target.style.color = "black")} // Change text color back to black on mouse leave
          to="/profile"
          style={{ textDecoration: "none", color: "black" }}
        >{auth?.user?.username}
        </Link>
      </Navbar.Text>}
    </Navbar.Collapse>
  </Navbar>
   
  );
};

export default NewHeader;
