import React from "react";
import { NavLink } from "react-router-dom";
import "./FirstPage.css";
const ZiajaFirstPage = () => {
  return (
    <div className="mt-5 m-5">
      <div className="container border border-2 ">
        <ul className="d-flex justify-content-between mt-5 bar navColor">
          <NavLink className="name" to="/products1">
            <li className="fs-4 name  ">ZIAJA</li>
          </NavLink>
          <NavLink className="name ">
            <li className="fs-4 name ">ZIAJA MED</li>
          </NavLink>
          <NavLink className="name">
            {" "}
            <li className="fs-4 name ">DENOVA PRO</li>
          </NavLink>
        </ul>
        <hr />
        {/* <input
          type="range"
          class="form-range "
          id="customRange1"
        /> */}
      </div>

      {/* <label for="customRange1" class="form-label">
        Example range
      </label> */}
    </div>
  );
};

export default ZiajaFirstPage;
