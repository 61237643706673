import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";

import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../../context/auth";
import MetaData from "../../Components/MetaData";

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(true);
  };
  const toggleOldPasswordUnVisibility = () => {
    setShowOldPassword(false);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(true);
  };
  const toggleNewPasswordUnVisibility = () => {
    setShowNewPassword(false);
  };

  const [auth, setAuth] = useAuth();

  const navigate = useNavigate();

  const emailHandle = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { data } = await axios.post(`/change-password`, {
        oldPassword,
        newPassword,
      });
      if (data?.error) {
        toast.error(data.error, { position: "top-center", autoClose: 3000 });
        setLoading(false);
      } else {
        toast.success(data.message, {
          position: "top-center",
          autoClose: 3000,
        });
        navigate("/");
        setAuth({ ...auth, user: data.user });
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.response.data.error);
    }
  };
 
  return (
    <div >
      <div className="align-items-center d-flex justify-content-center ">
        <MetaData title="Change-Password" />
        <form className="form_main h-100 fs-3 w-50 " onSubmit={emailHandle}>
          <p
            className="display-3 fs-3"
            style={{ color: "black", textDecoration: "underline" }}
          >
            CHANGE PASSWORD
          </p>
          <div className=" fs-5" style={{ color: "black" }}>
            Secure Your Account Through Change Password
          </div>

          {loading && (
            <p
              className="text-center"
              style={{ color: "black", marginRight: "75px" }}
            >
              {" "}
              <div style={{ color: "black" }} className="spinner "></div>{" "}
            </p>
          )}

          <div className="inputContainer">
            <svg
              viewBox="0 0 16 16"
              fill="#2e2e2e"
              height={16}
              width={16}
              xmlns="http://www.w3.org/2000/svg"
              className="inputIcon"
            >
              <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
            </svg>
            <input
              placeholder="Old-Password"
              id="username"
              className="inputField"
              type={showOldPassword ? "text" : "password"}
              value={oldPassword}
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
            />
            <span className="eye-icon">
              {showOldPassword ? (
                <FaEyeSlash onClick={toggleOldPasswordUnVisibility} />
              ) : (
                <FaEye onClick={toggleOldPasswordVisibility} />
              )}
            </span>
          </div>

          <div className="inputContainer">
            <svg
              viewBox="0 0 16 16"
              fill="#2e2e2e"
              height={16}
              width={16}
              xmlns="http://www.w3.org/2000/svg"
              className="inputIcon"
            >
              <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
            </svg>
            <input
              placeholder="New-Password"
              id="username"
              className="inputField"
              type={showNewPassword ? "text" : "password"}
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
            <span className="eye-icon">
              {showNewPassword ? (
                <FaEyeSlash onClick={toggleNewPasswordUnVisibility} />
              ) : (
                <FaEye onClick={toggleNewPasswordVisibility} />
              )}
            </span>
          </div>

          <button id="button" type="submit" className="" disabled={loading}>
            {loading ? "..Loading" : "Change Password"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
