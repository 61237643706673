import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaCopy } from "react-icons/fa";
import { LuCopy } from "react-icons/lu";
import MetaData from "../../Components/MetaData";

const PaymentPage = () => {
  const [amount, setAmount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const loadedAmounts = JSON.parse(
      localStorage.getItem("selectedPackageAmounts") || "[]"
    );
    const totalAmount = loadedAmounts.reduce(
      (sum, current) => sum + current,
      0
    );
    setAmount(totalAmount); // Initialize the payment amount based on selected packages
  }, []);

  const handlePaymentSubmission = (event) => {
    event.preventDefault();
    console.log(`Processing payment for $${amount}`);
    alert(`Your payment of $${amount} has been processed successfully.`);
    navigate("/"); // Redirect to home or another appropriate route
  };

  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText("123456789")
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
      })
      .catch((err) => console.error("Failed to copy:", err));
  };

  return (
    <Container className="py-5 ">
      <MetaData title="Payment" />
      <h2
        className="text-center mb-4 display-4"
        style={{
          color: "#73aa43",
          textDecorationLine: "underline",
          textShadow: "2px 2px 4px #73aa43",
        }}
      >
        Make a Payment
      </h2>
      <p className="text-center ">
                      <b class=''>   Account ID : </b>{`123456789  `}
                        {/* <input
                          style={{ border: "1px solid #73aa43" }}
                          className="text-center mt-2"
                          type="text"
                          value={refrelLink}
                          readOnly
                        /> */}
                        <button
                          style={{ border: "1px solid #73aa43" }}
                          onClick={copyToClipboard}
                        >
                          {copied ? "Copied!" : <LuCopy />}
                        </button>
                      </p>
      <span
        className="text-center fs-3"
        style={{
          color: "#73aa43",
        }}
      >
        Please enter the amount you wish to pay below.
      </span>
      <Form onSubmit={handlePaymentSubmission}>
        <InputGroup className="mb-3 form-control-lg">
          <InputGroup.Text>$</InputGroup.Text>
          <FormControl
            type="number"
            placeholder="Amount to Pay"
            value={amount}
            readOnly // This field is now read-only
          />
        </InputGroup>
        
        <Button type="submit" variant="primary" size="lg"
                className="Button"
                // variant={`outline-primary`}
               
                style={{
                  backgroundColor: "#73aa43",
                  border: "2px solid #73aa43",
                }}
              >
                Pay Now
              </Button>
       
      </Form>
    </Container>
  );
};

export default PaymentPage;

// import React, { useState } from "react";
// import {
//   Container,
//   Row,
//   Col,
//   Alert,
//   Form,
//   Button,
//   InputGroup,
//   FormControl,
// } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBtc } from "@fortawesome/free-brands-svg-icons";

// const PaymentPage = () => {
//   const [amount, setAmount] = useState("");
//   const [paymentStatus, setPaymentStatus] = useState(null);

//   const handlePaymentSubmission = (event) => {
//     event.preventDefault();
//     if (amount && parseFloat(amount) > 0) {
//       console.log(`Processing payment for $${amount}`);
//       // Simulate a payment process
//       setPaymentStatus("success");
//       alert(`Your payment of $${amount} has been processed successfully.`);
//       setAmount(""); // Clear the amount after payment
//     } else {
//       setPaymentStatus("error");
//     }
//   };

//   return (
//     <Container className="py-5">
//       <h2 className="text-center mb-4">Make a Payment</h2>
//       <Form onSubmit={handlePaymentSubmission}>
//         <InputGroup className="mb-3">
//           <InputGroup.Text>$</InputGroup.Text>
//           <FormControl
//             type="number"
//             placeholder="Amount to Pay"
//             value={amount}
//             onChange={(e) => setAmount(e.target.value)}
//             min="0.01"
//             step="0.01"
//             required
//           />
//         </InputGroup>
//         <div className="d-grid">
//           <Button type="submit" variant="primary" size="lg">
//             Pay Now
//           </Button>
//         </div>
//       </Form>
//       {paymentStatus === "error" && (
//         <Alert variant="danger" className="mt-3">
//           Please enter a valid amount to process the payment.
//         </Alert>
//       )}
//     </Container>
//   );
// };

// export default PaymentPage;
