import React,{useEffect, useState} from "react";
// import { useSelector } from "react-redux";
// import {currentAdmin} from '../../functions/auth'
// import LoadingToRedirect from "./LoadingToRedirect";
import LoadingToRedirect from "./LoadingToRedirect";
import { useAuth } from "../context/auth";
import { useNavigate } from "react-router-dom";

function AdminRoute({ children }) {
    // const { user } = useSelector((state) => ({ ...state }));
    const [ok,setOk]=useState(false)
    const [auth , setAuth] = useAuth();
    let navigate=useNavigate();
    // const auth=localStorage.getItem("auth");
    // let admin=auth?.user?.role.includes("Admin")
    // const admin=localStorage.getItem("auth")?.user?.role.includes("Admin")
    let admin=auth?.user?.role.includes("Admin")
    // const admin=false
    useEffect(()=>{
        if(admin){
        // currentAdmin(user.token)
                setOk(true)
            }else
            {
                setOk(false)
        }
    },[admin])
    if (ok) {
    // authorized so return child components
        return children;    
    }else{
        navigate('/')
        
    }
        // not logged in so redirect to login page with the return url    
    
        // <LoadingToRedirect />
}

export default AdminRoute