import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  ProgressBar,
} from "react-bootstrap";
import "../../Styles/MyInvestment.css";
import MetaData from "../../Components/MetaData";
import { useAuth } from "../../context/auth";
import axios from "axios";
import { Avatar } from "antd";

import { toast } from "react-toastify";
import { AiTwotoneDollarCircle } from "react-icons/ai";
import { PiSortDescendingBold } from "react-icons/pi";
import { TbFileDescription } from "react-icons/tb";
// import {Avatar} from "antd";
import Resizer from "react-image-file-resizer";

import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link, useLocation } from "react-router-dom";
import "../../Styles/Login.css";

import { useEffect, useState } from "react";

const ChatReply = () => {
 

  const navigate = useNavigate();
  const location = useLocation();
  const [uploading, setUploading] = useState(false);
  const [userName, setUserName] = useState("");
  const [Image, setImage] = useState("");
  const [Reply, setReply] = useState("");

  //   const [investment, setInvestment] = useState([{}]);

  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { data } = await axios.post(`ChatReply`, {
        // image: Image,
        userName,
       
        Reply,
      });
      if (data?.error) {
        toast.error(data.error, { position: "top-center", autoClose: 3000 });
        setLoading(false);
      } else {
        setLoading(false);
        console.log(data);
        toast.info(data.message, {
          position: "top-center",
          autoClose: 3000,
        });
        // setImage("")
        setUserName("")
        setReply("");

      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong. Try again!!");
      setLoading(false);
    }
  };

  const handleImageUpload = async (e) => {
    try {
      // const file = e.target.files[0];
      // const formData = new FormData();
      // formData.append("file", file);
      // const config = {
      //   headers: {
      //     "content-type": "multipart/form-data",
      //   },
      // };
      // const { data } = await axios.post("/upload", formData, config);
      // console.log(data);
      // setImage(data);
      // setLoading(false);
      let file = e.target.files[0];
      if (file) {
        setUploading(true);
        new Promise(() => {
          Resizer.imageFileResizer(
            file,
            200,
            200,
            "JPEG",
            100,
            0,
            async (uri) => {
              console.log("UPLOAD Image:", uri);
              const { data } = await axios.post("/upload-image", {
                image: uri,
              });
              console.log(data);
              setImage(data);
              setUploading(false);
            },
            "base64"
          );
        });
      }
    } catch (err) {
      console.log(err);
      // 'setUploading'(false);
    }
  };
  return (
    <Container>
      <MetaData title="My-Investments" />
      <div className="row justify-content-between d-flex gap-5">
      
      
       
       
      </div>
    
      <Row className="my-4 justify-content-center mb-3">
       
       
        <div className="align-items-center d-flex justify-content-center row mt-4">
          <form
            className="form_main h-100 fs-3 w-50 col-md-12"
            onSubmit={handleLogin}
          >
            <p
              className="display-3 fs-3 fw-bold"
              style={{
                color: "black",
                textDecorationLine: "underline",
                // textShadow: "2px 2px 4px #73aa43",
              }}
            >
             CHATING PORFOLIO
            </p>
            {loading && (
              <p
                className="text-center"
                style={{ color: "#73aa43", marginRight: "75px" }}
              >
                {" "}
                <div className="spinner mb-4"></div>{" "}
              </p>
            )}
            

            <div className="inputContainer">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                height={20}
                width={20}
                xmlns="http://www.w3.org/2000/svg"
                className="inputIcon"
              >
                <AiTwotoneDollarCircle />
              </svg>

              <input
                placeholder="UserName"
                id="username"
                className="inputField"
                type="text"
                // disabled={auth?.user?.role.includes("Admin")}
                value={userName}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
              />
            </div>
        

        

            <div className="inputContainer">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                height={20}
                width={20}
                strokeLinecap="round"
                strokeLinejoin="round"
                // disabled={auth?.user?.role.includes("Admin")}
                xmlns="http://www.w3.org/2000/svg"
                className="inputIcon"
              >
                <TbFileDescription />
              </svg>

              <input
                placeholder="Reply"
                id="password"
                className="inputField"
                type="text"
                // disabled={auth?.user?.role.includes("Admin")}
                value={Reply}
                onChange={(e) => setReply(e.target.value)}
              />
            </div>
            {/* <div className="inputContainer">
              <label className="upload mb-3">
                {uploading ? "Processing..." : "Upload Picture"}
                <input
                  hidden
                  type="file"
                  accept="image/*"
                  multiple
                //   disabled={auth?.user?.role.includes("Admin")}
                  onChange={handleImageUpload}
                />
              </label>
              <a href={Image?.Location} className="mx-3">
                (
                <Avatar
                  src={Image?.Location}
                  // alt={invest.picture?[0].Key}
                  shape="square"
                  size="72"
                  className="mx-1"
                  // onClick={()=> deleteImageUpload()}
                />
                ){" "}
              </a>
            </div> */}
            <button
              id="button"
              type="submit"
              className="text-white"
              disabled={loading}
            >
              {loading ? "..Loading" : "Submit"}
            </button>
          </form>
        </div>
      </Row>
    </Container>
  );
};

export default ChatReply;
