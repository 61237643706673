import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "react-toastify/dist/ReactToastify.css";
import Header from "./Components/Header";
import Home from "./Pages/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {BrowserRouter as Router , Routes, Route} from "react-router-dom";
import Footer from "./Components/Footer";
import FilterModal from "./Modal/FilterModal";
import FilterModal2 from "./Modal/FilterModal2";
import ZiajaFirstPage from "./Modal/ZiajaFirstPage";
import FirstPage from "./Modal/FirstPage";
import NewHeader from "./Components/NewHeader";
import "./Styles/elemenets/_input.scss";
import "./Styles/elemenets/_textarea.scss";
import "./Styles/main3.css";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import Investment from "./Pages/Investment";
import MyInvestment from "./Pages/InvestmentPages/MyInvestment";
import Packages from "./Pages/InvestmentPages/Packages";
import History from "./Pages/InvestmentPages/History";
import Withdraw from "./Pages/InvestmentPages/Withdraw";
import PaymentPage from "./Pages/InvestmentPages/PayementPage";
import NewWithDraw from "./Pages/InvestmentPages/NewWithDraw";
import Contact from "./Pages/Contact";
import ZiajaMedFilter from "./Modal/ZiajaMedFilter";
import DenvaPro from "./Modal/DenvaPro";
import { AuthProvider, useAuth } from "./context/auth";
import ForgetPassword from "./Pages/ForgetPassword";
import OTPVerification from "./Pages/OTPVerification";
import Profile from "./Pages/Profile";
import PrivateRoute from "./routes/PrivateRoute";
import DashBoard from "./Components/DashBoard";
import { useEffect, useState } from "react";
import AllUser from "./Pages/AllUser";
import AllInvestment from "./Pages/AllInvestment";
import AdminRoute from "./functions/AdminRoute";
import ApproveUser from "./Pages/InvestmentPages/ApproveUser";
import AllWithDrawRequest from "./Pages/AllWithDrawRequest";
import ApproveWithDraw from "./Pages/InvestmentPages/ApproveWithDraw";
import RejectWithDraw from "./Pages/InvestmentPages/RejectWithDraw";
import WithDrawHistory from "./Pages/InvestmentPages/WithDrawHistory";
import AdminSetting from "./Pages/InvestmentPages/AdminSetting";
import ChangePassword from "./Pages/InvestmentPages/ChangePassword";
import AccessAccount from "./Pages/InvestmentPages/AccessAccount";
import RejectUserInvestment from "./Pages/InvestmentPages/RejectUserInvestment";
import Package2 from "./Pages/InvestmentPages/Package2";
import Chat from "./Pages/InvestmentPages/Chat";
import ChatHistory from "./Pages/InvestmentPages/ChatHistory";
import ChatReply from "./Pages/InvestmentPages/ChatReply";
import Picture from "./Pages/Picture";

// import "./Styles/_global.scss";
// import "./Styles/elemenets/_breadcrumbs.scss";

function App() {
  return (
    <Router>
        <div style={{ backgroundColor: "white" }}>
      
      {/* <Header /> */}
      <AuthProvider>
        <NewHeader />
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/auth/forget-password" element={<ForgetPassword />} />
          <Route path="/auth/access-account" element={<AccessAccount />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/auth/otp-verification" element={<OTPVerification />} />
          <Route path="/products" element={<FilterModal2 />} />
          <Route path="/products2" element={<ZiajaFirstPage />} />
          <Route path="/products1" element={<FirstPage />} />
          <Route path="/productsmodal-dp" element={<DenvaPro />} />
          <Route path="/productsmodal" element={<FilterModal />} />
          <Route path="/productsmodal-ziajamed" element={<ZiajaMedFilter />} />

          <Route path="/" element={<PrivateRoute />}>
            <Route path="/profile" element={<Profile />} />
            <Route path="/supportchat" element={<Chat />} />

            <Route path="/investment" element={<Investment />} />
            {/* <Route path="/products-filter" element={<FilterModal />} /> */}
            <Route path="/my-investments" element={<MyInvestment />} />
            <Route path="/history" element={<History />} />
            <Route path="/withdrawhistory" element={<WithDrawHistory />} />

            <Route path="/withdraw2" element={<Withdraw />} />
            <Route path="/withdraw" element={<NewWithDraw />} />

            <Route path="/packages" element={<Packages />} />
            <Route path="/package2" element={<Package2 />} />

            <Route path="/payment" element={<PaymentPage />} />
            <Route path="/changePassword" element={<ChangePassword />} />

            <Route path="/supportchat" element={<Chat />} />


            <Route
              path="/admin/dashboard"
              element={
                <AdminRoute>
                  <DashBoard />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/approve-investments"
              element={
                <AdminRoute>
                  <ApproveUser />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/withdraw"
              element={
                <AdminRoute>
                  <AllWithDrawRequest />
                </AdminRoute>
              }
            />
             <Route
              path="/admin/chatHistory"
              element={
                <AdminRoute>
                  <ChatHistory />
                </AdminRoute>
              }
            />
           
             <Route
              path="/admin/chatReply"
              element={
                <AdminRoute>
                  <ChatReply />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/picture"
              element={
                <AdminRoute>
                  <Picture />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/approvewithdraw"
              element={
                <AdminRoute>
                  <ApproveWithDraw />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/rejectwithdraw"
              element={
                <AdminRoute>
                  <RejectWithDraw />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/rejectdeposit"
              element={
                <AdminRoute>
                  <RejectUserInvestment />
                </AdminRoute>
              }
            />

            <Route
              path="/admin/admin-setting"
              element={
                <AdminRoute>
                  <AdminSetting />
                </AdminRoute>
              }
            />

            <Route
              path="/admin/all-users"
              element={
                <AdminRoute>
                  <AllUser />{" "}
                </AdminRoute>
              }
            />
            <Route
              path="/admin/all-investments"
              element={
                <AdminRoute>
                  <AllInvestment />{" "}
                </AdminRoute>
              }
            />
          </Route>
        </Routes>

      </AuthProvider>
      {/* <Footer /> */}
      
    </div>
  </Router>
  );
}

export default App;
