import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { toast } from "react-toastify";
import { Container, Row, Col, Card, ListGroup, Button } from "react-bootstrap";
import { MdOutlineSettings } from "react-icons/md";
import { BiMoneyWithdraw } from "react-icons/bi";
import { SiWebmoney } from "react-icons/si";
import { FaUsers } from "react-icons/fa";
import { MdWifiProtectedSetup } from "react-icons/md";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import MetaData from "../../Components/MetaData";
dayjs.extend(relativeTime); // fromNow()  // 4 hours ago

const WithDrawHistory = () => {
  const [loading, setLoading] = useState(true);
  const [withDraw, setWithDraw] = useState([{}]);
  useEffect(() => {
    handleUserWithDraw();
  }, []);
  // const containerStyle = {
  //   top: 0,
  //   left: 0,
  //   width: "95vw",
  //   height: "95vh",
  //   backgroundColor: "white",
  //   // overflow: "hidden",
  // };
  const handleUserWithDraw = async (e) => {
    try {
      setLoading(true);
      const { data } = await axios.get("/fetchSingleUserWithDraw");
      if (data?.error) {
        setLoading(false);
        toast.error(data.error, { position: "top-center", autoClose: 3000 });
      } else {
        console.log(data.WithDraws);
        setWithDraw(data.WithDraws);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const setUserWithDraws = () => {
    const data = {
      columns: [
        {
          label: (
            <span
              style={{
                color: "black",
                textDecorationLine: "underline",
              }}
            >
              Name
            </span>
          ),
          field: "Name",
          sort: "asc",
        },

        {
          label: (
            <span
              style={{
                color: "black",
                textDecorationLine: "underline",
              }}
            >
              Amount
            </span>
          ),
          field: "Amount",
        },
        // {
        //   label: "Transaction Id",
        //   field: "TransactionId",
        // },
        {
          label: (
            <span
              style={{
                color: "black",
                textDecorationLine: "underline",
              }}
            >
              Account ID
            </span>
          ),
          field: "AccountId",
        },
        {
          label: (
            <span
              style={{
                color: "black",
                textDecorationLine: "underline",
              }}
            >
              Status
            </span>
          ),
          field: "Status",
          sort: "asc",
        },
        {
          label: (
            <span
              style={{
                color: "black",
                textDecorationLine: "underline",
              }}
            >
              Reason
            </span>
          ),
          field: "Reason",
          sort: "asc",
        },
        {
          label: (
            <span
              style={{
                color: "black",
                textDecorationLine: "underline",
              }}
            >
              Invested Time
            </span>
          ),
          field: "InvestedTime",
        },
      ],
      rows: [],
    };
    withDraw?.forEach((invest) => {
      data.rows.push({
        Name: `${invest?.WithDrawRequestedBy?.first_name} ${invest?.WithDrawRequestedBy?.last_name} `,
        Amount: invest?.Amount,
        // TransactionId: invest?.transactionId,
        AccountId: invest?.AccountId,
        Status: invest?.Status,
        InvestedTime: dayjs(invest?.createdAt).fromNow(),
        Reason: invest?.Reason,
      });
    });
    return data;
  };
  return (
    <div className="container" >
      {/* <h1 className='text-center'>All Users</h1> */}

      <MetaData title={"Your WithDraw"} />
      <div className="row">
        <div className="col-12 col-md-12 col-sm-12">
          <Fragment>
            <h1
              className=" text-center "
              style={{
                color: "black",
                textDecorationLine: "underline",
                // textShadow: "2px 2px 4px #73aa43",
              }}
            >
              {" "}
              YOUR ALL WITHDRAW
            </h1>
            <MDBDataTable
              data={setUserWithDraws()}
              className="px-3 py-3 box-cart purple"
              bordered
              striped
              hover
            />
          </Fragment>
        </div>
      </div>
    </div>
  );
};

export default WithDrawHistory;
