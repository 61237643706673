import React, { useState, useEffect } from "react";
// import { Container, Navbar, Nav, ListGroup, Col, Row } from "react-bootstrap";
import { Container, Row, Col, Card, ListGroup, Button } from "react-bootstrap";
import { MdOutlineSettings } from "react-icons/md";
import { BiMoneyWithdraw } from "react-icons/bi";
import { SiWebmoney } from "react-icons/si";
import axios from "axios";
import { toast } from "react-toastify";
import { FaUsers } from "react-icons/fa";
import { MdWifiProtectedSetup } from "react-icons/md";
import { GiSkullCrossedBones } from "react-icons/gi";

import "./dashBoard.css";
import { Link } from "react-router-dom";
import MetaData from "./MetaData";
import { useAuth } from "../context/auth";

const DashBoard = () => {
  const [loading, setLoading] = useState(true);
  const [investments, setInvestments] = useState([]);
  const [WithDrawl, setWithDrawl] = useState([]);
  const [auth, setAuth] = useAuth();
  const [total, setTotal] = useState([]);

  useEffect(() => {
    handleAllUserInvestment();
  }, []);
  const handleAllUserInvestment = async (e) => {
    try {
      setLoading(true);
      const { data } = await axios.get("/admin/pendinginvestment");
      if (data?.error) {
        setLoading(false);
        toast.error(data.error, { position: "top-center", autoClose: 3000 });
      } else {
        console.log(data.pendingUser);
        console.log(data.total);

        setInvestments(data.pendingUser);
        setWithDrawl(data.pendingWithdrawl);

        setTotal(data.total);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  // const pendingInvest = investments.find((invest) =>
  //   invest.status.includes("pending")
  // );
  // console.log(pendingInvest);
  return (
    <Container fluid className="display-6">
      <MetaData title="DashBoard" />
      <Row className="mt-4">
        <Col md={4} lg={3} className="mb-4 ">
        <Card className='mt-3'>
            <Card.Header style={{ color: "#73aa43",
                  textDecorationLine: "underline",
                   fontWeight:"bold"}}>Admin Menu </Card.Header>
            <ListGroup variant="flush">
              {/* <ListGroup.Item> Dashboard</ListGroup.Item> */}
              <ListGroup.Item>
                <Link
                  to="/admin/all-users"
                  onMouseEnter={(e) => (e.target.style.color = "#73aa43" , (e.target.style.textDecoration = "underline") 
                )} // Change text color to grey on hover
                  onMouseLeave={(e) => (e.target.style.color = "black" , (e.target.style.textDecoration = "none")
                )} // Change text color back to black on mouse leave
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <FaUsers className="mx-1" />
                 <span  className="mx-2"> All Users </span>
                </Link>
              </ListGroup.Item>
              <ListGroup.Item>
                <Link
                 onMouseEnter={(e) => (e.target.style.color = "#73aa43" , (e.target.style.textDecoration = "underline") 
                )} // Change text color to grey on hover
                  onMouseLeave={(e) => (e.target.style.color = "black" , (e.target.style.textDecoration = "none")
                )} // Change text color back to black on mouse leave
                  style={{ textDecoration: "none", color: "black" }}
                  to="/admin/all-investments"
                >
                  <SiWebmoney className="mx-1" 
                    onMouseEnter={(e) => (e.target.style.color = "#73aa43" , (e.target.style.textDecoration = "underline") 
                  )} // Change text color to grey on hover
                    onMouseLeave={(e) => (e.target.style.color = "black" , (e.target.style.textDecoration = "none")
                  )} // Change text color back to black on mouse leave
                  />
                <span className="mx-2"
                > All Investments</span> 
                </Link>
              </ListGroup.Item>
              <ListGroup.Item>
                <Link
                 onMouseEnter={(e) => (e.target.style.color = "#73aa43" , (e.target.style.textDecoration = "underline") 
                )} // Change text color to grey on hover
                  onMouseLeave={(e) => (e.target.style.color = "black" , (e.target.style.textDecoration = "none")
                )} // Change text color back to black on mouse leave
                  style={{ textDecoration: "none", color: "black" }}
                  to="/admin/approve-investments"
                >
                  <MdWifiProtectedSetup className="mx-1"/>
                 <span className="mx-1"> Approve Investment</span>
                </Link>
              </ListGroup.Item>
              <ListGroup.Item>
                <Link
                 onMouseEnter={(e) => (e.target.style.color = "#73aa43" , (e.target.style.textDecoration = "underline") 
                )} // Change text color to grey on hover
                  onMouseLeave={(e) => (e.target.style.color = "black" , (e.target.style.textDecoration = "none")
                )} // Change text color back to black on mouse leave
                  style={{ textDecoration: "none", color: "black" }}
                  to="/admin/rejectdeposit"
                >
                  <GiSkullCrossedBones className="mx-1"/>
                 <span className="mx-1"> Reject Investment</span>
                </Link>
              </ListGroup.Item>
              <ListGroup.Item>
                <Link
                  onMouseEnter={(e) => (e.target.style.color = "#73aa43" , (e.target.style.textDecoration = "underline") 
                )} // Change text color to grey on hover
                  onMouseLeave={(e) => (e.target.style.color = "black" , (e.target.style.textDecoration = "none")
                )} // Change text color back to black on mouse leave
                  style={{ textDecoration: "none", color: "black" }}
                  to="/admin/withdraw"
                >
                  <BiMoneyWithdraw className="mx-1" /> <span>Withdrawal Requests</span>
                </Link>
              </ListGroup.Item>
              <ListGroup.Item>
                  <Link
                    onMouseEnter={(e) => (
                      (e.target.style.color = "#73aa43"),
                      (e.target.style.textDecoration = "underline"),
                      (e.target.style.fontWeight = "bold")
                    )} // Change text color to grey on hover
                    onMouseLeave={(e) => (
                      (e.target.style.color = "black"),
                      (e.target.style.textDecoration = "none"),
                      (e.target.style.fontWeight = "normal")
                    )} // Change text color back to black on mouse leave
                    style={{ textDecoration: "none", color: "black" }}
                    to="/admin/chatHistory"
                  >
                    <MdWifiProtectedSetup className="mx-1" />
                    <span className="mx-1"> Chat History</span>
                  </Link>
                </ListGroup.Item>
              <ListGroup.Item>
                <Link
                  onMouseEnter={(e) => (e.target.style.color = "#73aa43" , (e.target.style.textDecoration = "underline") 
                )} // Change text color to grey on hover
                  onMouseLeave={(e) => (e.target.style.color = "black" , (e.target.style.textDecoration = "none")
                )} // Change text color back to black on mouse leave
                  to="/admin/admin-setting"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <MdOutlineSettings className="mx-1" />
                <span className="mx-1">  Admin Settings</span>
                </Link>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
        <Col md={8} lg={9}>
          <Row>
            <Col md={4} className="mb-4">
              <Card>
                <Card.Body>
                  <Card.Title style={{ color: "#73aa43" }}>
                    Total Pending Investments
                  </Card.Title>
                  <Card.Text><b>{total}</b></Card.Text>
                  <Button
                    className="Button"
                    // variant={`outline-primary`}
                    size="lg"
                    style={{
                      backgroundColor: "#73aa43",
                      border: "2px solid #73aa43",
                    }}
                  >
                    pending Investment
                  </Button>{" "}
                </Card.Body>
              </Card>
            </Col>

            <Col md={4} className="mb-4">
              <Card>
                <Card.Body>
                  <Card.Title style={{ color: "#73aa43" }}>
                    Current WithDrawl Limit
                  </Card.Title>
                  <Card.Text>
                    <span>{auth?.user?.WithDrawLimit}</span>
                  </Card.Text>
                  <Link to="/admin/admin-setting">
                    {" "}
                    <Button
                      className="Button"
                      // variant={`outline-primary`}
                      size="lg"
                      style={{
                        backgroundColor: "#73aa43",
                        border: "2px solid #73aa43",
                      }}
                    >
                      Update WithDrawl Limit
                    </Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4} className="mb-4">
              <Card>
                <Card.Body>
                  <Card.Title style={{ color: "#73aa43" }}>
                    {" "}
                    Total Pending Withdrawals
                  </Card.Title>
                  <Card.Text><b>{WithDrawl.length}</b></Card.Text>
                  <Button
                    className="Button"
                    // variant={`outline-primary`}
                    size="lg"
                    style={{
                      backgroundColor: "#73aa43",
                      border: "2px solid #73aa43",
                    }}
                  >
                    pending Withdrawals
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Header style={{ color: "#73aa43" ,  textDecorationLine: "underline",}}>Recent Activity</Card.Header>
                <Card.Body>
                  <Card.Text>
                    Here you can put tables or charts for recent user activity,
                    transaction logs, etc.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default DashBoard;
