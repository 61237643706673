import React from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import "../../Styles/package.css";
import { NavLink, Link } from "react-router-dom";
// import "../../Components/dashBoard.css"


const Package2 = () => {
  // const containerStyle = {
  //   top: 0,
  //   left: 0,
  //   width: "95vw",
  //   height: "135vh",
  //   backgroundColor: "dark",
  //   // overflow: "hidden",
  // };
  return (
    <body className="" >
      <div className="container mt-5">
        {/* <h1 className="text-center">Package 2</h1> */}
        <div className="row mt-5">
          <div className="d-flex justify-content-center">
            {/* <div className="col-12 col-md-12 col-sm-12"></div> */}
            <h1
              className="text-center mt-5 text-bold  fw-bold col-lg-6 col-sm-12 col-md-10  py-1"
              style={{ backgroundColor: "white", border: "2px solid #73aa43" , borderRadius:"50px" , color:"#73aa43" }}
            >
              STARTER PACKAGES AT ZIAJA
            </h1>
          </div>
        </div>
        <div className="row d-flex justify-content-around" style={{ border: "6px solid #73aa43" , borderRadius:"20px" }}>
         <div className="col-4 mt-3 mb-3 mx-3  rounded-5 Button"style={{ border: "3px solid #73aa43" ,borderStyle: "double",borderWidth: "6px",   }}> 
          <div>          <img
                      
        style={{ backgroundColor: "transparent"  }}
        // src={require("../media/Logo-removebg1.png")}
        src={("./ogo.png")}
       
        className="d-flex justify-content-end w-25 h-25 h1 mt-2"
        alt="Ziaja International"
      /> </div>  <div className="text-center  fw-bold display-1 "  style={{color:"#73aa43"}}>10$
    <div className="mt-5">
       <p
      className="text-center  fw-bold fs-5 text-dark"
      style={{
        textDecoration: "underline",
      }}
    >
      Monthly Return{" "}
      <div className="text-center fw-bold text-dark fs-1" style={{color:"#73aa43",}}>
        15%
      </div>
    </p>
    {/* <br /> */}
    <p
      className="text-center  fw-bold fs-5 text-dark mt-5"
      style={{
        textDecoration: "underline",
      }}
    >
      Total Return{" "}
      <div className="text-center fw-bold text-dark fs-1 mb-5">
        3X
      </div>
    <Link to="/my-investments">  <button type="button" class="btn btn-secondary btn-lg w-75 rounded-5 try" >Try Now</button> </Link>
      </p>
    
    </div>
    
    </div>
            </div>

          <div className="col-4 mt-3 mb-3 mx-3  rounded-5 Button"style={{ border: "3px solid #73aa43" ,borderStyle: "double",borderWidth: "6px",   }}> 
          <div>          <img
                      
        style={{ backgroundColor: "transparent"  }}
        // src={require("../media/Logo-removebg1.png")}
        src={("./ogo.png")}
       
        className="d-flex justify-content-end w-25 h-25 h1 mt-2"
        alt="Ziaja International"
      /> </div> 
       <div className="text-center  fw-bold display-1 "  style={{color:"#73aa43"}}>30$
    <div className="mt-5">
       <p
      className="text-center  fw-bold fs-5 text-dark"
      style={{
        textDecoration: "underline",
      }}
    >
      Monthly Return{" "}
      <div className="text-center fw-bold text-dark fs-1" style={{color:"#73aa43",}}>
        15%
      </div>
    </p>
    {/* <br /> */}
    <p
      className="text-center  fw-bold fs-5 text-dark mt-5"
      style={{
        textDecoration: "underline",
      }}
    >
      Total Return{" "}
      <div className="text-center fw-bold text-dark fs-1 mb-5">
        3X
      </div>
    <Link to="/my-investments">  <button type="button" class="btn btn-secondary btn-lg w-75 rounded-5 try">Try Now</button> </Link>
      </p>
    
    </div>
    
    </div>
            </div>

            <div className="col-4 mt-3 mb-3 mx-3  rounded-5 Button"style={{ border: "3px solid #73aa43" ,borderStyle: "double",borderWidth: "6px",   }}> 
          <div>          <img
                      
        style={{ backgroundColor: "transparent"  }}
        // src={require("../media/Logo-removebg1.png")}
        src={("./ogo.png")}
       
        className="d-flex justify-content-end w-25 h-25 h1 mt-2"
        alt="Ziaja International"
      /> </div>
        <div className="text-center  fw-bold display-1 "  style={{color:"#73aa43"}}>50$
    <div className="mt-5">
       <p
      className="text-center  fw-bold fs-5 text-dark"
      style={{
        textDecoration: "underline",
      }}
    >
      Monthly Return{" "}
      <div className="text-center fw-bold text-dark fs-1" style={{color:"#73aa43",}}>
        15%
      </div>
    </p>
    {/* <br /> */}
    <p
      className="text-center  fw-bold fs-5 text-dark mt-5"
      style={{
        textDecoration: "underline",
      }}
    >
      Total Return{" "}
      <div className="text-center fw-bold text-dark fs-1 mb-5">
        3X
      </div>
    <Link to="/my-investments">  <button type="button" class="btn btn-secondary btn-lg w-75 rounded-5 try" >Try Now</button> </Link>
      </p>
    
    </div>
    
    </div>
            </div>

            <div className="col-4 mt-3 mb-3 mx-3  rounded-5 Button"style={{ border: "3px solid #73aa43" ,borderStyle: "double",borderWidth: "6px",   }}> 
          <div>          <img
                      
        style={{ backgroundColor: "transparent"  }}
        // src={require("../media/Logo-removebg1.png")}
        src={("./ogo.png")}
       
        className="d-flex justify-content-end w-25 h-25 h1 mt-2"
        alt="Ziaja International"
      /> </div>  <div className="text-center  fw-bold display-1 "  style={{color:"#73aa43"}}>100$
    <div className="mt-5">
       <p
      className="text-center  fw-bold fs-5 text-dark"
      style={{
        textDecoration: "underline",
      }}
    >
      Monthly Return{" "}
      <div className="text-center fw-bold text-dark fs-1" style={{color:"#73aa43",}}>
        15%
      </div>
    </p>
    {/* <br /> */}
    <p
      className="text-center  fw-bold fs-5 text-dark mt-5"
      style={{
        textDecoration: "underline",
      }}
    >
      Total Return{" "}
      <div className="text-center fw-bold text-dark fs-1 mb-5">
        3X
      </div>
    <Link to="/my-investments">  <button type="button" class="btn btn-secondary btn-lg w-75 rounded-5 try">Try Now</button> </Link>
      </p>
    
    </div>
    
    </div>
            </div>

            <div className="col-4 mt-3 mb-3 mx-3  rounded-5 Button"style={{ border: "3px solid #73aa43" ,borderStyle: "double",borderWidth: "6px",   }}> 
          <div>          <img
                      
        style={{ backgroundColor: "transparent"  }}
        // src={require("../media/Logo-removebg1.png")}
        src={("./ogo.png")}
       
        className="d-flex justify-content-end w-25 h-25 h1 mt-2"
        alt="Ziaja International"
      /> </div>  <div className="text-center  fw-bold display-1 "  style={{color:"#73aa43"}}>200$
    <div className="mt-5">
       <p
      className="text-center  fw-bold fs-5 text-dark"
      style={{
        textDecoration: "underline",
      }}
    >
      Monthly Return{" "}
      <div className="text-center fw-bold text-dark fs-1" style={{color:"#73aa43",}}>
        15%
      </div>
    </p>
    {/* <br /> */}
    <p
      className="text-center  fw-bold fs-5 text-dark mt-5"
      style={{
        textDecoration: "underline",
      }}
    >
      Total Return{" "}
      <div className="text-center fw-bold text-dark fs-1 mb-5">
        3X
      </div>
    <Link to="/my-investments " className="">  <button type="button" class="btn btn-secondary btn-lg w-75 rounded-5 try">Try Now</button> </Link>
      </p>
    
    </div>
    
    </div>
            </div>

            <div className="col-4 mt-3 mb-3 mx-3  rounded-5 Button"style={{ border: "3px solid #73aa43" ,borderStyle: "double",borderWidth: "6px",   }}> 
          <div>          <img
                      
        style={{ backgroundColor: "transparent"  }}
        // src={require("../media/Logo-removebg1.png")}
        src={("./ogo.png")}
       
        className="d-flex justify-content-end w-25 h-25 h1 mt-2"
        alt="Ziaja International"
      /> </div>  <div className="text-center  fw-bold display-1 "  style={{color:"#73aa43"}}>500$
    <div className="mt-5">
       <p
      className="text-center  fw-bold fs-5 text-dark"
      style={{
        textDecoration: "underline",
      }}
    >
      Monthly Return{" "}
      <div className="text-center fw-bold text-dark fs-1" style={{color:"#73aa43",}}>
        15%
      </div>
    </p>
    {/* <br /> */}
    <p
      className="text-center  fw-bold fs-5 text-dark mt-5"
      style={{
        textDecoration: "underline",
      }}
    >
      Total Return{" "}
      <div className="text-center fw-bold text-dark fs-1 mb-5">
        3X
      </div>
    <Link to="/my-investments">  <button type="button" class="btn btn-secondary btn-lg w-75 rounded-5 try" >Try Now</button> </Link>
      </p>
    
    </div>
    
    </div>
            </div>

        </div>


        {/* <div className="mt-3 rounded-4" style={{ border: "3px solid #73aa43" }}>
          <div
            className="container rounded-4 "
            style={{ border: "3px solid #73aa43" }}
          >
            <div className="row grid mt-5 mb-5 d-flex justify-content-around  text-dark"  >
              <div
                className="d-flex justify-content-around  "
               
              >
                <div className="  text-dark g-col-6 rounded-4 d-flex justify-content-center "   style={{
                        // width: "40rem",
                        // height: "40rem",
                        borderRadius: "50%",
                        // backgroundColor: "light",
                        // border: "2px solid black",
                        borderStyle: "double",
                    borderWidth: "6px",
                    borderColor: "#73aa43",
                      }}>
                  <NavLink to="/my-investments" style={{textDecoration:"none" , }}>
                    <Card
                      className="text-dark rounded-5 Button  "
                    //   style={{
                    //     // width: "40rem",
                    //     // height: "40rem",
                    //     borderRadius: "50%",
                    //     // backgroundColor: "light",
                    //     // border: "2px solid black",
                    //     borderStyle: "double",
                    // borderWidth: "6px",
                    // borderColor: "#73aa43",
                    //   }}
                    >
                      <Card.Body className="Button">
                      <img
                      
        style={{ backgroundColor: "transparent"  }}
        // src={require("../media/Logo-removebg1.png")}
        src={("./ogo.png")}
        width="100" // Set the width you desire
        height="auto" // Height will be set automatically
        className="d-flex justify-content-end"
        alt="Ziaja International"
      />                        <Card.Title
                          className="text-center fw-bold text-dark fs-2 mt-5 d-flex justify-content-center"
                          // style={{
                          //   textDecoration: "none",
                          //   // textDecoration: "underline",
                          // }}
                        >
                        <h1 className="fw-bold mt-5"  style={{
                            // :" 0.4rem 0.4rem 3rem #73aa43",
                                        // textShadow: "4px 4px 4px #73aa43",
                            color:"#73aa43",
                            fontSize:"80px"

                          }}>10$</h1>  
                          <br /><br /><br /><br /><br />
                        </Card.Title>
                        <Card.Text className="  text-dark" style={{textDecoration:"none"}}>
                        <p
                            className="text-center  fw-bold fs-5 text-dark"
                            style={{
                              textDecoration: "underline",
                            }}
                          >
                            Monthly Return{" "}
                            <div className="text-center fw-bold text-dark fs-1" style={{color:"#73aa43",}}>
                              15%
                            </div>
                          </p>
                          <br />
                          <p
                            className="text-center  fw-bold fs-5 text-dark"
                            style={{
                              textDecoration: "underline",
                            }}
                          >
                            Total Return{" "}
                            <div className="text-center fw-bold text-dark fs-1">
                              3X
                            </div>
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </NavLink>
                </div>

                <div className="col-6  text-dark rounded-4 d-flex justify-content-center">
                  <NavLink to="/my-investments" style={{textDecoration:"none"}}>
                    <Card
                      className="text-dark rounded-5 Button"
                      style={{
                        width: "40rem",
                        height: "40rem",
                        borderRadius: "50%",
                        // backgroundColor: "light",
                        // border: "2px solid black",
                        borderStyle: "double",
                    borderWidth: "6px",
                    borderColor: "#73aa43",
                      }}
                    >
                      <Card.Body className="Button">
                      <img
                      
        style={{ backgroundColor: "transparent"  }}
        // src={require("../media/Logo-removebg1.png")}
        src={("./ogo.png")}
        width="100" // Set the width you desire
        height="auto" // Height will be set automatically
        className="d-flex justify-content-end"
        alt="Ziaja International"
      />                        <Card.Title
                          className="text-center fw-bold text-dark fs-2 mt-5 d-flex justify-content-center"
                          // style={{
                          //   textDecoration: "none",
                          //   // textDecoration: "underline",
                          // }}
                        >
                        <h1 className="fw-bold mt-5"  style={{
                            // :" 0.4rem 0.4rem 3rem #73aa43",
                                        // textShadow: "4px 4px 4px #73aa43",
                            color:"#73aa43",
                            fontSize:"80px"

                          }}>30$</h1>  
                          <br /><br /><br /><br /><br />
                        </Card.Title>
                        <Card.Text className="  text-dark" style={{textDecoration:"none"}}>
                        <p
                            className="text-center  fw-bold fs-5 text-dark"
                            style={{
                              textDecoration: "underline",
                            }}
                          >
                            Monthly Return{" "}
                            <div className="text-center fw-bold text-dark fs-1" style={{color:"#73aa43",}}>
                              15%
                            </div>
                          </p>
                          <br />
                          <p
                            className="text-center  fw-bold fs-5 text-dark"
                            style={{
                              textDecoration: "underline",
                            }}
                          >
                            Total Return{" "}
                            <div className="text-center fw-bold text-dark fs-1">
                              3X
                            </div>
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </NavLink>
                </div>
                

               
              </div>
            </div>

            <div className="row mt-5 mb-5 text-dark ">
              <div
                className="d-flex justify-content-evenly "
                // style={{ marginLeft: "8px" }}
              >
                

                
                <div className="col-6  text-dark rounded-4 d-flex justify-content-center">
                  <NavLink to="/my-investments" style={{textDecoration:"none"}}>
                    <Card
                      className="text-dark rounded-5"
                      style={{
                        width: "40rem",
                        height: "40rem",
                        borderRadius: "50%",
                        // backgroundColor: "light",
                        // border: "2px solid black",
                        borderStyle: "double",
                    borderWidth: "6px",
                    borderColor: "#73aa43",
                      }}
                    >
                      <Card.Body  className="Button">
                      <img
                      
        style={{ backgroundColor: "transparent"  }}
        // src={require("../media/Logo-removebg1.png")}
        src={("./ogo.png")}
        width="100" // Set the width you desire
        height="auto" // Height will be set automatically
        className="d-flex justify-content-end"
        alt="Ziaja International"
      />                        <Card.Title
                          className="text-center fw-bold text-dark fs-2 mt-5 d-flex justify-content-center"
                          // style={{
                          //   textDecoration: "none",
                          //   // textDecoration: "underline",
                          // }}
                        >
                        <h1 className="fw-bold mt-5"  style={{
                            // :" 0.4rem 0.4rem 3rem #73aa43",
                                        // textShadow: "4px 4px 4px #73aa43",
                            color:"#73aa43",
                            fontSize:"80px"

                          }}>50$</h1>  
                          <br /><br /><br /><br /><br />
                        </Card.Title>
                        <Card.Text className="  text-dark" style={{textDecoration:"none"}}>
                        <p
                            className="text-center  fw-bold fs-5 text-dark"
                            style={{
                              textDecoration: "underline",
                            }}
                          >
                            Monthly Return{" "}
                            <div className="text-center fw-bold text-dark fs-1" style={{color:"#73aa43",}}>
                              15%
                            </div>
                          </p>
                          <br />
                          <p
                            className="text-center  fw-bold fs-5 text-dark"
                            style={{
                              textDecoration: "underline",
                            }}
                          >
                            Total Return{" "}
                            <div className="text-center fw-bold text-dark fs-1">
                              3X
                            </div>
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </NavLink>
                </div>

                <div className="col-6  text-dark rounded-4 d-flex justify-content-center">
                  <NavLink to="/my-investments" style={{textDecoration:"none"}}>
                    <Card
                      className="text-dark rounded-5 Button"
                      style={{
                        width: "40rem",
                        height: "40rem",
                        borderRadius: "50%",
                        // backgroundColor: "light",
                        // border: "2px solid black",
                        borderStyle: "double",
                    borderWidth: "6px",
                    borderColor: "#73aa43",
                      }}
                    >
                      <Card.Body className="Button">
                      <img
                      
        style={{ backgroundColor: "transparent"  }}
        // src={require("../media/Logo-removebg1.png")}
        src={("./ogo.png")}
        width="100" // Set the width you desire
        height="auto" // Height will be set automatically
        className="d-flex justify-content-end"
        alt="Ziaja International"
      />                        <Card.Title
                          className="text-center fw-bold text-dark fs-2 mt-5 d-flex justify-content-center"
                          // style={{
                          //   textDecoration: "none",
                          //   // textDecoration: "underline",
                          // }}
                        >
                        <h1 className="fw-bold mt-5"  style={{
                            // :" 0.4rem 0.4rem 3rem #73aa43",
                                        // textShadow: "4px 4px 4px #73aa43",
                            color:"#73aa43",
                            fontSize:"80px"

                          }}>100$</h1>  
                          <br /><br /><br /><br /><br />
                        </Card.Title>
                        <Card.Text className="  text-dark" style={{textDecoration:"none"}}>
                        <p
                            className="text-center  fw-bold fs-5 text-dark"
                            style={{
                              textDecoration: "underline",
                            }}
                          >
                            Monthly Return{" "}
                            <div className="text-center fw-bold text-dark fs-1" style={{color:"#73aa43",}}>
                              15%
                            </div>
                          </p>
                          <br />
                          <p
                            className="text-center  fw-bold fs-5 text-dark"
                            style={{
                              textDecoration: "underline",
                            }}
                          >
                            Total Return{" "}
                            <div className="text-center fw-bold text-dark fs-1">
                              3X
                            </div>
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </NavLink>
                </div>
               
                
              
              </div>
            </div>

            <div className="row mt-5 mb-5 text-dark">
              <div
                className="d-flex justify-content-evenly "
                // style={{ marginLeft: "8px" }}
              >
                

                <div className="col-6  text-dark rounded-4 d-flex justify-content-center">
                  <NavLink to="/my-investments" style={{textDecoration:"none"}}>
                    <Card
                      className="text-dark rounded-5 Button"
                      style={{
                        width: "40rem",
                        height: "40rem",
                        borderRadius: "50%",
                        // backgroundColor: "light",
                        // border: "2px solid black",
                        borderStyle: "double",
                    borderWidth: "6px",
                    borderColor: "#73aa43",
                      }}
                    >
                      <Card.Body  className="Button">
                      <img
                      
        style={{ backgroundColor: "transparent"  }}
        // src={require("../media/Logo-removebg1.png")}
        src={("./ogo.png")}
        width="100" // Set the width you desire
        height="auto" // Height will be set automatically
        className="d-flex justify-content-end"
        alt="Ziaja International"
      />                        <Card.Title
                          className="text-center fw-bold text-dark fs-2 mt-5 d-flex justify-content-center"
                          // style={{
                          //   textDecoration: "none",
                          //   // textDecoration: "underline",
                          // }}
                        >
                        <h1 className="fw-bold mt-5"  style={{
                            // :" 0.4rem 0.4rem 3rem #73aa43",
                                        // textShadow: "4px 4px 4px #73aa43",
                            color:"#73aa43",
                            fontSize:"80px"

                          }}>200$</h1>  
                          <br /><br /><br /><br /><br />
                        </Card.Title>
                        <Card.Text className="  text-dark" style={{textDecoration:"none"}}>
                        <p
                            className="text-center  fw-bold fs-5 text-dark"
                            style={{
                              textDecoration: "underline",
                            }}
                          >
                            Monthly Return{" "}
                            <div className="text-center fw-bold text-dark fs-1" style={{color:"#73aa43",}}>
                              15%
                            </div>
                          </p>
                          <br />
                          <p
                            className="text-center  fw-bold fs-5 text-dark"
                            style={{
                              textDecoration: "underline",
                            }}
                          >
                            Total Return{" "}
                            <div className="text-center fw-bold text-dark fs-1">
                              3X
                            </div>
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </NavLink>
                </div>

                <div className="col-6  text-dark rounded-4 d-flex justify-content-center">
                  <NavLink to="/my-investments" style={{textDecoration:"none"}}>
                    <Card
                      className="text-dark rounded-5 Button"
                      style={{
                        width: "40rem",
                        height: "40rem",
                        borderRadius: "50%",
                        // backgroundColor: "light",
                        // border: "2px solid black",
                        borderStyle: "double",
                    borderWidth: "6px",
                    borderColor: "#73aa43",
                      }}
                    >
                      <Card.Body  className="Button">
                      <img
                      
        style={{ backgroundColor: "transparent"  }}
        // src={require("../media/Logo-removebg1.png")}
        src={("./ogo.png")}
        width="100" // Set the width you desire
        height="auto" // Height will be set automatically
        className="d-flex justify-content-end"
        alt="Ziaja International"
      />                        <Card.Title
                          className="text-center fw-bold text-dark fs-2 mt-5 d-flex justify-content-center"
                          // style={{
                          //   textDecoration: "none",
                          //   // textDecoration: "underline",
                          // }}
                        >
                        <h1 className="fw-bold mt-5"  style={{
                            // :" 0.4rem 0.4rem 3rem #73aa43",
                                        // textShadow: "4px 4px 4px #73aa43",
                            color:"#73aa43",
                            fontSize:"80px"

                          }}>500$</h1>  
                          <br /><br /><br /><br /><br />
                        </Card.Title>
                        <Card.Text className="  text-dark" style={{textDecoration:"none"}}>
                        <p
                            className="text-center  fw-bold fs-5 text-dark"
                            style={{
                              textDecoration: "underline",
                            }}
                          >
                            Monthly Return{" "}
                            <div className="text-center fw-bold text-dark fs-1" style={{color:"#73aa43",}}>
                              15%
                            </div>
                          </p>
                          <br />
                          <p
                            className="text-center  fw-bold fs-5 text-dark"
                            style={{
                              textDecoration: "underline",
                            }}
                          >
                            Total Return{" "}
                            <div className="text-center fw-bold text-dark fs-1">
                              3X
                            </div>
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </NavLink>
                </div>

               
              </div>
            </div>

          </div>
        </div> */}

        <p className="text-center mt-4 fs-2 fw-bold" style={{ color: "black" }}>
          Invest in Ziaja today and secure <br /> your share of the thriving beauty
          industry While enjoying impressive monthly returns
        </p>

        <div className="row mt-5">
          <div className="d-flex justify-content-center">
            {/* <div className="col-12 col-md-12 col-sm-12"></div> */}
            <h1
              className="text-center mt-5 text-bold  fw-bold col-lg-6 col-sm-12 col-md-10  py-1"
              style={{ backgroundColor: "white", border: "2px solid #73aa43" , borderRadius:"50px" , color:"#73aa43" }}
            >
              PREMIUM PACKAGES AT ZIAJA
            </h1>
          </div>
        </div>
        <div className="row d-flex justify-content-around" style={{ border: "6px solid #73aa43" , borderRadius:"20px" }}>
        

            <div className="col-4 mt-3 mb-3 mx-3  rounded-5 Button"style={{ border: "3px solid #73aa43" ,borderStyle: "double",borderWidth: "6px",   }}> 
          <div>          <img
                      
        style={{ backgroundColor: "transparent"  }}
        // src={require("../media/Logo-removebg1.png")}
        src={("./ogo.png")}
       
        className="d-flex justify-content-end w-25 h-25 h1 mt-2"
        alt="Ziaja International"
      /> </div>
        <div className="text-center  fw-bold display-1 "  style={{color:"#73aa43"}}>1000$
    <div className="mt-5">
       <p
      className="text-center  fw-bold fs-5 text-dark"
      style={{
        textDecoration: "underline",
      }}
    >
      Monthly Return{" "}
      <div className="text-center fw-bold text-dark fs-1" style={{color:"#73aa43",}}>
        18%
      </div>
    </p>
    {/* <br /> */}
    <p
      className="text-center  fw-bold fs-5 text-dark mt-5"
      style={{
        textDecoration: "underline",
      }}
    >
      Total Return{" "}
      <div className="text-center fw-bold text-dark fs-1 mb-5">
        5X
      </div>
    <Link to="/my-investments">  <button type="button" class="btn btn-secondary btn-lg w-75 rounded-5 try">Try Now</button> </Link>
      </p>
    
    </div>
    
    </div>
            </div>

            <div className="col-4 mt-3 mb-3 mx-3  rounded-5 Button"style={{ border: "3px solid #73aa43" ,borderStyle: "double",borderWidth: "6px",   }}> 
          <div>          <img
                      
        style={{ backgroundColor: "transparent"  }}
        // src={require("../media/Logo-removebg1.png")}
        src={("./ogo.png")}
       
        className="d-flex justify-content-end w-25 h-25 h1 mt-2"
        alt="Ziaja International"
      /> </div>  <div className="text-center  fw-bold display-1 "  style={{color:"#73aa43"}}>2000$
    <div className="mt-5">
       <p
      className="text-center  fw-bold fs-5 text-dark"
      style={{
        textDecoration: "underline",
      }}
    >
      Monthly Return{" "}
      <div className="text-center fw-bold text-dark fs-1" style={{color:"#73aa43",}}>
        18%
      </div>
    </p>
    {/* <br /> */}
    <p
      className="text-center  fw-bold fs-5 text-dark mt-5"
      style={{
        textDecoration: "underline",
      }}
    >
      Total Return{" "}
      <div className="text-center fw-bold text-dark fs-1 mb-5">
        5X
      </div>
    <Link to="/my-investments">  <button type="button" class="btn btn-secondary btn-lg w-75 rounded-5 try" >Try Now</button> </Link>
      </p>
    
    </div>
    
    </div>
            </div>

            <div className="col-4 mt-3 mb-3 mx-3  rounded-5 Button"style={{ border: "3px solid #73aa43" ,borderStyle: "double",borderWidth: "6px",   }}> 
          <div>          <img
                      
        style={{ backgroundColor: "transparent"  }}
        // src={require("../media/Logo-removebg1.png")}
        src={("./ogo.png")}
       
        className="d-flex justify-content-end w-25 h-25 h1 mt-2"
        alt="Ziaja International"
      /> </div>  <div className="text-center  fw-bold display-1 "  style={{color:"#73aa43"}}>5000$
    <div className="mt-5">
       <p
      className="text-center  fw-bold fs-5 text-dark"
      style={{
        textDecoration: "underline",
      }}
    >
      Monthly Return{" "}
      <div className="text-center fw-bold text-dark fs-1" style={{color:"#73aa43",}}>
        18%
      </div>
    </p>
    {/* <br /> */}
    <p
      className="text-center  fw-bold fs-5 text-dark mt-5"
      style={{
        textDecoration: "underline",
      }}
    >
      Total Return{" "}
      <div className="text-center fw-bold text-dark fs-1 mb-5">
        5X
      </div>
    <Link to="/my-investments">  <button type="button" class="btn btn-secondary btn-lg w-75 rounded-5 try" >Try Now</button> </Link>
      </p>
    
    </div>
    
    </div>
            </div>

            <div className="col-4 mt-3 mb-3 mx-3  rounded-5 Button"style={{ border: "3px solid #73aa43" ,borderStyle: "double",borderWidth: "6px",   }}> 
          <div>          <img
                      
        style={{ backgroundColor: "transparent"  }}
        // src={require("../media/Logo-removebg1.png")}
        src={("./ogo.png")}
       
        className="d-flex justify-content-end w-25 h-25 h1 mt-2"
        alt="Ziaja International"
      /> </div>  <div className="text-center  fw-bold display-1 "  style={{color:"#73aa43"}}>10000$
    <div className="mt-5">
       <p
      className="text-center  fw-bold fs-5 text-dark"
      style={{
        textDecoration: "underline",
      }}
    >
      Monthly Return{" "}
      <div className="text-center fw-bold text-dark fs-1" style={{color:"#73aa43",}}>
        18%
      </div>
    </p>
    {/* <br /> */}
    <p
      className="text-center  fw-bold fs-5 text-dark mt-5"
      style={{
        textDecoration: "underline",
      }}
    >
      Total Return{" "}
      <div className="text-center fw-bold text-dark fs-1 mb-5">
        5X
      </div>
    <Link to="/my-investments">  <button type="button" class="btn btn-secondary btn-lg w-75 rounded-5 try">Try Now</button> </Link>
      </p>
    
    </div>
    
    </div>
            </div>

        </div>

        <p className="text-center mt-4 fs-2 fw-bold" style={{ color: "black" }}>
          Invest in Ziaja today and secure <br /> your share of the thriving beauty
          industry While enjoying impressive monthly returns
        </p>

        
        
      </div>
    </body>
  );
};

export default Package2;
