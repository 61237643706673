import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../../context/auth";
import MetaData from "../../Components/MetaData";
import { MdProductionQuantityLimits } from "react-icons/md";

const AdminSetting = () => {
  const [auth, setAuth] = useAuth();

  const [Limit, setLimit] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const approveWithDrawHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { data } = await axios.post("/admin/change-limit", {
        limit: Limit,
      });
      if (data?.error) {
        setLimit("");
        toast.error(data.error, { position: "top-center", autoClose: 3000 });
        setLoading(false);
      } else {
        // localStorage.setItem("auth", JSON.stringify(data));
        // setAuth(data);
        setLoading(false);
        // setCheckCode("");
        toast.success(data.message, {
          position: "top-center",
          autoClose: 3000,
        });
        setLoading(false);
        navigate("/admin/dashboard");
      }
    } catch (err) {
      setLoading(false);
      setLimit("");
      toast.error("Something went wrong. Try again!!");
    }
  };
  return (
    <div className="align-items-center d-flex justify-content-center ">
      <MetaData title="Approve-User" />
      <form
        className="form_main h-100 fs-3 w-50 "
        onSubmit={approveWithDrawHandler}
      >
        <p className="display-3 fs-3" style={{ color: "#73aa43" }}>
          {" "}
          Update WithDrawal Limit
        </p>
        <div className=" fs-5" style={{ color: "#73aa43" }}>
          {" "}
          Enter WithDrawal Limit Only in Digits
        </div>

        {loading && (
          <p
            className="text-center"
            style={{ color: "#73aa43", marginRight: "75px" }}
          >
            {" "}
            <div className="spinner "></div>{" "}
          </p>
        )}

        <div className="inputContainer">
          <svg
            viewBox="0 0 16 16"
            fill="#2e2e2e"
            height={16}
            width={16}
            xmlns="http://www.w3.org/2000/svg"
            className="inputIcon"
          >
            <MdProductionQuantityLimits />
          </svg>
          <input
            placeholder="Enter WithDrawal Limits"
            id="username"
            className="inputField"
            type="text"
            value={Limit}
            onChange={(e) => {
              setLimit(e.target.value);
            }}
          />
        </div>

        <button id="button" type="submit" className="" disabled={loading}>
          {loading ? "..Loading" : "Update Limit..."}
        </button>

        <p style={{ fontSize: "15px" }}>
          <Link
            to="/admin/dashboard"
            className="fw-bold"
            style={{ color: "RGB(115, 170, 67)" }}
          >
            Go Back To DashBoard
          </Link>
        </p>
      </form>
    </div>
  );
};

export default AdminSetting;
