import React from "react";
import { Link } from "react-router-dom";
import MetaData from "../Components/MetaData";
import "../Styles/package.css";
import "../Components/dashBoard.css"
const Investment = () => {
  
  return (
    <div className="" >
      <div className="container mt-5 gap-4">
        <MetaData title="Investment" />

        <div className="row">
          <div className=" d-lg-flex d-md-block d-sm-block justify-content-around mb-5">
            <div
              className="col-lg-6 col-md-12  col-sm-12 mb-5"
              // onMouseEnter={(e) => (
              //   (e.target.style.fontWeight = "bold"),
              //   (e.target.style.textDecoration = "underline")
              // )} // Change text color to grey on hover
              // onMouseLeave={(e) => (
              //   (e.target.style.fontWeight = "normal"),
              //   (e.target.style.textDecoration = "none")
              // )}
            >
              <Link to="/my-investments" style={{ textDecoration: "none" }}>
                <div
                  className="square-box bg-light p-4  rounded-5 Button"
                  style={{
                    borderStyle: "double",
                    borderWidth: "6px",
                    borderColor: "#73aa43",
                    // transition: "background-color 0.3s",
                    // border: "2px solid black",
                  }}
                  // onMouseEnter={(e) => (e.target.styleboxShadow= '0.4rem 0.4rem 3rem #73aa43' 
                  // )} // Change text color to grey on hover
                  //   onMouseLeave={(e) => (e.target.style.boxShadow= '0.4rem 0.4rem 3rem #73aa43'
                  // )} 
                >
                <p className="text-center" >  <img src="./MyInvestment.jpg" alt="" className="" style={{height:"40px" , width:"50px" ,  backgroundColor: "transparent" }} /></p>
                  <h2 style={{ color: "black" }} className="text-center fw-bold">MY INVESTMENT</h2>
                  <p style={{ color: "black" }} className="text-center"
                  
                  >
                    {/* Click here to see the status of your current investment. */}
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-lg-5 col-md-12 col-sm-12 mb-4 ">
              <Link to="/package2" style={{ textDecoration: "none" }}>
                <div
                  className="square-box bg-light p-4 rounded-5 Button"
                  style={{
                    borderStyle: "double",
                    borderWidth: "6px",
                    borderColor: "#73aa43",
                    // transition: "background-color 0.3s",
                    // border: "2px solid black",
                  }}
                >
<p className="text-center" >  <img src="./package.png" alt="" className="" style={{height:"35px" ,  backgroundColor: "transparent" , backgroundColor:"lightgray"}} /></p>

                  <h2 style={{ color: "black" }} className="text-center fw-bold">PACKAGES</h2>
                  <p style={{ color: "black" }}>
                    {/* Click here to see current available packages. */}
                  </p>
                </div>
              </Link>
            </div>

          </div>
        </div>

        <div className="row">
          <div className=" d-lg-flex d-md-block d-sm-block justify-content-around mb-5">
            <div className="col-lg-6 col-md-12 col-sm-12 mb-5">
              <Link to="/withdrawhistory" style={{ textDecoration: "none" }}>
                <div
                  className="square-box bg-light p-4 rounded-5 Button"
                  style={{
                    borderStyle: "double",
                    borderWidth: "6px",
                    borderColor: "#73aa43",
                    // transition: "background-color 0.3s",
                    // border: "2px solid black",
                  }}
                >
                  {/* withdrawalIcon */}
                  <p className="text-center" >  <img src="./withdrawalIcon.png" alt="" className="" style={{height:"35px" ,  backgroundColor: "transparent" , backgroundColor:"lightgray"}} /></p>

                  <h2 style={{ color: "black" }} className="text-center fw-bold"> WITHDRAWAL HISTORY</h2>
                  <p style={{ color: "black" }}>
                    {/* Clicks here to see the history of your withdrawals. */}
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-lg-5 col-md-12 col-sm-12 mb-4">
              <Link to="/history" style={{ textDecoration: "none" }}>
                <div
                  className="square-box bg-light p-4 rounded-5 Button w-100"
                  style={{
                    borderStyle: "double",
                    borderWidth: "6px",
                    borderColor: "#73aa43",
                    // transition: "background-color 0.3s",
                    // border: "2px solid black",
                  }}
                >
 <p className="text-center" >  <img src="./investment2.png" alt="" className="" style={{height:"35px" , width:"50px",  backgroundColor: "transparent" }} /></p>

                  <h2 style={{ color: "black" }} className="text-center fw-bold">INVESTMENT HISTORY</h2>
                  <p style={{ color: "black" }}>
                    {/* Click here to see the history of investments. */}
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/* 
      <div className="row">
        <div className=" d-flex justify-content-evenly">
          <div>
            <Link to="/history">
              <div className="col-lg-6 mb-4">
                <div className="square-box bg-light p-4">
                  <h2>Investment History</h2>
                  <p>Click here to see the history of your investents.</p>
                </div>
              </div>
            </Link>
          </div>

          <div>
            <Link to="/withdrawhistory">
              <div className="col-lg-6 mb-4">
                <div className="square-box bg-light p-4">
                  <h2> withDrawal History</h2>
                  <p>Click here to see the history of your WithDrawal.</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
        
      </div> */}

        <div className="row">
          <div className=" d-lg-flex d-md-block d-sm-block justify-content-around mb-5">
            <div className="col-lg-6 col-md-12 col-sm-12 mb-5 ">
              <Link to="/withdraw2" style={{ textDecoration: "none" }}>
                <div
                  className="square-box bg-light p-4  rounded-5 Button w-md-100 w-sm-100"
                  style={{
                    borderStyle: "double",
                    borderWidth: "6px",
                    borderColor: "#73aa43",
                    // transition: "background-color 0.3s",
                    // border: "2px solid black",
                  }}
                >
                   <p className="text-center" >  <img src="./withdrawal2.png" alt="" className="" style={{height:"35px" , width:"50px",  backgroundColor: "transparent" }} /></p>

                  <h2 style={{ color: "black" }} className="text-center fw-bold">WITHDRAWAL</h2>
                  <p style={{ color: "black" }}>
                    {/* Click here to withdraw if you have enough money. */}
                  </p>
                </div>
              </Link>{" "}
            </div>

            <div className="col-lg-5 col-md-12 mb-4">
              <Link to="/changePassword" style={{ textDecoration: "none" }}>
                <div
                  className="square-box bg-light p-4 rounded-5 Button"
                  style={{
                    borderStyle: "double",
                    borderWidth: "6px",
                    borderColor: "#73aa43",
                    // transition: "background-color 0.3s",
                    // border: "2px solid black",
                  }}
                >
                                     <p className="text-center" >  <img src="./passowrd2.png" alt="" className="" style={{height:"35px" , width:"50px",  backgroundColor: "transparent" }} /></p>

                  <h2 style={{ color: "black" }} className="text-center fw-bold">CHANGE PASSWORD</h2>
                  <p style={{ color: "black" }}>
                    {/* Click here to change your account password. */}
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>

        {/* <div className="line">
          <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <line
              x1="0"
              y1="50"
              x2="100"
              y2="50"
              stroke="black"
              strokeWidth="2"
            />
            <image
              xlinkHref="facebook_logo.svg"
              x="45"
              y="40"
              width="20"
              height="20"
            />
          </svg>
        </div> */}
      </div>
    </div>
  );
};

export default Investment;
