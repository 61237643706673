import React, { useState } from "react";
import "../Styles/elemenets/_filters-grid.scss";
import "../Styles/elemenets/_filter_product_element.scss";
import "../Styles/elemenets/_filters-modal.scss";
import "../Styles/elemenets/_clear-filters.scss";
import "../Styles/elemenets/_checkbox.scss";
// import '../Styles/elemenets/_gdpr.scss'
// import '../Styles/elemenets/_flash.scss'
import "../Styles/elemenets/_cookie-notice.scss";
import "../Styles/elemenets/_presskit.scss";
import "../Styles/elemenets/_select.scss";
import "../Styles/_odameter.scss";
import "../Styles/_global.scss";
import "../Styles/_select2.scss";
// import '../Styles/lato.scss'
import "../Styles/elemenets/_flash.scss";
import "../Styles/elemenets/_input.scss";
import "../Styles/elemenets/_product-list.scss";
import "./check.css"
import "../Styles/elemenets/_related-products.scss";
import "../Styles/elemenets/_slick-dots.scss";
import "../Styles/elemenets/_table.scss";
import "../Styles/elemenets/_textarea.scss";
import "../Styles/elemenets/_tiles-content.scss";
import "../Styles/elemenets/_tiles-grid.scss";
import { Link, useNavigate } from "react-router-dom";
import MetaData from "../Components/MetaData";

const DenvaPro = () => {
  let navigate = useNavigate();
  const [isClose, setIsClose] = useState(false);

  return (
    <div id="filters-modal" className={isClose ? "" : "visible"}>
      <MetaData title='ProductsModal-Dp'/>
      <div className="filters-container">
        <div className="close-modal-container wrapper">
          <button
            className="close-modal"
            onClick={() => {
              setIsClose(true);
              navigate("/");
            }}
          >
            Close <span>×</span>
          </button>
        </div>
        <div className="filters-modal-content">
          <div className="filters-modal-wrap">
            <div className="wrapper">
              <div className="filters-nav">
                <ul className="inline-list">
                  <li>
                    <Link
                      style={{ textDecoration: "none", color: "gray" }}
                      onMouseLeave={(e) => (e.target.style.color = "gray")}
                      onMouseOver={(e) => (e.target.style.color = "#73aa43")}
                       onmouseover="this.style.color='red'" onmouseout="this.style.color='blue'"
                      to="/productsmodal"
                      data-item="ziaja"
                    >
                      ziaja
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link
                      style={{ textDecoration: "none", color: "gray" }}
                      to="/productsmodal-ziajamed"
                      data-item="ziaja"
                      onMouseEnter={(e) => (e.target.style.color = "#73aa43")} // Change text color to grey on hover
              onMouseLeave={(e) => (e.target.style.color = "gray")}
                    >
                      ziaja MED
                    </Link>
                  </li>
                  <li className="active">
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/productsmodal-dp"
                      data-item="ziaja"
                      onMouseEnter={(e) => (e.target.style.color = "#73aa43")} // Change text color to grey on hover
                      onMouseLeave={(e) => (e.target.style.color = "")}
                      
                    >
                      DENOVA pro
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="filters">
                <form
                  name="filter-popup"
                  method="get"
                  action="/products/line/denova-pro"
                  id="filter-modal"
                >
                  <div className="filters-form-items">
                    <div className="filter-items">
                      <div className="filters-row filters-row-checkbox">
                        <div className="form-group">
                          <label hidden="hidden" className="control-label">
                            Category
                          </label>{" "}
                          <div id="filter-popup_category" placeholder="">
                            <div className="checkbox">
                              <label
                                htmlFor="filter-popup_category_10010"
                                className=""
                                data-count={3}
                              >
                                <input
                                  type="checkbox"
                                  id="filter-popup_category_10010"
                                  name="filter-popup[category][]"
                                  data-count={3}
                                  defaultValue={10010}
                                />{" "}
                                body care
                              </label>
                            </div>
                            <div className="checkbox">
                              <label
                                htmlFor="filter-popup_category_10011"
                                className=""
                                data-count={91}
                              >
                                <input
                                  type="checkbox"
                                  id="filter-popup_category_10011"
                                  name="filter-popup[category][]"
                                  data-count={91}
                                  defaultValue={10011}
                                />{" "}
                                face care
                              </label>
                            </div>
                            <div className="checkbox">
                              <label
                                htmlFor="filter-popup_category_10358"
                                className=""
                                data-count={80}
                              >
                                <input
                                  type="checkbox"
                                  id="filter-popup_category_10358"
                                  name="filter-popup[category][]"
                                  data-count={80}
                                  defaultValue={10358}
                                />{" "}
                                vegan
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="filters-row">
                        <div>
                          <div className="form-group">
                            <label
                              className="control-label"
                              htmlFor="filter-popup_type"
                            >
                              product Type
                            </label>{" "}
                            <select
                              id="filter-popup_type"
                              name="filter-popup[type][]"
                              placeholder="Search filters..."
                              className="form-control"
                              multiple=""
                              data-placeholder="Search filters..."
                              tabIndex={0}
                              aria-hidden="false"
                            >
                              <option value={10260} data-count={4}>
                                cleansing gels
                              </option>
                              <option value={10277} data-count={1}>
                                cleansing milks
                              </option>
                              <option value={10264} data-count={3}>
                                eye creams
                              </option>
                              <option value={10265} data-count={24}>
                                face creams
                              </option>
                              <option value={10266} data-count={20}>
                                face masks
                              </option>
                              <option value={11865} data-count={1}>
                                hand creams
                              </option>
                              <option value={10276} data-count={4}>
                                make-up removers
                              </option>
                              <option value={10278} data-count={1}>
                                oils
                              </option>
                              <option value={10279} data-count={4}>
                                peels
                              </option>
                              <option value={10281} data-count={11}>
                                serums
                              </option>
                              <option value={10285} data-count={10}>
                                special
                              </option>
                              <option value={10288} data-count={10}>
                                toners
                              </option>
                            </select>
                          </div>
                        </div>
                        <div>
                          <div className="form-group">
                            <label
                              className="control-label"
                              htmlFor="filter-popup_skin"
                            >
                              skin
                            </label>{" "}
                            <select
                              id="filter-popup_skin"
                              name="filter-popup[skin][]"
                              placeholder="Search filters..."
                              className="form-control"
                              multiple=""
                              data-placeholder="Search filters..."
                              tabIndex={0}
                              aria-hidden="false"
                            >
                              <option value={10079} data-count={10}>
                                acne prone
                              </option>
                              <option value={10080} data-count={1}>
                                all types
                              </option>
                              <option value={10314} data-count={10}>
                                combination
                              </option>
                              <option value={10085} data-count={5}>
                                dehydrated
                              </option>
                              <option value={10086} data-count={11}>
                                dilated capillaries
                              </option>
                              <option value={10087} data-count={18}>
                                dry
                              </option>
                              <option value={10324} data-count={2}>
                                dull
                              </option>
                              <option value={10088} data-count={1}>
                                irritated
                              </option>
                              <option value={10089} data-count={11}>
                                mature / wrinkle prone
                              </option>
                              <option value={10090} data-count={4}>
                                non-elastic
                              </option>
                              <option value={10091} data-count={11}>
                                normal
                              </option>
                              <option value={10315} data-count={10}>
                                oily
                              </option>
                              <option value={10095} data-count={1}>
                                seborrhoea
                              </option>
                            </select>
                          </div>
                        </div>
                        <div>
                          <div className="form-group">
                            <label
                              className="control-label"
                              htmlFor="filter-popup_series"
                            >
                              Line
                            </label>{" "}
                            <select
                              id="filter-popup_series"
                              name="filter-popup[series][]"
                              placeholder="Search filters..."
                              className="form-control"
                              multiple=""
                              data-placeholder="Search filters..."
                              tabIndex={0}
                              aria-hidden="false"
                            >
                              <option value={11309} data-count={8}>
                                acne-prone skin treatment
                              </option>
                              <option value={11311} data-count={7}>
                                anti-ageing treatment
                              </option>
                              <option value={11313} data-count={7}>
                                clay &amp; algae masks
                              </option>
                              <option value={11321} data-count={12}>
                                complementary treatment
                              </option>
                              <option value={11312} data-count={7}>
                                exfoliation treatment
                              </option>
                              <option value={11319} data-count={6}>
                                eye treatment
                              </option>
                              <option value={11314} data-count={3}>
                                hand treatment
                              </option>
                              <option value={11317} data-count={10}>
                                intensive face care
                              </option>
                              <option value={11310} data-count={9}>
                                revitalising treatment
                              </option>
                              <option value={11306} data-count={8}>
                                treatment for dry and normal skin
                              </option>
                              <option value={11308} data-count={8}>
                                treatment for oily and combination skin
                              </option>
                              <option value={11307} data-count={8}>
                                treatment for skin with dilated capillaries
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="summary">
                      <div className="summary-box">
                        <div className="found">
                          <p>
                            <span className="found-label">products Found </span>{" "}
                            <span
                              className="number odometer odometer-auto-theme"
                              data-max={93}
                            />
                          </p>
                          <div className="odometer-inside">
                            <span className="odometer-digit">
                              <span className="odometer-digit-spacer"></span>
                              <span className="odometer-digit-inner">
                                <span className="odometer-ribbon">
                                  <span className="odometer-ribbon-inner">
                                    <span className="odometer-value">9</span>
                                  </span>
                                </span>
                              </span>
                            </span>
                            <span className="odometer-digit">
                              <span className="odometer-digit-spacer"></span>
                              <span className="odometer-digit-inner">
                                <span className="odometer-ribbon">
                                  <span className="odometer-ribbon-inner">
                                    <span className="odometer-value">3</span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </div>
                          <p />
                        </div>
                        <div
                          className="chosen-filters"
                          style={{ display: "none" }}
                        >
                          <h3>Active filters</h3>
                        </div>
                        <div
                          className="filters-clear filter-row"
                          style={{ display: "none" }}
                        >
                          <a href="#" className="clear-form">
                            <span className="filter-value">
                              Clear all filters
                              <span className="filter-value-remove">×</span>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="summary-actions">
                        <p>Adjust search criteria or</p>
                        <button type="submit">
                          <Link
                            style={{ textDecoration: "none", color: "white" }}
                            to="https://en.ziaja.com/products/line/denova-pro?"
                          >
                            Show products
                          </Link>
                        </button>
                        <a style={{textDecoration:"none"}} className="close-modal" href="/">
                          Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DenvaPro;
