import React from "react";
const Footer = () => {
  return (
    // style={{ width: "1341px" }}
    //  className=" fixed-bottom-md fixed-bottom-lg-none d-none d-md-block d-lg-block"
    <div>
      <footer
        id="footer"
        style={{
          textDecoration: "none",
          // position: "fixed",
          backgroundColor: "white",
          
        }}
      >
        <div className="row d-flex justify-content-between">
          <div className="col-lg-10 col-md-9 col-sm-6 col-xsm-6">
            <hr style={{ border: "1px solid black" }} />
          </div>
          <div className="col-lg-1 col-md-2 col-sm-2 ">
            <div className="colunm">
              <ul className="social-links inline-list">
                {/* <li> */}
                <a
                  href="https://pl-pl.facebook.com/ziajainternational"
                  className="m-2"
                >
                  <img
                    src={require("../media/facebook_new.png")}
                    alt="Facebook"
                  />
                </a>
                {/* </li> */}
                {/* <li> */}
                <a
                  href="https://www.instagram.com/ziaja_international/"
                  className="ms-2"
                >
                  <img
                  className=""
                    src={require("../media/instagram_new.png")}
                    alt="Instagram"
                  />
                                  

                </a>

                {/* </li> */}
                {/* <hr /> */}
                
              </ul>
            </div>
            
          </div>
          <div className="col-1">
            <hr style={{ border: "1px solid black" }} />
          </div>
        </div>
        <div className="footer-bar">
          <div className="wrapper">
            <div className="newsletter-form-sm"></div>
            {/* <ul className="social-links inline-list">
            <li>
              <a href="https://pl-pl.facebook.com/ziajainternational">
                <img
                  src={require("../media/facebook_new.png")}
                  alt="Facebook"
                />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/ziaja_international/">
                <img
                  src={require("../media/instagram_new.png")}
                  alt="Instagram"
                />
              </a>
            </li>
          </ul> */}
          </div>
        </div>
        <div className="wrapper">
          <nav className="footer-nav">
            <div className="column">
              <h4 className="column-name">products</h4>
              <ul>
                <li>
                  <a
                    href="https://en.ziaja.com/products/line/ziaja"
                    style={{ textDecoration: "none", color: "GrayText",fontWeight:"bolder", }}
                  >
                    ziaja
                  </a>
                </li>
                <li>
                  <a
                    href="https://en.ziaja.com/products/line/ziaja-med"
                    style={{ textDecoration: "none", color: "GrayText", }}
                  >
                    ziaja MED
                  </a>
                </li>
                <li>
                  <a
                    href="https://en.ziaja.com/products/line/denova-pro"
                    style={{ textDecoration: "none", color: "GrayText" }}
                  >
                    denova pro
                  </a>
                </li>
                <li>
                  <a
                    href="https://en.ziaja.com/guides"
                    style={{ textDecoration: "none", color: "GrayText" }}
                  >
                    guides
                  </a>
                </li>
                <li>
                  <a
                    href="https://en.ziaja.com/products/category/vegan-1"
                    style={{ textDecoration: "none", color: "GrayText" }}
                  >
                    vegan
                  </a>
                </li>
              </ul>
            </div>
            <div className="column">
              <h4 className="column-name">company</h4>
              <ul>
                <li>
                  <a
                    href="https://en.ziaja.com/privacy"
                    style={{ textDecoration: "none", color: "GrayText",fontWeight:"bolder", }}
                  >
                    privacy
                  </a>
                </li>
              </ul>
            </div>
            <div className="column">
              <h4 className="column-name">contact</h4>
              <ul>
                <li>
                  <a
                    href="/contact"
                    style={{ textDecoration: "none", color: "GrayText" ,fontWeight:"bolder", }}
                  >
                    contact
                  </a>
                </li>
                <li>
                  <a
                    href="https://en.ziaja.com/where-to-buy"
                    style={{ textDecoration: "none", color: "GrayText" }}
                  >
                    where to buy
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        {/* https://themewagon.github.io/PodTalk/ */}
      </footer>
    </div>
  );
};

export default Footer;
