import React, { useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
// import { useAuth } from "../context/auth";
import { useState } from "react";


const NewFullWidthSlider = ({Images}) => {
  // const [auth , setAuth] = useAuth()
// const [picture1 , setPicture1] = useState()
// console.log("yes",auth.picture1)

//   useEffect(()=>{
//     setPicture1(auth.picture1)
//   },[])
// console.log(Images)
  return (
    <Carousel fade interval={4000}>
      <Carousel.Item >
        {" "}
        <a href="https://en.ziaja.com/products/series/lip-care" tabIndex={-1}>
          <img
            // src={Images?.Picture1}
            src={"./home1.jpg"}
            alt="First slide"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://en.ziaja.com/products/series/coconut-orange-vibes"
          tabIndex={-1}
        >
          <img
            // src={""}
            src={("./home22.jpg")}
            alt="Second slide"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://en.ziaja.com/products/series/vitamin-c-b3-niacinamide?page=1"
          tabIndex={-1}
        >
          {" "}
          <img
            alt=""
            // src={Images?.Picture3}
            src={("./home3.jpg")}
            className="slider_banner_1903"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://en.ziaja.com/products/series/antioxidant-hair-care"
          tabIndex={-1}
        >
          <img
            alt=""
            // src={Images?.Picture4}
            src={("./home4.jpg")}
            className="slider_banner_1903"
          />
        </a>
      </Carousel.Item>
    </Carousel>
  );
};

export default NewFullWidthSlider;
