import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  ProgressBar,
} from "react-bootstrap";
import "../../Styles/MyInvestment.css";
import MetaData from "../../Components/MetaData";
import { useAuth } from "../../context/auth";
import axios from "axios";
import { Avatar } from "antd";
import { LuCopy } from "react-icons/lu";


import { toast } from "react-toastify";
import { AiTwotoneDollarCircle } from "react-icons/ai";
import { PiSortDescendingBold } from "react-icons/pi";
import { TbFileDescription } from "react-icons/tb";
// import {Avatar} from "antd";
import Resizer from "react-image-file-resizer";

import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link, useLocation } from "react-router-dom";
import "../../Styles/Login.css";

import { useEffect, useState } from "react";

const MyInvestment = () => {
  function myFunction() {
    var x = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  // This data would actually come from your back-end or state management
  const investments = [
    { name: "Tech Stocks", value: 15000, progress: 75 },
    { name: "Real Estate Funds", value: 30000, progress: 60 },
    { name: "Government Bonds", value: 10000, progress: 90 },
  ];

  const totalInvestment = investments.reduce(
    (sum, investment) => sum + investment.value,
    0
  );

  const navigate = useNavigate();
  const location = useLocation();
  const [uploading, setUploading] = useState(false);
  const [Image, setImage] = useState("");
  const [amount, setAmount] = useState("");
  const [accountId, setAccountId] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [discription, setDescription] = useState("");

  const [investment, setInvestment] = useState([{}]);

  const [auth, setAuth] = useAuth();
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { data } = await axios.post(`/deposit-user`, {
        image: Image,
        amount,
        accountId,
        transactionId,
        discription,
      });
      if (data?.error) {
        toast.error(data.error, { position: "top-center", autoClose: 3000 });
        setLoading(false);
      } else {
        localStorage.setItem("investment", JSON.stringify(data.investment));
        // setAuth(data);/
        setLoading(false);
        console.log(data);
        toast.info("Your verification request send Successfully", {
          position: "top-center",
          autoClose: 3000,
        });
        // setImage("")
        setAmount("");
        setAccountId("");
        setTransactionId("");
        setDescription("");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong. Try again!!");
      setLoading(false);
    }
  };

  const handleImageUpload = async (e) => {
    try {
      
      let file = e.target.files[0];
      if (file) {
        setUploading(true);
        new Promise(() => {
          Resizer.imageFileResizer(
            file,
            300,
            300,
            "JPEG",
            100,
            0,
            async (uri) => {
              console.log("UPLOAD Image:", uri);
              const { data } = await axios.post("/upload-image", {
                image: uri,
              });
              console.log(data);
              setImage(data);
              setUploading(false);
            },
            "base64"
          );
        });
      }
    } catch (err) {
      console.log(err);
      // 'setUploading'(false);
    }
  };

  
  const [copied1, setCopied1] = useState(false);
  const [copied2, setCopied2] = useState(false);
  const [copied3, setCopied3] = useState(false);

  const copyToClipboard1 = () => {
    navigator.clipboard
      .writeText("0x66b6E6B1C8A5067DF7f5a6367892ED8F773127B2")
      .then(() => {
        setCopied1(true);
        setTimeout(() => setCopied1(false), 2000); // Reset copied state after 2 seconds
      })
      .catch((err) => console.error("Failed to copy:", err));
  };
  const copyToClipboard2 = () => {
    navigator.clipboard
      .writeText("0xA62B7f6f994c315be81EaC9222Ff45C42e2c6C69")
      .then(() => {
        setCopied2(true);
        setTimeout(() => setCopied2(false), 2000); // Reset copied state after 2 seconds
      })
      .catch((err) => console.error("Failed to copy:", err));
  };
  const copyToClipboard3 = () => {
    navigator.clipboard
      .writeText("0x668313D8e4Ab6401576F4AD2b48f1cC3F57B803E")
      .then(() => {
        setCopied3(true);
        setTimeout(() => setCopied3(false), 2000); // Reset copied state after 2 seconds
      })
      .catch((err) => console.error("Failed to copy:", err));
  };
  return (
    <Container>
      <MetaData title="My-Investments" />
      <div className="row justify-content-around d-flex gap-5">
        <div className="card col-6 ms-lg-0 ms-md-5 ms-sm-5" style={{ width: "auto" }}>
         <center>SCAN QR</center> 
          <p className="d-flex justify-content-center ">
            <img
              style={{ width: "130px", display: "block", margin: 0 }}
              class="card-img-top"
              src="./QR1.jpeg"
              alt="Card image cap"
            />
          </p>
          <div class="card-body">
            {/* <h5 class="card-title">Card title</h5> */}
            <h3> <center>ETH</center>  </h3><h2 className="text-center">  NETWORK</h2><h2 className="text-center">ETHEREUM</h2>
            <p class="card-text fw-bold">
            0x66b6E6B1C8A5067DF7f5a6367892ED8F773127B2  <button
                          style={{ border: "1px solid black" }}
                          className="ms-3"
                          onClick={copyToClipboard1}
                        >
                          {copied1 ? "Copied!" : <LuCopy />}
                        </button>
            </p>
          </div>
        </div>
        <div className="card col-6 " style={{ width: "auto" }}>
          <center>SCAN QR</center>
          <p className="d-flex justify-content-center ">
            <img
              style={{ width: "130px", display: "block", margin: 0 }}
              class="card-img-top"
              src="./QR2.jpeg"
              alt="Card image cap"
            />
          </p>
          <div class="card-body">
            {/* <h5 class="card-title">Card title</h5> */}
            <h3> <center>BNB</center>  </h3><h2 className="text-center">  NETWORK</h2><h2>(BEP20) SMART CHAIN</h2>
            <p class="card-text fw-bold">
            0xA62B7f6f994c315be81EaC9222Ff45C42e2c6C69 <button
                          style={{ border: "1px solid black" }}
                          className="ms-3"
                          onClick={copyToClipboard2}
                        >
                          {copied2 ? "Copied!" : <LuCopy />}
                        </button>
            </p>
          </div>
        </div>
        <div className="card col-6 " style={{ width: "auto" }}>
          {/* <h5 class="card-title">Card title</h5> */}
          <center>SCAN QR</center>
          <p className="d-flex justify-content-center ">
            <img
              style={{ width: "130px", display: "block", margin: 0 }}
              class="card-img-top"
              src="./QR3.jpeg"
              alt="Card image cap"
            />
          </p>
          <div class="card-body">
          <h3> <center>USDT</center>  </h3><h2 className="text-center">  NETWORK</h2><h2> (BEP20)BNB SMART CHAIN </h2>
            <p class="card-text fw-bold">
            0x668313D8e4Ab6401576F4AD2b48f1cC3F57B803E <button
                          style={{ border: "1px solid black" }}
                          className="ms-3"
                          onClick={copyToClipboard3}
                        >
                          {copied3 ? "Copied!" : <LuCopy />}
                        </button>
            </p>
          </div>
        </div>
        
       
      </div>
      <Row className="my-4">
        <Col>
          <h1
            className="text-center"
            style={{
              color: "black",
              textDecorationLine: "underline",
              // textShadow: "2px 2px 4px #73aa43",
            }}
          >
            INVESTMENT PORTFOLIO
          </h1>
        </Col>
      </Row>
      <Row className="my-4 justify-content-center mb-3">
        <Col md={8} lg={6} sm={12}>
          <Card>
            <Card.Body>
              <Card.Title>INVESTED AMOUNT</Card.Title>
              <Card.Text>
                <h2>${auth?.user?.totalAmount}</h2>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        {/* <Col md={8}> */}
        {/* <Card  className="mb-3">
              <Card.Header> <b>STATUS : </b>{investment.Status}</Card.Header>
              {/* <ListGroup variant="flush">
                <ListGroup.Item>
                  Value: ${investment.value.toLocaleString()}
                  <ProgressBar
                    now={investment.progress}
                    label={`${investment.progress}%`}
                    className="mt-2"
                  />
                </ListGroup.Item>
              </ListGroup> */}
        {/* </Card> */}
        {/* </Col> */}
        <div className="align-items-center d-flex justify-content-center row mt-4">
          <form
            className="form_main h-100 fs-3  col-md-12" style={{ width: "auto" }}
            onSubmit={handleLogin}
          >
            <p
              className="display-3 fs-3"
              style={{
                color: "black",
                textDecorationLine: "underline",
                // textShadow: "2px 2px 4px #73aa43",
              }}
            >
              {" "}
              INVESTMENT AMOUNT VERIFICATION
            </p>
            {loading && (
              <p
                className="text-center"
                style={{ color: "#73aa43", marginRight: "75px" }}
              >
                {" "}
                <div className="spinner mb-4"></div>{" "}
              </p>
            )}
            {/* <div className="inputContainer">
              <label className="upload mb-3">
                {uploading ? "Processing..." : "Upload Picture"}
                <input
                  hidden
                  type="file"
                  accept="image/*"
                  multiple
                  disabled={auth?.user?.role.includes("Admin")}
                  onChange={handleImageUpload}
                />
              </label>
              <a href={Image?.Location} className="mx-3">
                (
                <Avatar
                  src={Image?.Location}
                  // alt={invest.picture?[0].Key}
                  shape="square"
                  size="72"
                  className="mx-1"
                  // onClick={()=> deleteImageUpload()}
                />
                ){" "}
              </a>
            </div> */}

            <div className="inputContainer">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                height={20}
                width={20}
                xmlns="http://www.w3.org/2000/svg"
                className="inputIcon"
              >
                <AiTwotoneDollarCircle />
              </svg>

              <input
                placeholder="Amount"
                id="username"
                className="inputField"
                type="text"
                disabled={auth?.user?.role.includes("Admin")}
                value={amount}
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
              />
            </div>
            <div className="inputContainer">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                height={20}
                width={20}
                xmlns="http://www.w3.org/2000/svg"
                className="inputIcon"
              >
                <PiSortDescendingBold />
              </svg>

              <input
                placeholder="Account ID"
                id="text"
                className="inputField"
                type="text"
                disabled={auth?.user?.role.includes("Admin")}
                value={accountId}
                onChange={(e) => setAccountId(e.target.value)}
              />
            </div>
            <div className="inputContainer">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                height={20}
                width={20}
                xmlns="http://www.w3.org/2000/svg"
                className="inputIcon"
              >
                <PiSortDescendingBold />
              </svg>

              <input
                placeholder="TXID"
                id="password"
                className="inputField"
                disabled={auth?.user?.role.includes("Admin")}
                type="text"
                value={transactionId}
                onChange={(e) => setTransactionId(e.target.value)}
              />
            </div>

            {/* <input
              defaultChecked="checked"
              type="checkbox"
              className="justify-content-start"
            />
            <div className="checkmark" /> */}

            <div className="inputContainer">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                height={20}
                width={20}
                strokeLinecap="round"
                strokeLinejoin="round"
                disabled={auth?.user?.role.includes("Admin")}
                xmlns="http://www.w3.org/2000/svg"
                className="inputIcon"
              >
                <TbFileDescription />
              </svg>

              <input
                placeholder="Description"
                id="password"
                className="inputField"
                type="text"
                disabled={auth?.user?.role.includes("Admin")}
                value={discription}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="inputContainer">
              <label className="upload mb-3">
                {uploading ? "Processing..." : "Upload Picture"}
                <input
                  hidden
                  type="file"
                  accept="image/*"
                  multiple
                  disabled={auth?.user?.role.includes("Admin")}
                  onChange={handleImageUpload}
                />
              </label>
              <a href={Image?.Location} className="mx-3">
                (
                <Avatar
                  src={Image?.Location}
                  // alt={invest.picture?[0].Key}
                  shape="square"
                  size="72"
                  className="mx-1"
                  // onClick={()=> deleteImageUpload()}
                />
                ){" "}
              </a>
            </div>
            <button
              id="button"
              type="submit"
              className="text-white"
              disabled={loading}
            >
              {loading ? "..Loading" : "Submit"}
            </button>
          </form>
        </div>
      </Row>
    </Container>
  );
};

export default MyInvestment;
