import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { toast } from "react-toastify";
import { Container, Row, Col, Card, ListGroup, Button } from "react-bootstrap";
import { MdOutlineSettings } from "react-icons/md";
import { BiMoneyWithdraw } from "react-icons/bi";
import { SiWebmoney } from "react-icons/si";
import { FaUsers } from "react-icons/fa";
import { MdWifiProtectedSetup } from "react-icons/md";


import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import MetaData from "../../Components/MetaData";
import { Avatar } from "antd";
dayjs.extend(relativeTime); // fromNow()  // 4 hours ago

const ChatHistory = () => {


  const [loading, setLoading] = useState(true);
  const [chat, setChat] = useState([]);
  useEffect(() => {
    handleUserWithDraw();
  }, []);
  // const containerStyle = {
  //   top: 0,
  //   left: 0,
  //   width: "95vw",
  //   height: "95vh",
  //   backgroundColor: "white",
  //   // overflow: "hidden",
  // };
  const handleUserWithDraw = async (e) => {
    try {
      setLoading(true);
      const { data } = await axios.get("/allChatHistory");
      if (data?.error) {
        setLoading(false);
        toast.error(data.error, { position: "top-center", autoClose: 3000 });
      } else {
        console.log(data.chats);
        setChat(data.chats);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const setUserWithDraws = () => {
    const data = {
      columns: [
       
        {
            label: (
              <span
                style={{
                  color: "black",
                  textDecorationLine: "underline",
                }}
              >
                UserName
              </span>
            ),
            field: "UserName",
            sort: "asc",
          },
        
        {
          label: "Picture",
          field: "Picture",
        },
        
        {
          label: (
            <span
              style={{
                color: "black",
                textDecorationLine: "underline",
              }}
            >
              Description
            </span>
          ),
          field: "issue",
          sort: "asc",
        },
        {
          label: (
           <Link to='/admin/chatReply'> <span
              style={{
                color: "black",
                textDecorationLine: "underline",
              }}
            >
              Reply
            </span></Link>
          ),
          field: "Reason",
          sort: "asc",
        },
        {
          label: (
            <span
              style={{
                color: "black",
                textDecorationLine: "underline",
              }}
            >
              Time
            </span>
          ),
          field: "Time",
        },
      ],
      rows: [],
    };
    chat?.forEach((invest) => {
      data.rows.push({
        // Name: `${invest?.chatUserId?.first_name} ${invest?.chatUserId?.last_name} `,
        UserName: `${invest?.username} `,
        // TransactionId: invest?.transactionId,
        // AccountId: invest?.AccountId,
        issue: invest?.issue,
        Time: dayjs(invest?.createdAt).fromNow(),
        Picture: (
          <a href={invest?.picture?.Location}>
            (
            <Avatar
              src={invest?.picture?.Location}
              // alt={invest.picture?[0].Key}
              shape="square"
              size="72"
              className="mx-1"
              // onClick={()=> deleteImageUpload()}
            />
            ){" "}
          </a>
        ),
        // Reason: invest?.Reason,
      });
    });
    return data;
  };
  return (
    <div className="container" >
      {/* <h1 className='text-center'>All Users</h1> */}

      <MetaData title={"Your WithDraw"} />
      <div className="row">
        <div className="col-12 col-md-12 col-sm-12">
          <Fragment>
            <h1
              className=" text-center "
              style={{
                color: "black",
                textDecorationLine: "underline",
                // textShadow: "2px 2px 4px #73aa43",
              }}
            >
              {" "}
               ALL USER CHAT HISTORY
            </h1>
            <MDBDataTable
              data={setUserWithDraws()}
              className="px-3 py-3 box-cart purple"
              bordered
              striped
              hover
            />
          </Fragment>
        </div>
      </div>
    </div>
  );
};

export default ChatHistory;
