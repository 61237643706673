import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { toast } from "react-toastify";
import { Container, Row, Col, Card, ListGroup, Button } from "react-bootstrap";
import { MdOutlineSettings } from "react-icons/md";
import { BiMoneyWithdraw } from "react-icons/bi";
import { SiWebmoney } from "react-icons/si";
import { FaUsers } from "react-icons/fa";
import { MdWifiProtectedSetup } from "react-icons/md";
import { Avatar } from "antd";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import MetaData from "../../Components/MetaData";
dayjs.extend(relativeTime); // fromNow()  // 4 hours ago

const History = () => {
  const [loading, setLoading] = useState(true);
  const [investments, setInvestments] = useState([{}]);
  useEffect(() => {
    handleUserInvestment();
  }, []);
  const handleUserInvestment = async (e) => {
    try {
      setLoading(true);
      const { data } = await axios.get("/fetchSingleUserInvestments");
      if (data?.error) {
        setLoading(false);
        toast.error(data.error, { position: "top-center", autoClose: 3000 });
      } else {
        console.log(data.investments);
        setInvestments(data.investments);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  

  const setUserInvestments = () => {
    const data = {
      columns: [
        // {
        //   label: "Name",
        //   field: "Name",
        //   sort: "asc",
        // },

        {
          label: (
            <span
              style={{
                color: "black",
                textDecorationLine: "underline",
              }}
            >
              Amount
            </span>
          ),
          field: "Amount",
        },
        {
          label: (
            <span
              style={{
                color: "black",
                textDecorationLine: "underline",
              }}
            >
              Transaction ID
            </span>
          ),
          field: "TransactionId",
        },
        // {
        //   label: "Account Id",
        //   field: "AccountId",
        // },
        {
          label: (
            <span
              style={{
                color: "black",
                textDecorationLine: "underline",
              }}
            >
              Image
            </span>
          ),
          field: "Image",
        },
        {
          label: (
            <span
              style={{
                color: "black",
                textDecorationLine: "underline",
              }}
            >
              Status
            </span>
          ),
          field: "Status",
          sort: "asc",
        },
        {
          label: (
            <span
              style={{
                color: "black",
                textDecorationLine: "underline",
              }}
            >
              Reason
            </span>
          ),
          field: "Reason",
          sort: "asc",
        },
        {
          label: (
            <span
              style={{
                color: "black",
                textDecorationLine: "underline",
              }}
            >
              Requested Time
            </span>
          ),
          field: "InvestedTime",
        },
      ],
      rows: [],
    };
    investments?.forEach((invest) => {
      data.rows.push({
        // Name: `${invest?.investedBy?.first_name} ${invest?.investedBy?.last_name} `,
        Amount: invest?.investedAmount,
        TransactionId: invest?.transactionId,
        // AccountId: invest?.accountId,
        Status: invest?.Status,
        Reason: invest?.Reason,
        InvestedTime: dayjs(invest?.createdAt).fromNow(),
        Image: (
          <a href={invest?.picture?.Location}>
            (
            <Avatar
              src={invest?.picture?.Location}
              // alt={invest.picture?[0].Key}
              shape="square"
              size="72"
              className="mx-1"
              // onClick={()=> deleteImageUpload()}
            />
            ){" "}
          </a>
        ),
      });
    });
    return data;
  };
  return (
    <div className="container">
      {/* <h1 className='text-center'>All Users</h1> */}

      <MetaData title={"Your Investments"} />
      <div className="row">
        <div className="col-12 col-md-12 col-sm-12">
          <Fragment>
            <h1
              className=" text-center "
              style={{
                color: "black",
                textDecorationLine: "underline",
                // textShadow: "2px 2px 4px black",
              }}
            >
              {" "}
              YOUR ALL INVESTMENT{" "}
            </h1>
            <div className="rounded-3" style={{ borderRadius: "15px" }}>
              <MDBDataTable
                style={{ borderRadius: "10px" }}
                data={setUserInvestments()}
                className="px-3 py-3 box-cart purple "
                bordered
                striped
                hover
              />
            </div>
          </Fragment>
        </div>
      </div>
    </div>
  );
};

export default History;
