import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link, useLocation } from "react-router-dom";
import "../../Styles/Login.css";
import { useAuth } from "../../context/auth";
import MetaData from "../../Components/MetaData";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";

const AccessAccount = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [resetCode, setResetCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [auth, setAuth] = useAuth();
  const [loading, setLoading] = useState(false);

  const handleAccessAccount = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { data } = await axios.post("/access-account", {
        resetCode,
        newPassword,
      });
      if (data?.error) {
        toast.error(data.error, { position: "top-center", autoClose: 3000 });
        setLoading(false);
      } else {
        localStorage.setItem("auth", JSON.stringify(data));
        setAuth(data);
        setLoading(false);
        toast.info("Youa are Login Successfully", {
          position: "top-center",
          autoClose: 3000,
        });
        location?.state !== null ? navigate(location.state) : navigate("/");
        console.log(data);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong. Try again!!");
      setLoading(false);
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(true);
  };
  const togglePasswordUnVisibility = () => {
    setShowPassword(false);
  };
  return (
    <div className="align-items-center d-flex justify-content-center row">
      <MetaData title="Access-Account" />
      <form
        className="form_main h-100 fs-3 w-50 col-md-12"
        onSubmit={handleAccessAccount}
      >
        <p className="display-3 fs-3 fw-bold" style={{ color: "black" , textDecoration:"underline" }}>
          {" "}
          ACCESS ACCOUNNT
        </p>
        <p>Access Your Account Through Reset Code</p>
        {loading && (
          <p
            className="text-center"
            style={{ color: "#73aa43", marginRight: "75px" }}
          >
            {" "}
            <div className="spinner "></div>{" "}
          </p>
        )}

        <div className="inputContainer">
          <svg
            viewBox="0 0 16 16"
            fill="#2e2e2e"
            height={16}
            width={16}
            xmlns="http://www.w3.org/2000/svg"
            className="inputIcon"
          >
            <path d="M13.106 7.222c0-2.967-2.249-5.032-5.482-5.032-3.35 0-5.646 2.318-5.646 5.702 0 3.493 2.235 5.708 5.762 5.708.862 0 1.689-.123 2.304-.335v-.862c-.43.199-1.354.328-2.29.328-2.926 0-4.813-1.88-4.813-4.798 0-2.844 1.921-4.881 4.594-4.881 2.735 0 4.608 1.688 4.608 4.156 0 1.682-.554 2.769-1.416 2.769-.492 0-.772-.28-.772-.76V5.206H8.923v.834h-.11c-.266-.595-.881-.964-1.6-.964-1.4 0-2.378 1.162-2.378 2.823 0 1.737.957 2.906 2.379 2.906.8 0 1.415-.39 1.709-1.087h.11c.081.67.703 1.148 1.503 1.148 1.572 0 2.57-1.415 2.57-3.643zm-7.177.704c0-1.197.54-1.907 1.456-1.907.93 0 1.524.738 1.524 1.907S8.308 9.84 7.371 9.84c-.895 0-1.442-.725-1.442-1.914z" />
          </svg>
          <input
            placeholder="Reset-Code"
            id="username"
            className="inputField"
            type="text"
            value={resetCode}
            onChange={(e) => {
              setResetCode(e.target.value);
            }}
          />
        </div>
        <div className="inputContainer">
          <svg
            viewBox="0 0 16 16"
            fill="#2e2e2e"
            height={16}
            width={16}
            xmlns="http://www.w3.org/2000/svg"
            className="inputIcon"
          >
            <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
          </svg>
          <input
            placeholder="New-Password"
            id="password"
            className="inputField"
            type={showPassword ? "text" : "password"}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <span className="eye-icon">
            {showPassword ? (
              <FaEye onClick={togglePasswordUnVisibility} />
            ) : (
              <FaEyeSlash onClick={togglePasswordVisibility} />
            )}
          </span>
        </div>

        <button id="button" type="submit" className="" disabled={loading}>
          {loading ? "..Loading" : "Access Account"}
        </button>
      </form>
    </div>
  );
};

export default AccessAccount;
