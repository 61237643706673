import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  ProgressBar,
} from "react-bootstrap";
import "../Styles/MyInvestment.css";
import MetaData from "../Components/MetaData";
import { useAuth } from "../context/auth";
import axios from "axios";
import { Avatar } from "antd";

import { toast } from "react-toastify";
import { AiTwotoneDollarCircle } from "react-icons/ai";
import { PiSortDescendingBold } from "react-icons/pi";
import { TbFileDescription } from "react-icons/tb";
// import {Avatar} from "antd";
import Resizer from "react-image-file-resizer";

import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link, useLocation } from "react-router-dom";
import "../Styles/Login.css";


import  { Fragment, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { BiMoneyWithdraw } from "react-icons/bi";
import { SiWebmoney } from "react-icons/si";
import { FaUsers } from "react-icons/fa";
import { MdWifiProtectedSetup } from "react-icons/md";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { width } from "@fortawesome/free-brands-svg-icons/fa42Group";
dayjs.extend(relativeTime); // fromNow()  // 4 hours ago

const Picture = () => {

    

  const navigate = useNavigate();
  const location = useLocation();
  const [uploading1, setUploading1] = useState(false);
  const [uploading2, setUploading2] = useState(false);
  const [uploading3, setUploading3] = useState(false);
  const [uploading4, setUploading4] = useState(false);

  const [Image1, setImage1] = useState("");
  const [Image2, setImage2] = useState("");
  const [Image3, setImage3] = useState("");
  const [Image4, setImage4] = useState("");

  const [username, setUserName] = useState("");
  const [title, setTitle] = useState("");
  const [issue, setIssue] = useState("");


  const [auth, setAuth] = useAuth();
  const [loading1, setLoading1] = useState(false);


  



  const handlePictures = async (e) => {
    e.preventDefault();
    try {
      setLoading1(true);
      const { data } = await axios.post(`/admin/pictures`, {
        picture1: Image1.Location,
        picture2: Image2.Location,
        picture3: Image3.Location,
        picture4: Image4.Location,
        
      });
      if (data?.error) {
        toast.error(data.error, { position: "top-center", autoClose: 3000 });
        setLoading1(false);
      } else {
        // localStorage.setItem("investment", JSON.stringify(data.investment));
        // setAuth(data);/
        setLoading1(false);
        console.log(data);
        toast.info("Your Message send Successfully", {
          position: "top-center",
          autoClose: 3000,
        });
        // setImage("")
        setUserName("");
        setTitle("");
        setIssue("");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong. Try again!!");
      setLoading1(false);
    }
  };


  const [loading, setLoading] = useState(true);
  const [chat, setChat] = useState([]);
 
  
  

  const handleImageUpload1 = async (e) => {
    try {
     
      let file = e.target.files[0];
      if (file) {
        setUploading1(true);
        new Promise(() => {
          Resizer.imageFileResizer(
            file,
            // 200,
            // 200,
            "JPEG",
            100,
            0,
            async (uri) => {
              console.log("UPLOAD Image:", uri);
              const { data } = await axios.post("/upload-image", {
                image: uri,
              });
              console.log(data);
              setImage1(data);
              setUploading1(false);
            },
            "base64"
          );
        });
      }
    } catch (err) {
      console.log(err);
      // 'setUploading'(false);
    }
  };
  const handleImageUpload2 = async (e) => {
    try {
      
      let file = e.target.files[0];
      if (file) {
        setUploading2(true);
        new Promise(() => {
          Resizer.imageFileResizer(
            file,
            200,
            200,
            "JPEG",
            100,
            0,
            async (uri) => {
              console.log("UPLOAD Image:", uri);
              const { data } = await axios.post("/upload-image", {
                image: uri,
              });
              console.log(data);
              setImage2(data);
              setUploading2(false);
            },
            "base64"
          );
        });
      }
    } catch (err) {
      console.log(err);
      // 'setUploading'(false);
    }
  };
  const handleImageUpload3 = async (e) => {
    try {
    
      let file = e.target.files[0];
      if (file) {
        setUploading3(true);
        new Promise(() => {
          Resizer.imageFileResizer(
            file,
            200,
            200,
            "JPEG",
            100,
            0,
            async (uri) => {
              console.log("UPLOAD Image:", uri);
              const { data } = await axios.post("/upload-image", {
                image: uri,
              });
              console.log(data);
              setImage3(data);
              setUploading3(false);
            },
            "base64",
            
          );
        });
      }
    } catch (err) {
      console.log(err);
      // 'setUploading'(false);
    }
  };
  const handleImageUpload4 = async (e) => {
    try {
      
      let file = e.target.files[0];
      if (file) {
        setUploading4(true);
        new Promise(() => {
          Resizer.imageFileResizer(
            file,
            // 200,
            // 200,
            "JPEG",
            100,
            0,
            async (uri) => {
              console.log("UPLOAD Image:", uri);
              const { data } = await axios.post("/upload-image", {
                image: uri,
              });
              console.log(data);
              setImage4(data);
              setUploading4(false);
            },
            "base64"
          );
        });
      }
    } catch (err) {
      console.log(err);
      // 'setUploading'(false);
    }
  };

  return (
    <div>
        <div className="align-items-center d-flex justify-content-center row mt-4">
          <form
            className="form_main h-100 fs-3 w-50 col-md-12"
            onSubmit={handlePictures}
          >
            <p
              className="display-3 fs-3 fw-bold"
              style={{
                color: "black",
                textDecorationLine: "underline",
                // textShadow: "2px 2px 4px #73aa43",
              }}
            >
              {" "}
              UPLOAD PICTURE
            </p>
            {loading1 && (
              <p
                className="text-center"
                style={{ color: "#73aa43", marginRight: "75px" }}
              >
                {" "}
                <div className="spinner mb-4"></div>{" "}
              </p>
            )}
           

           
            <div className="inputContainer">
              <label className="upload mb-3">
                {uploading1 ? "Processing..." : "Upload Picture 1"}
                <input
                  hidden
                  type="file"
                  accept="image/*"
                  multiple
                //   disabled={auth?.user?.role.includes("Admin")}
                  onChange={handleImageUpload1}
                />
              </label>
              <a href={Image1?.Location} className="mx-3">
                (
                <Avatar
                  src={Image1?.Location}
                  // alt={invest.picture?[0].Key}
                  shape="square"
                  size="72"
                  className="mx-1"
                  // onClick={()=> deleteImageUpload()}
                />
                ){" "}
              </a>
            </div>
            <div className="inputContainer">
              <label className="upload mb-3">
                {uploading2 ? "Processing..." : "Upload Picture 2"}
                <input
                  hidden
                  type="file"
                  accept="image/*"
                  multiple
                //   disabled={auth?.user?.role.includes("Admin")}
                  onChange={handleImageUpload2}
                />
              </label>
              <a href={Image2?.Location} className="mx-3">
                (
                <Avatar
                  src={Image2?.Location}
                  // alt={invest.picture?[0].Key}
                  shape="square"
                  size="72"
                  className="mx-1"
                  // onClick={()=> deleteImageUpload()}
                />
                ){" "}
              </a>
            </div>
            <div className="inputContainer">
              <label className="upload mb-3">
                {uploading3 ? "Processing..." : "Upload Picture 3"}
                <input
                  hidden
                  type="file"
                  accept="image/*"
                  multiple
                //   disabled={auth?.user?.role.includes("Admin")}
                  onChange={handleImageUpload3}
                />
              </label>
              <a href={Image3?.Location} className="mx-3">
                (
                <Avatar
                  src={Image3?.Location}
                  // alt={invest.picture?[0].Key}
                  shape="square"
                  size="72"
                  className="mx-1"
                  // onClick={()=> deleteImageUpload()}
                />
                ){" "}
              </a>
            </div>
            <div className="inputContainer">
              <label className="upload mb-3">
                {uploading4 ? "Processing..." : "Upload Picture 4"}
                <input
                  hidden
                  type="file"
                  accept="image/*"
                  multiple
                //   disabled={auth?.user?.role.includes("Admin")}
                  onChange={handleImageUpload4}
                />
              </label>
              <a href={Image4?.Location} className="mx-3">
                (
                <Avatar
                  src={Image4?.Location}
                  // alt={invest.picture?[0].Key}
                  shape="square"
                  size="72"
                  className="mx-1"
                  // onClick={()=> deleteImageUpload()}
                />
                ){" "}
              </a>
            </div>
            <button
              id="button"
              type="submit"
              className="text-white"
              disabled={loading1}
            >
              {loading1 ? "..Loading" : "Submit"}
            </button>
          </form>
        </div>
    </div>
  )
}

export default Picture
