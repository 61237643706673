import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  ProgressBar,
} from "react-bootstrap";
import "../../Styles/MyInvestment.css";
import MetaData from "../../Components/MetaData";
import { useAuth } from "../../context/auth";
import axios from "axios";
import { toast } from "react-toastify";
import { AiTwotoneDollarCircle } from "react-icons/ai";
import { FaArrowDownWideShort } from "react-icons/fa6";
import { TbFileDescription } from "react-icons/tb";

import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link, useLocation } from "react-router-dom";
import "../../Styles/Login.css";

import { useEffect, useState } from "react";

const MyInvestment = () => {
  function myFunction() {
    var x = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  // This data would actually come from your back-end or state management
  const investments = [
    { name: "Tech Stocks", value: 15000, progress: 75 },
    { name: "Real Estate Funds", value: 30000, progress: 60 },
    { name: "Government Bonds", value: 10000, progress: 90 },
  ];

  const totalInvestment = investments.reduce(
    (sum, investment) => sum + investment.value,
    0
  );

  const navigate = useNavigate();
  const location = useLocation();
  const [amount, setAmount] = useState("");
  const [accountId, setAccountId] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [description, setDescription] = useState("");

  const [investment, setInvestment] = useState([{}]);

  const [auth, setAuth] = useAuth();
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { data } = await axios.post(`/withDraw-Request`, {
        amount,
        accountId,
        transactionId,
        description,
      });
      if (data?.error) {
        toast.error(data.error, { position: "top-center", autoClose: 3000 });
        setLoading(false);
      } else {
        localStorage.setItem("investment", JSON.stringify(data.investment));
        // setAuth(data);/
        setLoading(false);
        console.log(data);
        toast.info("Your verification request send Successfully", {
          position: "top-center",
          autoClose: 3000,
        });
        navigate("/withdrawhistory");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong. Try again!!");
      setLoading(false);
    }
  };

  // const containerStyle = {
  //   top: 0,
  //   left: 0,
  //   width: "95vw",
  //   height: "95vh",
  //   backgroundColor: "white",
  //   // overflow: "hidden",
  // };
  return (
    <div className="mt-md-5 mt-sm-5 mt-lg-0">
    <Container style={{border:"2px solid black"}} >
      <MetaData title="WithDraw" />
      <Row className="my-4">
        <Col  className="mb-5 mt-5">
          <h1
            className="text-center"
            style={{
              color: "black",
              textDecorationLine: "underline",
              // textShadow: "2px 2px 4px #73aa43",
            }}
          >
            WITHDRAWAL FUNDS
          </h1>
        </Col>
      </Row>
      <Row className="my-4 justify-content-center mb-3">
        <Col md={8} lg={6} sm={12}>
          <Card>
            <Card.Body>
              <Card.Title>
                Your Current balance is $
                {auth?.user?.balance &&
                  Number.parseFloat(auth?.user?.balance).toFixed(2)}
              </Card.Title>
              <Card.Text>
                <p className="fw-bold text-danger">
                  Note:- Limitations on Withdrawals: A{" "}
                  {auth?.user?.WithDrawLimit}$ Threshold
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        {/* <Col md={8}> */}
        {/* <Card  className="mb-3">
              <Card.Header> <b>STATUS : </b>{investment.Status}</Card.Header>
              {/* <ListGroup variant="flush">
                <ListGroup.Item>
                  Value: ${investment.value.toLocaleString()}
                  <ProgressBar
                    now={investment.progress}
                    label={`${investment.progress}%`}
                    className="mt-2"
                  />
                </ListGroup.Item>
              </ListGroup> */}
        {/* </Card> */}
        {/* </Col> */}
        <div className="align-items-center d-flex justify-content-center row mt-4">
          <form
            className="form_main h-100 fs-3  col-md-12"style={{ width: "auto" , height:"auto"}}
            onSubmit={handleLogin}
          >
            <p
              className="display-3 fs-3"
              style={{
                color: "black",
                textDecorationLine: "underline",
                // textShadow: "2px 2px 4px #73aa43",
              }}
            >
              {" "}
              WITHDRAW AMOUNT REQUEST
            </p>
            {loading && (
              <p
                className="text-center"
                style={{ color: "black", marginRight: "75px" }}
              >
                {" "}
                <div className="spinner "></div>{" "}
              </p>
            )}

            <div className="inputContainer">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                height={20}
                width={20}
                xmlns="http://www.w3.org/2000/svg"
                className="inputIcon"
              >
                <AiTwotoneDollarCircle />
              </svg>

              <input
                placeholder="Amount"
                id="username"
                className="inputField"
                type="text"
                disabled={auth?.user?.role.includes("Admin")}
                value={amount}
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
              />
            </div>
            <div className="inputContainer">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                height={20}
                width={20}
                xmlns="http://www.w3.org/2000/svg"
                className="inputIcon"
              >
                <FaArrowDownWideShort />
              </svg>
              <input
                placeholder="AccountID"
                id="text"
                className="inputField"
                type="text"
                disabled={auth?.user?.role.includes("Admin")}
                value={accountId}
                onChange={(e) => setAccountId(e.target.value)}
              />
            </div>

            {/* <input
              defaultChecked="checked"
              type="checkbox"
              className="justify-content-start"
            />
            <div className="checkmark" /> */}

            <div className="inputContainer">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                height={20}
                width={20}
                strokeLinecap="round"
                strokeLinejoin="round"
                disabled={auth?.user?.role.includes("Admin")}
                xmlns="http://www.w3.org/2000/svg"
                className="inputIcon"
              >
                <TbFileDescription />
              </svg>

              <input
                placeholder="Description"
                id="password"
                className="inputField"
                type="text"
                disabled={auth?.user?.role.includes("Admin")}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            <button
              id="button"
              type="submit"
              className=""
              disabled={
                auth?.user?.balance <= auth?.user?.WithDrawLimit
              }
            >
              {loading ? "..Loading" : "Send WithDraw Request"}
            </button>
          </form>
        </div>
      </Row>
    </Container>
    </div>
  );
};

export default MyInvestment;
