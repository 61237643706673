import React, { useState } from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../Styles/Packages.css";
import MetaData from "../../Components/MetaData";
const Packages = () => {
  const [selectedPackages, setSelectedPackages] = useState([]);
  const packages = Array.from({ length: 10 }, (_, i) => ({
    id: i + 1,
    amount: (i + 1) * 10,
  }));
  const navigate = useNavigate();

  const togglePackageSelection = (packageAmount) => {
    if (selectedPackages.includes(packageAmount)) {
      setSelectedPackages(
        selectedPackages.filter((amount) => amount !== packageAmount)
      );
    } else {
      setSelectedPackages([...selectedPackages, packageAmount]);
    }
  };

  const handleSubmit = () => {
    alert(
      `Your selected packages total to $${selectedPackages.reduce(
        (a, b) => a + b,
        0
      )}. Redirecting to the payment page.`
    );
    // Redirecting user to payment page with package details in local storage
    localStorage.setItem(
      "selectedPackageAmounts",
      JSON.stringify(selectedPackages)
    );
    navigate("/payment");
  };

  return (
    <Container className="py-5 display-6">
      <MetaData title='Packages' />
      <Row>
        <Col>
          <h2 className="text-center mb-4">Select Your Investment Packages</h2>
        </Col>
      </Row>
      <Row>
        {packages.map((pkg) => (
          <Col sm={6} md={4} lg={3} xl={2} key={pkg.id} className="mb-3">
            <Card>
              <Card.Body>
                <Card.Title>${pkg.amount}</Card.Title>
                <Button
                  variant={
                    selectedPackages.includes(pkg.amount) ? "danger" : "primary"
                  }
                  onClick={() => togglePackageSelection(pkg.amount)}
                >
                  {selectedPackages.includes(pkg.amount)
                    ? "Deselect"
                    : "Select"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <div className="d-flex justify-content-end align-items-center mt-3">
        <span className="me-3">
          Number of packages Selected: {selectedPackages.length}
        </span>
        <Button disabled={selectedPackages.length === 0} onClick={handleSubmit}>
          Proceed to Payment
        </Button>
      </div>
    </Container>
  );
};

export default Packages;

// import { Card, Button, Container, Row, Col } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { useState } from "react";
// import "../../Styles/Packages.css";
// import { useNavigate } from "react-router-dom";

// const Packages = () => {
//   const [selectedPackages, setSelectedPackages] = useState([]);

//   const packages = Array.from({ length: 10 }, (_, i) => (i + 1) * 10);

//   const navigate = useNavigate();

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     alert(
//       "Your selected packages are" +
//         selectedPackages +
//         "we are Taking you to the  Payment Page"
//     );
//     // Redirecting user to payment page with  package details in local storage
//     localStorage.setItem("packages", JSON.stringify(selectedPackages));
//     localStorage.setItem("amount");
//     navigate("/payment");
//   };

//   const togglePackageSelection = (amount) => {
//     setSelectedPackages((prevSelected) =>
//       prevSelected.includes(amount)
//         ? prevSelected.filter((x) => x !== amount)
//         : [...prevSelected, amount]
//     );
//   };

//   return (
//     <Container className="py-5">
//       <Row>
//         <Col>
//           <h2 className="text-center mb-4">Investment Packages</h2>
//         </Col>
//       </Row>
//       <Row>
//         {packages.map((amount) => (
//           <Col sm={6} md={4} lg={3} xl={2} key={amount} className="mb-3">
//             <Card>
//               <Card.Body>
//                 <Card.Title>${amount}</Card.Title>
//                 <Card.Text>
//                   Invest in our ${amount} package and enjoy competitive returns.
//                 </Card.Text>
//                 <Button
//                   variant={
//                     selectedPackages.includes(amount) ? "success" : "primary"
//                   }
//                   onClick={() => togglePackageSelection(amount)}
//                 >
//                   {selectedPackages.includes(amount)
//                     ? "Selected"
//                     : "Select Package"}
//                 </Button>
//               </Card.Body>
//             </Card>
//           </Col>
//         ))}
//       </Row>
//       <div className="d-flex justify-content-end align-items-center mt-3">
//         <span className="me-3">
//           Number of packages: {selectedPackages.length}
//         </span>
//         <Button disabled={!selectedPackages.length} onClick={handleSubmit}>
//           Submit
//         </Button>
//       </div>
//     </Container>
//   );
// };
// export default Packages;
