import React, { useState } from "react";
import Footer from "../Components/Footer";
import "../Styles/elemenets/_icons.scss";
import "../Styles/elemenets/_breadcrumbs.scss";
import { Carousel } from "react-responsive-carousel";
import MetaData from "../Components/MetaData";
import { useAuth } from "../context/auth";
import { useNavigate } from "react-router-dom";
// import "../Styles/elemenets/";
// import "../Styles/elemenets/_tiles-content.scss";
const Contact = () => {
  const containerStyle = {
    top: 0,
    left: 0,
    width: "98vw",
    height: "250vh",
    backgroundColor: "white",
    // overflow: "hidden",
  };

  const [auth , setAuth] = useAuth()
  // const [show , setShow] = useState(false)
  const loggedIn =
  auth.user !== null && auth.token !== "" && auth.refreshToken !== "";
const navigate = useNavigate()
const chatHandler = () => {
  if (loggedIn) {
    navigate("/supportchat");
  } else {
    navigate("/login", {
      state: "/supportchat",
    });
  }
};
  return (
    <div className="" style={{ overflowX: "hidden", position: "relative" }}>
      <MetaData title="Contact" />
      <div id="fb-root" />
      <div className="wrapper mb-5">
        <nav className="breadcrumbs mb-5">
          <ul className="inline-list">
            <li>
              <span>Start</span>
              <span className="separator">&nbsp;/ </span>{" "}
            </li>
            <li>
              <span className="">&nbsp;contact</span>
            </li>
          </ul>
        </nav>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n\n        .text-page-section h1,\n        .text-page-section h2,\n        .text-page-section h3,\n        .text-page-section h4,\n        .text-page-section h5,\n        .text-page-section h6 {\n            color: #000000;\n        }\n\n        .text-page-type-text_bg {\n            color: #000000;\n            background-color: #FFFFFF;\n        }\n        .text-page-type-text_bg .text-page-content p\n        .text-page-type-text_bg .text-page-content i\n        .text-page-type-text_bg .text-page-content em\n        .text-page-type-text_bg .text-page-content h1\n        .text-page-type-text_bg .text-page-content h2\n        .text-page-type-text_bg .text-page-content h3\n        .text-page-type-text_bg .text-page-content h4\n        .text-page-type-text_bg .text-page-content h5\n        .text-page-type-text_bg .text-page-content h6 {\n            color: #000000;\n        }\n        .text-page-content.row {\n            display: -webkit-box;\n            display: -ms-flexbox;\n            display: flex;\n            -ms-flex-wrap: wrap;\n            flex-wrap: wrap;\n            margin-left: -15px;\n            margin-right: -15px;\n        }\n\n        .text-page-content.row [class*='col-'],\n        .text-page-content.row [class^='col-'] {\n            padding-left: 15px;\n            padding-right: 15px\n        }\n\n        .text-page-content-text.col-100 {\n            width: 100%;\n        }\n        @media screen and (min-width: 992px) {\n            .text-page-content-text.col-lg-50 {\n                width: 50%;\n            }\n        }\n    ",
        }}
      />
      <div className="wrapper ">
        <section className="alerts " style={{marginTop:"100px"}} role="alert"></section>
        <div className="text-page-section"></div>
      </div>
      <section className="text-page-section text-page-type-raw">
        <div className="wrapper mt-5">
          <div className="text-page-content  ">
            <div className="text-page-content-text col-100 ">
              <section id="contact">
                <div className="contact-boxes">
                  <div className="left-column">
                    <div className="contact-box">
                      <h2 className="box-title"></h2>
                      <div className="contact-big">
                        <p></p>
                      </div>
                      <p></p>
                      <address className="mt-5">
                        <h1
                          style={{
                            // fontFamily: 'inherit  ',
                            // fontWeight: "lighter",
                            // unicodeBidi:"isolate",
                            // lineHeight:1.1
                            // fontStyle: "normal",
                            // fontWeight: "50px",
                            // fontWeight: 100,
                            // fontSize: '20px', fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif', fontWeight: 100
                            // fontFamily: "Arial, Helvetica, sans-serif",
                            // fontSize: 20,
                            // marginTop:"30px"
                          }}
                          className="inline-list mt-5"
                        >
                          MANUFACTURER
                        </h1>

                        <br />
                        <p
                          style={{
                            color: "#000000",
                            // fontFamily: "Verdana, Arial, Helvetica, sans-serif",

                            fontSize: 11,
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "start",
                            textIndent: 0,
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            textDecorationThickness: "initial",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                          }}
                        >
                          <span style={{ fontSize: 16 }}>Ziaja Ltd</span>
                          <br />
                          <span style={{ fontSize: 16 }}>ul. Jesienna 9</span>
                          <br />
                          <span style={{ fontSize: 16 }}>80-298 Gdansk</span>
                          <br />
                          <span style={{ fontSize: 16 }}>Poland</span>
                        </p>
                        <p
                          style={{
                            color: "#000000",
                            fontFamily: "Verdana, Arial, Helvetica, sans-serif",
                            fontSize: 11,
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "start",
                            textIndent: 0,
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            textDecorationThickness: "initial",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                          }}
                        >
                          <span
                            style={{ fontSize: 16 }}
                            // className="text-success"
                          >
                            <a
                            href=""
                              onClick={chatHandler}
                              style={{
                                textDecoration: "none",
                                color: "#73aa43",
                              }}
                              className="fw-bold"
                            >
                              Support Chat
                            </a>
                            <a href="mailto:export@ziaja.com">
                              <br />
                            </a>
                            phone +48 58 521 35 10
                          </span>
                        </p>
                      </address>
                    </div>
                  </div>
                  <div className="right-column">
                    <div className="contact-box">
                      <p></p>
                    </div>
                    <div className="contact-box">
                      <p></p>
                    </div>
                    <div className="contact-box">
                      <p></p>
                    </div>
                    <div className="contact-box">
                      <p></p>
                    </div>
                  </div>
                </div>
                <div className="tiles row  mt-5" >
                  <div className="links no-form col-lg-5 col-md-6 col-sm-12 mt-5 d-flex justify-content-around align-items-center" style={{backgroundColor:"#f6f6f6" , height:"125px"}}>
                  {/* filter: 'sepia(100%) saturate(1000%) hue-rotate(107deg) */}
                  <img style={{height:"40px"}} src="./location.webp" alt="" />

                    <a 
                                  // onMouseEnter={(e) => ()=>(setShow(true))} 
                                  // onMouseLeave={(e) => (e.target.style.color = "black")} // Change text color back to black on mouse leave

                    href="https://en.ziaja.com/where-to-buy" data-toggle="tooltip" data-placement="top"  data-bs-title="Tooltip on top" className="tile text-decoration-none" >
                      <span >
                        {/* <div className="icon"> */}
                        {/* <i className="fa fa-map-marker" aria-hidden="true" /> */}
                        {/* </div> */}
                        <h1 className="tile-title fw-bold " data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Tooltip on top" title="Click Here">FIND STORE </h1>
                      </span>
            
                    </a>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <Footer />
      </section>
      <div className="wrapper">
        <section className="text-page-section children-links"></section>
      </div>
    </div>
  );
};

export default Contact;
