import React from "react";
import "../Styles/_FilterModal.scss";
const FilterModal2 = () => {
  return (
    <div className="mt-3" style={{ marginTop: "10000px" }}>
      <div className="wrapper">
        <section id="product-list">
          <div className="load-more-indicator" style={{ textAlign: "center" }}>
            &nbsp;
          </div>
          <div className="product-filters">
            <header className="filters-grid-header " data-pages={67}>
              <h1 className="page-title">Brand: ziaja</h1>
            </header>
            <div className="filters-grid">
              <form name="filter" method="get" action="/products/line/ziaja">
                <div className="fields ">
                  <div className="select">
                    <div className="form-group">
                      <label
                        className="control-label"
                        htmlFor="filter_category"
                      >
                        Category
                      </label>{" "}
                      <select
                        id="filter_category"
                        name="filter[category][]"
                        placeholder="Search filters..."
                        className="form-control select2-hidden-accessible"
                        multiple=""
                        data-placeholder="Search filters..."
                        tabIndex={-1}
                        aria-hidden="true"
                      >
                        <option value={10010} data-count={160}>
                          body care
                        </option>
                        <option value={10011} data-count={171}>
                          face care
                        </option>
                        <option value={10013} data-count={49}>
                          hair care
                        </option>
                        <option value={10312} data-count={8}>
                          oral hygiene
                        </option>
                        <option value={10358} data-count={316}>
                          vegan
                        </option>
                      </select>
                      <span
                        className="select2 select2-container select2-container--default"
                        dir="ltr"
                        style={{ width: 263 }}
                      >
                        <span className="selection">
                          <span
                            className="select2-selection select2-selection--multiple"
                            role="combobox"
                            aria-haspopup="true"
                            aria-expanded="false"
                            tabIndex={-1}
                          >
                            <ul className="select2-selection__rendered">
                              <li className="select2-search select2-search--inline">
                                <input
                                  className="select2-search__field"
                                  type="search"
                                  tabIndex={0}
                                  autoComplete="off"
                                  autoCorrect="off"
                                  autoCapitalize="off"
                                  spellCheck="false"
                                  role="textbox"
                                  aria-autocomplete="list"
                                  placeholder="Search filters..."
                                  style={{ width: 255 }}
                                />
                              </li>
                            </ul>
                          </span>
                        </span>
                        <span className="dropdown-wrapper" aria-hidden="true" />
                      </span>
                    </div>
                  </div>
                  <div className="select">
                    <div className="form-group">
                      <label className="control-label" htmlFor="filter_type">
                        product Type
                      </label>{" "}
                      <select
                        id="filter_type"
                        name="filter[type][]"
                        placeholder="Search filters..."
                        className="form-control select2-hidden-accessible"
                        multiple=""
                        data-placeholder="Search filters..."
                        tabIndex={-1}
                        aria-hidden="true"
                      >
                        <option value={13649} data-count={4}>
                          after-shave balm
                        </option>
                        <option value={10262} data-count={7}>
                          anti-perspirants
                        </option>
                        <option value={10271} data-count={1}>
                          antibacterial and disinfecting
                        </option>
                        <option value={11348} data-count={4}>
                          bath jelly soaps
                        </option>
                        <option value={10255} data-count={5}>
                          body balms
                        </option>
                        <option value={10256} data-count={6}>
                          body butters
                        </option>
                        <option value={11873} data-count={5}>
                          bubble baths
                        </option>
                        <option value={10260} data-count={9}>
                          cleansing gels
                        </option>
                        <option value={10277} data-count={3}>
                          cleansing milks
                        </option>
                        <option value={10264} data-count={10}>
                          eye creams
                        </option>
                        <option value={10265} data-count={60}>
                          face creams
                        </option>
                        <option value={10266} data-count={27}>
                          face masks
                        </option>
                        <option value={10267} data-count={4}>
                          foot care
                        </option>
                        <option value={10269} data-count={11}>
                          hair conditioners
                        </option>
                        <option value={10270} data-count={8}>
                          hair masks
                        </option>
                        <option value={11865} data-count={20}>
                          hand creams
                        </option>
                        <option value={10272} data-count={6}>
                          hand soaps
                        </option>
                        <option value={10273} data-count={15}>
                          intimate care
                        </option>
                        <option value={10275} data-count={4}>
                          lip balms
                        </option>
                        <option value={13710} data-count={4}>
                          lip gloss
                        </option>
                        <option value={13713} data-count={4}>
                          lip scrub
                        </option>
                        <option value={10257} data-count={19}>
                          lotions
                        </option>
                        <option value={10276} data-count={17}>
                          make-up removers
                        </option>
                        <option value={11350} data-count={3}>
                          mists
                        </option>
                        <option value={11346} data-count={6}>
                          mousses
                        </option>
                        <option value={10313} data-count={2}>
                          mouthwashes
                        </option>
                        <option value={10278} data-count={7}>
                          oils
                        </option>
                        <option value={10279} data-count={8}>
                          peels
                        </option>
                        <option value={10280} data-count={18}>
                          scrubs
                        </option>
                        <option value={10281} data-count={14}>
                          serums
                        </option>
                        <option value={10282} data-count={27}>
                          shampoos
                        </option>
                        <option value={10284} data-count={44}>
                          shower gels
                        </option>
                        <option value={10285} data-count={8}>
                          special
                        </option>
                        <option value={10286} data-count={11}>
                          sprays
                        </option>
                        <option value={10287} data-count={8}>
                          sun care
                        </option>
                        <option value={10288} data-count={11}>
                          toners
                        </option>
                        <option value={10289} data-count={7}>
                          toothpastes
                        </option>
                      </select>
                      <span
                        className="select2 select2-container select2-container--default"
                        dir="ltr"
                        style={{ width: 263 }}
                      >
                        <span className="selection">
                          <span
                            className="select2-selection select2-selection--multiple"
                            role="combobox"
                            aria-haspopup="true"
                            aria-expanded="false"
                            tabIndex={-1}
                          >
                            <ul className="select2-selection__rendered">
                              <li className="select2-search select2-search--inline">
                                <input
                                  className="select2-search__field"
                                  type="search"
                                  tabIndex={0}
                                  autoComplete="off"
                                  autoCorrect="off"
                                  autoCapitalize="off"
                                  spellCheck="false"
                                  role="textbox"
                                  aria-autocomplete="list"
                                  placeholder="Search filters..."
                                  style={{ width: 255 }}
                                />
                              </li>
                            </ul>
                          </span>
                        </span>
                        <span className="dropdown-wrapper" aria-hidden="true" />
                      </span>
                    </div>
                  </div>
                  <div className="select">
                    <div className="form-group">
                      <label className="control-label" htmlFor="filter_skin">
                        skin
                      </label>{" "}
                      <select
                        id="filter_skin"
                        name="filter[skin][]"
                        placeholder="Search filters..."
                        className="form-control select2-hidden-accessible"
                        multiple=""
                        data-placeholder="Search filters..."
                        tabIndex={-1}
                        aria-hidden="true"
                      >
                        <option value={10079} data-count={7}>
                          acne prone
                        </option>
                        <option value={10080} data-count={165}>
                          all types
                        </option>
                        <option value={10082} data-count={1}>
                          atopic
                        </option>
                        <option value={10314} data-count={32}>
                          combination
                        </option>
                        <option value={10084} data-count={1}>
                          cracked
                        </option>
                        <option value={10085} data-count={35}>
                          dehydrated
                        </option>
                        <option value={10086} data-count={5}>
                          dilated capillaries
                        </option>
                        <option value={10087} data-count={108}>
                          dry
                        </option>
                        <option value={10324} data-count={8}>
                          dull
                        </option>
                        <option value={10088} data-count={12}>
                          irritated
                        </option>
                        <option value={10089} data-count={41}>
                          mature / wrinkle prone
                        </option>
                        <option value={10090} data-count={5}>
                          non-elastic
                        </option>
                        <option value={10091} data-count={70}>
                          normal
                        </option>
                        <option value={10315} data-count={33}>
                          oily
                        </option>
                        <option value={10093} data-count={9}>
                          pigmentation spots
                        </option>
                        <option value={10096} data-count={39}>
                          sensitive
                        </option>
                        <option value={10098} data-count={12}>
                          tired
                        </option>
                        <option value={10099} data-count={12}>
                          very dry
                        </option>
                      </select>
                      <span
                        className="select2 select2-container select2-container--default"
                        dir="ltr"
                        style={{ width: 263 }}
                      >
                        <span className="selection">
                          <span
                            className="select2-selection select2-selection--multiple"
                            role="combobox"
                            aria-haspopup="true"
                            aria-expanded="false"
                            tabIndex={-1}
                          >
                            <ul className="select2-selection__rendered">
                              <li className="select2-search select2-search--inline">
                                <input
                                  className="select2-search__field"
                                  type="search"
                                  tabIndex={0}
                                  autoComplete="off"
                                  autoCorrect="off"
                                  autoCapitalize="off"
                                  spellCheck="false"
                                  role="textbox"
                                  aria-autocomplete="list"
                                  placeholder="Search filters..."
                                  style={{ width: 255 }}
                                />
                              </li>
                            </ul>
                          </span>
                        </span>
                        <span className="dropdown-wrapper" aria-hidden="true" />
                      </span>
                    </div>
                  </div>
                  <div className="select">
                    <div className="form-group">
                      <label className="control-label" htmlFor="filter_series">
                        Line
                      </label>{" "}
                      <select
                        id="filter_series"
                        name="filter[series][]"
                        placeholder="Search filters..."
                        className="form-control select2-hidden-accessible"
                        multiple=""
                        data-placeholder="Search filters..."
                        tabIndex={-1}
                        aria-hidden="true"
                      >
                        <option value={10305} data-count={8}>
                          acai berry
                        </option>
                        <option value={10021} data-count={3}>
                          aloe
                        </option>
                        <option value={10022} data-count={4}>
                          anti-perspirants
                        </option>
                        <option value={14321} data-count={4}>
                          antioxidant hair care
                        </option>
                        <option value={10023} data-count={8}>
                          argan oil
                        </option>
                        <option value={10024} data-count={3}>
                          avocado
                        </option>
                        <option value={12498} data-count={6}>
                          baltic home spa vitality
                        </option>
                        <option value={11345} data-count={9}>
                          baltic home spa fit
                        </option>
                        <option value={12360} data-count={7}>
                          baltic home spa wellness
                        </option>
                        <option value={10025} data-count={7}>
                          BB &amp; CC creams
                        </option>
                        <option value={10028} data-count={7}>
                          cashmere
                        </option>
                        <option value={10030} data-count={3}>
                          ceramides
                        </option>
                        <option value={10031} data-count={13}>
                          cocoa butter
                        </option>
                        <option value={10032} data-count={3}>
                          coconut{" "}
                        </option>
                        <option value={14382} data-count={8}>
                          coconut &amp; orange vibes
                        </option>
                        <option value={10035} data-count={5}>
                          cucumber{" "}
                        </option>
                        <option value={10036} data-count={3}>
                          cupuacu
                        </option>
                        <option value={11315} data-count={12}>
                          delicious skin care
                        </option>
                        <option value={10038} data-count={4}>
                          eye creams &amp; gels
                        </option>
                        <option value={10306} data-count={11}>
                          face masks
                        </option>
                        <option value={13734} data-count={4}>
                          face masks and scrubs with acids
                        </option>
                        <option value={10066} data-count={5}>
                          family shampoos
                        </option>
                        <option value={10039} data-count={4}>
                          foot care
                        </option>
                        <option value={10326} data-count={11}>
                          gdanskin
                        </option>
                        <option value={10359} data-count={10}>
                          gift sets
                        </option>
                        <option value={10041} data-count={20}>
                          goat's milk
                        </option>
                        <option value={10044} data-count={7}>
                          hand care
                        </option>
                        <option value={10045} data-count={4}>
                          intimate creamy wash
                        </option>
                        <option value={10307} data-count={3}>
                          intimate foam washes
                        </option>
                        <option value={10046} data-count={2}>
                          intimate wash gel basic
                        </option>
                        <option value={10047} data-count={4}>
                          intimate wash gel herbal
                        </option>
                        <option value={10048} data-count={6}>
                          jasmine
                        </option>
                        <option value={10367} data-count={16}>
                          jeju
                        </option>
                        <option value={10049} data-count={4}>
                          lifting solution
                        </option>
                        <option value={13704} data-count={9}>
                          lip care
                        </option>
                        <option value={10050} data-count={8}>
                          make-up remover
                        </option>
                        <option value={10051} data-count={5}>
                          manuka tree
                        </option>
                        <option value={10052} data-count={2}>
                          marigold
                        </option>
                        <option value={10053} data-count={5}>
                          marine algae spa
                        </option>
                        <option value={10054} data-count={2}>
                          massage oils
                        </option>
                        <option value={10075} data-count={7}>
                          men
                        </option>
                        <option value={12507} data-count={8}>
                          men shower gels and after-shave balms
                        </option>
                        <option value={10309} data-count={6}>
                          mintperfect
                        </option>
                        <option value={10056} data-count={2}>
                          multivitamin
                        </option>
                        <option value={12482} data-count={14}>
                          natural care
                        </option>
                        <option value={10059} data-count={4}>
                          olive leaf
                        </option>
                        <option value={10057} data-count={16}>
                          olive oil
                        </option>
                        <option value={10060} data-count={4}>
                          orange butter{" "}
                        </option>
                        <option value={12376} data-count={8}>
                          pineapple skin care
                        </option>
                        <option value={10062} data-count={4}>
                          rose butter
                        </option>
                        <option value={10063} data-count={4}>
                          saffron
                        </option>
                        <option value={10065} data-count={6}>
                          sensitive skin
                        </option>
                        <option value={10040} data-count={8}>
                          shower gels &amp; bubble baths
                        </option>
                        <option value={10067} data-count={7}>
                          silk
                        </option>
                        <option value={10069} data-count={2}>
                          subtle bronze
                        </option>
                        <option value={10072} data-count={1}>
                          vaseline
                        </option>
                        <option value={13679} data-count={8}>
                          vitamin c.b3 niacinamide
                        </option>
                        <option value={10073} data-count={16}>
                          ziaja baby
                        </option>
                        <option value={10074} data-count={4}>
                          ziaja kids
                        </option>
                        <option value={10076} data-count={2}>
                          ziaja mum
                        </option>
                        <option value={10077} data-count={9}>
                          ziaja sun
                        </option>
                      </select>
                      <span
                        className="select2 select2-container select2-container--default"
                        dir="ltr"
                        style={{ width: 263 }}
                      >
                        <span className="selection">
                          <span
                            className="select2-selection select2-selection--multiple"
                            role="combobox"
                            aria-haspopup="true"
                            aria-expanded="false"
                            tabIndex={-1}
                          >
                            <ul className="select2-selection__rendered">
                              <li className="select2-search select2-search--inline">
                                <input
                                  className="select2-search__field"
                                  type="search"
                                  tabIndex={0}
                                  autoComplete="off"
                                  autoCorrect="off"
                                  autoCapitalize="off"
                                  spellCheck="false"
                                  role="textbox"
                                  aria-autocomplete="list"
                                  placeholder="Search filters..."
                                  style={{ width: 255 }}
                                />
                              </li>
                            </ul>
                          </span>
                        </span>
                        <span className="dropdown-wrapper" aria-hidden="true" />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="summary leaf-heading">
                  <span className="filter-product-count">
                    products Found : <span className="number">400</span>
                  </span>
                </div>
              </form>
            </div>
          </div>
          <div className="product-list">
            <article className="product ">
              <img
                src="/images/theme/product-type-vegan.png?v0p2R2ENe"
                alt="product.label.special.vegan"
                className="product-type-vegan"
              />
              <a
                href="/product/17676-vitamin-c-b3-niacinamide-acid-face-toner"
                className="product-link"
              >
                <img
                  className="img-responsive product-img ct"
                  src="https://en.ziaja.com/media/cache/resolve/product_list/media/product/image/ZIAJA%2520EN_DE_ES/Vitamin%2520C.B3/acid%2520face%2520toner%2520600x860.jpg"
                  alt="acid face toner"
                />
                <header>
                  <h3 className="product-title">acid face toner</h3>
                </header>
              </a>
              <div className="traits">
                <div className="trait">
                  <h4 className="trait-title">Line</h4>
                  <span>vitamin c.b3 niacinamide </span>
                </div>
                <div className="trait">
                  <h4 className="trait-title">product Type</h4>
                  <span>toners</span>
                </div>
                <div className="trait">
                  <h4 className="trait-title">skin</h4>
                  <span>
                    dehydrated, dry, mature / wrinkle prone, normal,
                    pigmentation spots, combination, oily{" "}
                  </span>
                </div>
              </div>
            </article>
            <article className="product ">
              <img
                src="/images/theme/product-type-vegan.png?v0p2R2ENe"
                alt="product.label.special.vegan"
                className="product-type-vegan"
              />
              <a
                href="/product/13343-active-anticaries-mouthwash"
                className="product-link"
              >
                <img
                  className="img-responsive product-img ct"
                  src="https://en.ziaja.com/media/cache/resolve/product_list/media/product/image/ZIAJA%2520EN_DE_ES/Mintperfect/600x860px-06.jpg"
                  alt="active anticaries mouthwash"
                />
                <header>
                  <h3 className="product-title">active anticaries mouthwash</h3>
                </header>
              </a>
              <div className="traits">
                <div className="trait">
                  <h4 className="trait-title">Line</h4>
                  <span>mintperfect </span>
                </div>
                <div className="trait">
                  <h4 className="trait-title">product Type</h4>
                  <span>mouthwashes</span>
                </div>
              </div>
            </article>
            <article className="product ">
              <img
                src="/images/theme/product-type-vegan.png?v0p2R2ENe"
                alt="product.label.special.vegan"
                className="product-type-vegan"
              />
              <a
                href="/product/17670-vitamin-c-b3-niacinamide-active-face-serum"
                className="product-link"
              >
                <img
                  className="img-responsive product-img ct"
                  src="https://en.ziaja.com/media/cache/resolve/product_list/media/product/image/ZIAJA%2520EN_DE_ES/Vitamin%2520C.B3/serum%2520600x860.jpg"
                  alt="active face serum"
                />
                <header>
                  <h3 className="product-title">active face serum</h3>
                </header>
              </a>
              <div className="traits">
                <div className="trait">
                  <h4 className="trait-title">Line</h4>
                  <span>vitamin c.b3 niacinamide </span>
                </div>
                <div className="trait">
                  <h4 className="trait-title">product Type</h4>
                  <span>serums</span>
                </div>
                <div className="trait">
                  <h4 className="trait-title">skin</h4>
                  <span>
                    dehydrated, dry, mature / wrinkle prone, normal,
                    pigmentation spots, combination, oily{" "}
                  </span>
                </div>
              </div>
            </article>
            <article className="product ">
              <img
                src="/images/theme/product-type-vegan.png?v0p2R2ENe"
                alt="product.label.special.vegan"
                className="product-type-vegan"
              />
              <a
                href="/product/9673-active-firming-cream"
                className="product-link"
              >
                <img
                  className="img-responsive product-img ct"
                  src="https://en.ziaja.com/media/cache/resolve/product_list/media/product/image/ZIAJA%2520EN_DE_ES/Marine%2520algae/600x860px-02.jpg"
                  alt="active firming cream"
                />
                <header>
                  <h3 className="product-title">active firming cream</h3>
                </header>
              </a>
              <div className="traits">
                <div className="trait">
                  <h4 className="trait-title">Line</h4>
                  <span>marine algae spa </span>
                </div>
                <div className="trait">
                  <h4 className="trait-title">product Type</h4>
                  <span>face creams</span>
                </div>
                <div className="trait">
                  <h4 className="trait-title">skin</h4>
                  <span>dry, mature / wrinkle prone, normal </span>
                </div>
              </div>
            </article>
            <article className="product ">
              <img
                src="/images/theme/product-type-vegan.png?v0p2R2ENe"
                alt="product.label.special.vegan"
                className="product-type-vegan"
              />
              <a
                href="/product/13335-mintperfect-active-remineralising-toothpaste"
                className="product-link"
              >
                <img
                  className="img-responsive product-img ct"
                  src="https://en.ziaja.com/media/cache/resolve/product_list/media/product/image/ZIAJA%2520EN_DE_ES/Mintperfect/600x860px-03.jpg"
                  alt="active remineralising toothpaste"
                />
                <header>
                  <h3 className="product-title">
                    active remineralising toothpaste
                  </h3>
                </header>
              </a>
              <div className="traits">
                <div className="trait">
                  <h4 className="trait-title">Line</h4>
                  <span>mintperfect </span>
                </div>
                <div className="trait">
                  <h4 className="trait-title">product Type</h4>
                  <span>toothpastes</span>
                </div>
              </div>
            </article>
            <article className="product " data-page={1}>
              <img
                src="/images/theme/product-type-vegan.png?v0p2R2ENe"
                alt="product.label.special.vegan"
                className="product-type-vegan"
              />
              <a
                href="/product/10278-active-protection-antiperspirant"
                className="product-link"
              >
                <img
                  className="img-responsive product-img ct"
                  src="https://en.ziaja.com/media/cache/resolve/product_list/media/product/image/ZIAJA%2520EN_DE_ES/Anti-perspirants/600x860px-01.jpg"
                  alt="active-protection antiperspirant"
                />
                <header>
                  <h3 className="product-title">
                    active-protection antiperspirant
                  </h3>
                </header>
              </a>
              <div className="traits">
                <div className="trait">
                  <h4 className="trait-title">Line</h4>
                  <span>anti-perspirants </span>
                </div>
                <div className="trait">
                  <h4 className="trait-title">product Type</h4>
                  <span>anti-perspirants</span>
                </div>
              </div>
            </article>
            <article className="product " style={{}}>
              <img
                src="/images/theme/product-type-vegan.png?v0p2R2ENe"
                alt="product.label.special.vegan"
                className="product-type-vegan"
              />
              <a
                href="/product/10747-after-shave-balm-ziaja-unproper"
                className="product-link"
              >
                <img
                  className="img-responsive product-img ct"
                  src="https://en.ziaja.com/media/cache/resolve/product_list/media/product/image/ZIAJA%2520EN_DE_ES/Men/600x860px_4.jpg"
                  alt="after shave balm"
                />
                <header>
                  <h3 className="product-title">after shave balm</h3>
                </header>
              </a>
              <div className="traits">
                <div className="trait">
                  <h4 className="trait-title">Line</h4>
                  <span>men </span>
                </div>
                <div className="trait">
                  <h4 className="trait-title">product Type</h4>
                  <span>face creams</span>
                </div>
                <div className="trait">
                  <h4 className="trait-title">skin</h4>
                  <span>dry, normal </span>
                </div>
              </div>
            </article>
            <article className="product " style={{}}>
              <img
                src="/images/theme/product-type-vegan.png?v0p2R2ENe"
                alt="product.label.special.vegan"
                className="product-type-vegan"
              />
              <a
                href="/product/10420-after-sun-soothing-gel"
                className="product-link"
              >
                <img
                  className="img-responsive product-img ct"
                  src="https://en.ziaja.com/media/cache/resolve/product_list/media/product/image/ZIAJA%2520EN_DE_ES/Sun/600x860px_8.jpg"
                  alt="after sun soothing gel"
                />
                <header>
                  <h3 className="product-title">after sun soothing gel</h3>
                </header>
              </a>
              <div className="traits">
                <div className="trait">
                  <h4 className="trait-title">Line</h4>
                  <span>ziaja sun </span>
                </div>
                <div className="trait">
                  <h4 className="trait-title">product Type</h4>
                  <span>sun care</span>
                </div>
                <div className="trait">
                  <h4 className="trait-title">skin</h4>
                  <span>all types </span>
                </div>
              </div>
            </article>
            <article className="product " style={{}}>
              <img
                src="/images/theme/product-type-vegan.png?v0p2R2ENe"
                alt="product.label.special.vegan"
                className="product-type-vegan"
              />
              <a
                href="/product/10469-after-sun-tan-prolonger"
                className="product-link"
              >
                <img
                  className="img-responsive product-img ct"
                  src="https://en.ziaja.com/media/cache/resolve/product_list/media/product/image/ZIAJA%2520EN_DE_ES/Sun/600x860px_7.jpg"
                  alt="after sun tan prolonger"
                />
                <header>
                  <h3 className="product-title">after sun tan prolonger</h3>
                </header>
              </a>
              <div className="traits">
                <div className="trait">
                  <h4 className="trait-title">Line</h4>
                  <span>ziaja sun </span>
                </div>
                <div className="trait">
                  <h4 className="trait-title">product Type</h4>
                  <span>sun care</span>
                </div>
                <div className="trait">
                  <h4 className="trait-title">skin</h4>
                  <span>all types </span>
                </div>
              </div>
            </article>
            <article className="product " style={{}}>
              <img
                src="/images/theme/product-type-vegan.png?v0p2R2ENe"
                alt="product.label.special.vegan"
                className="product-type-vegan"
              />
              <a
                href="/product/17625-after-shave-balm-lemon-verbena"
                className="product-link"
              >
                <img
                  className="img-responsive product-img ct"
                  src="https://en.ziaja.com/media/cache/resolve/product_list/media/product/image/ZIAJA%2520EN_DE_ES/Men%2520shower%2520gels%2520and%2520shampoos/after-shave%2520balm%2520lemon%2520verbena%2520600x860.jpg"
                  alt="after-shave balm lemon verbena"
                />
                <header>
                  <h3 className="product-title">
                    after-shave balm lemon verbena
                  </h3>
                </header>
              </a>
              <div className="traits">
                <div className="trait">
                  <h4 className="trait-title">Line</h4>
                  <span>men shower gels and after-shave balms </span>
                </div>
                <div className="trait">
                  <h4 className="trait-title">product Type</h4>
                  <span>after-shave balm</span>
                </div>
                <div className="trait">
                  <h4 className="trait-title">skin</h4>
                  <span>
                    irritated, mature / wrinkle prone, normal, sensitive,
                    combination, oily{" "}
                  </span>
                </div>
              </div>
            </article>
            <article className="product " style={{}}>
              <img
                src="/images/theme/product-type-vegan.png?v0p2R2ENe"
                alt="product.label.special.vegan"
                className="product-type-vegan"
              />
              <a
                href="/product/17621-after-shave-balm-mountain-pepper"
                className="product-link"
              >
                <img
                  className="img-responsive product-img ct"
                  src="https://en.ziaja.com/media/cache/resolve/product_list/media/product/image/ZIAJA%2520EN_DE_ES/Men%2520shower%2520gels%2520and%2520shampoos/after-shave%2520balm%2520mountain%2520pepper%2520600x860.jpg"
                  alt="after-shave balm mountain pepper"
                />
                <header>
                  <h3 className="product-title">
                    after-shave balm mountain pepper
                  </h3>
                </header>
              </a>
              <div className="traits">
                <div className="trait">
                  <h4 className="trait-title">Line</h4>
                  <span>men shower gels and after-shave balms </span>
                </div>
                <div className="trait">
                  <h4 className="trait-title">product Type</h4>
                  <span>after-shave balm</span>
                </div>
                <div className="trait">
                  <h4 className="trait-title">skin</h4>
                  <span>
                    irritated, mature / wrinkle prone, normal, sensitive,
                    combination, oily{" "}
                  </span>
                </div>
              </div>
            </article>
            <article className="product " data-page={2} style={{}}>
              <img
                src="/images/theme/product-type-vegan.png?v0p2R2ENe"
                alt="product.label.special.vegan"
                className="product-type-vegan"
              />
              <a
                href="/product/17619-after-shave-balm-red-cedar"
                className="product-link"
              >
                <img
                  className="img-responsive product-img ct"
                  src="https://en.ziaja.com/media/cache/resolve/product_list/media/product/image/ZIAJA%2520EN_DE_ES/Men%2520shower%2520gels%2520and%2520shampoos/after-shave%2520balm%2520red%2520cedar%2520600x860.jpg"
                  alt="after-shave balm red cedar"
                />
                <header>
                  <h3 className="product-title">after-shave balm red cedar</h3>
                </header>
              </a>
              <div className="traits">
                <div className="trait">
                  <h4 className="trait-title">Line</h4>
                  <span>men shower gels and after-shave balms </span>
                </div>
                <div className="trait">
                  <h4 className="trait-title">product Type</h4>
                  <span>after-shave balm</span>
                </div>
                <div className="trait">
                  <h4 className="trait-title">skin</h4>
                  <span>
                    irritated, mature / wrinkle prone, normal, sensitive,
                    combination, oily{" "}
                  </span>
                </div>
              </div>
            </article>
          </div>
          <div className="pagination-action" style={{ display: "none" }}>
            <a
              rel="next"
              href="/products/line/ziaja?page=2"
              className="btn rounded-btn"
            >
              Next page
            </a>
          </div>
          <div className="load-more-indicator" style={{ textAlign: "center" }}>
            &nbsp;
          </div>
        </section>
      </div>
      <div id="filters-modal" className="visible">
        <div className="filters-container">
          <div className="close-modal-container wrapper">
            <button className="close-modal">
              Close <span>×</span>
            </button>
          </div>
          <div className="filters-modal-content">
            <div className="filters-modal-wrap">
              <div className="wrapper">
                <div className="filters-nav">
                  <ul className="inline-list">
                    <li>
                      <a
                        href="/search/filter/denova-pro#ziaja"
                        data-item="ziaja"
                      >
                        ziaja
                      </a>
                    </li>
                    <li>
                      <a
                        href="/search/filter/denova-pro#ziaja-med"
                        data-item="ziaja-med"
                      >
                        ziaja MED
                      </a>
                    </li>
                    <li className="active">
                      <a
                        href="/search/filter/denova-pro#denova-pro"
                        data-item="denova-pro"
                      >
                        DENOVA pro
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="filters">
                  <form
                    name="filter-popup"
                    method="get"
                    action="/products/line/denova-pro"
                    id="filter-modal"
                  >
                    <div className="filters-form-items">
                      <div className="filter-items">
                        <div className="filters-row filters-row-checkbox">
                          <div className="form-group">
                            <label hidden="hidden" className="control-label">
                              Category
                            </label>{" "}
                            <div id="filter-popup_category" placeholder="">
                              <div className="checkbox">
                                <label
                                  htmlFor="filter-popup_category_10010"
                                  className=""
                                  data-count={3}
                                >
                                  <input
                                    type="checkbox"
                                    id="filter-popup_category_10010"
                                    name="filter-popup[category][]"
                                    data-count={3}
                                    defaultValue={10010}
                                  />{" "}
                                  body care
                                </label>
                              </div>
                              <div className="checkbox">
                                <label
                                  htmlFor="filter-popup_category_10011"
                                  className=""
                                  data-count={91}
                                >
                                  <input
                                    type="checkbox"
                                    id="filter-popup_category_10011"
                                    name="filter-popup[category][]"
                                    data-count={91}
                                    defaultValue={10011}
                                  />{" "}
                                  face care
                                </label>
                              </div>
                              <div className="checkbox">
                                <label
                                  htmlFor="filter-popup_category_10358"
                                  className=""
                                  data-count={80}
                                >
                                  <input
                                    type="checkbox"
                                    id="filter-popup_category_10358"
                                    name="filter-popup[category][]"
                                    data-count={80}
                                    defaultValue={10358}
                                  />{" "}
                                  vegan
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="filters-row">
                          <div>
                            <div className="form-group">
                              <label
                                className="control-label"
                                htmlFor="filter-popup_type"
                              >
                                product Type
                              </label>{" "}
                              <select
                                id="filter-popup_type"
                                name="filter-popup[type][]"
                                placeholder="Search filters..."
                                className="form-control select2-hidden-accessible"
                                multiple=""
                                data-placeholder="Search filters..."
                                tabIndex={-1}
                                aria-hidden="true"
                              >
                                <option value={10260} data-count={4}>
                                  cleansing gels
                                </option>
                                <option value={10277} data-count={1}>
                                  cleansing milks
                                </option>
                                <option value={10264} data-count={3}>
                                  eye creams
                                </option>
                                <option value={10265} data-count={24}>
                                  face creams
                                </option>
                                <option value={10266} data-count={20}>
                                  face masks
                                </option>
                                <option value={11865} data-count={1}>
                                  hand creams
                                </option>
                                <option value={10276} data-count={4}>
                                  make-up removers
                                </option>
                                <option value={10278} data-count={1}>
                                  oils
                                </option>
                                <option value={10279} data-count={4}>
                                  peels
                                </option>
                                <option value={10281} data-count={11}>
                                  serums
                                </option>
                                <option value={10285} data-count={10}>
                                  special
                                </option>
                                <option value={10288} data-count={10}>
                                  toners
                                </option>
                              </select>
                              <span
                                className="select2 select2-container select2-container--default select2-container--focus select2-container--below"
                                dir="ltr"
                                style={{ width: 245 }}
                              >
                                <span className="selection">
                                  <span
                                    className="select2-selection select2-selection--multiple"
                                    role="combobox"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    tabIndex={-1}
                                  >
                                    <ul className="select2-selection__rendered">
                                      <li className="select2-search select2-search--inline">
                                        <input
                                          className="select2-search__field"
                                          type="search"
                                          tabIndex={0}
                                          autoComplete="off"
                                          autoCorrect="off"
                                          autoCapitalize="off"
                                          spellCheck="false"
                                          role="textbox"
                                          aria-autocomplete="list"
                                          placeholder="Search filters..."
                                          style={{ width: 238 }}
                                        />
                                      </li>
                                    </ul>
                                  </span>
                                </span>
                                <span
                                  className="dropdown-wrapper"
                                  aria-hidden="true"
                                />
                              </span>
                            </div>
                          </div>
                          <div>
                            <div className="form-group">
                              <label
                                className="control-label"
                                htmlFor="filter-popup_skin"
                              >
                                skin
                              </label>{" "}
                              <select
                                id="filter-popup_skin"
                                name="filter-popup[skin][]"
                                placeholder="Search filters..."
                                className="form-control select2-hidden-accessible"
                                multiple=""
                                data-placeholder="Search filters..."
                                tabIndex={-1}
                                aria-hidden="true"
                              >
                                <option value={10079} data-count={10}>
                                  acne prone
                                </option>
                                <option value={10080} data-count={1}>
                                  all types
                                </option>
                                <option value={10314} data-count={10}>
                                  combination
                                </option>
                                <option value={10085} data-count={5}>
                                  dehydrated
                                </option>
                                <option value={10086} data-count={11}>
                                  dilated capillaries
                                </option>
                                <option value={10087} data-count={18}>
                                  dry
                                </option>
                                <option value={10324} data-count={2}>
                                  dull
                                </option>
                                <option value={10088} data-count={1}>
                                  irritated
                                </option>
                                <option value={10089} data-count={11}>
                                  mature / wrinkle prone
                                </option>
                                <option value={10090} data-count={4}>
                                  non-elastic
                                </option>
                                <option value={10091} data-count={11}>
                                  normal
                                </option>
                                <option value={10315} data-count={10}>
                                  oily
                                </option>
                                <option value={10095} data-count={1}>
                                  seborrhoea
                                </option>
                              </select>
                              <span
                                className="select2 select2-container select2-container--default"
                                dir="ltr"
                                style={{ width: 245 }}
                              >
                                <span className="selection">
                                  <span
                                    className="select2-selection select2-selection--multiple"
                                    role="combobox"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    tabIndex={-1}
                                  >
                                    <ul className="select2-selection__rendered">
                                      <li className="select2-search select2-search--inline">
                                        <input
                                          className="select2-search__field"
                                          type="search"
                                          tabIndex={0}
                                          autoComplete="off"
                                          autoCorrect="off"
                                          autoCapitalize="off"
                                          spellCheck="false"
                                          role="textbox"
                                          aria-autocomplete="list"
                                          placeholder="Search filters..."
                                          style={{ width: 238 }}
                                        />
                                      </li>
                                    </ul>
                                  </span>
                                </span>
                                <span
                                  className="dropdown-wrapper"
                                  aria-hidden="true"
                                />
                              </span>
                            </div>
                          </div>
                          <div>
                            <div className="form-group">
                              <label
                                className="control-label"
                                htmlFor="filter-popup_series"
                              >
                                Line
                              </label>{" "}
                              <select
                                id="filter-popup_series"
                                name="filter-popup[series][]"
                                placeholder="Search filters..."
                                className="form-control select2-hidden-accessible"
                                multiple=""
                                data-placeholder="Search filters..."
                                tabIndex={-1}
                                aria-hidden="true"
                              >
                                <option value={11309} data-count={8}>
                                  acne-prone skin treatment
                                </option>
                                <option value={11311} data-count={7}>
                                  anti-ageing treatment
                                </option>
                                <option value={11313} data-count={7}>
                                  clay &amp; algae masks
                                </option>
                                <option value={11321} data-count={12}>
                                  complementary treatment
                                </option>
                                <option value={11312} data-count={7}>
                                  exfoliation treatment
                                </option>
                                <option value={11319} data-count={6}>
                                  eye treatment
                                </option>
                                <option value={11314} data-count={3}>
                                  hand treatment
                                </option>
                                <option value={11317} data-count={10}>
                                  intensive face care
                                </option>
                                <option value={11310} data-count={9}>
                                  revitalising treatment
                                </option>
                                <option value={11306} data-count={8}>
                                  treatment for dry and normal skin
                                </option>
                                <option value={11308} data-count={8}>
                                  treatment for oily and combination skin
                                </option>
                                <option value={11307} data-count={8}>
                                  treatment for skin with dilated capillaries
                                </option>
                              </select>
                              <span
                                className="select2 select2-container select2-container--default"
                                dir="ltr"
                                style={{ width: 250 }}
                              >
                                <span className="selection">
                                  <span
                                    className="select2-selection select2-selection--multiple"
                                    role="combobox"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    tabIndex={-1}
                                  >
                                    <ul className="select2-selection__rendered">
                                      <li className="select2-search select2-search--inline">
                                        <input
                                          className="select2-search__field"
                                          type="search"
                                          tabIndex={0}
                                          autoComplete="off"
                                          autoCorrect="off"
                                          autoCapitalize="off"
                                          spellCheck="false"
                                          role="textbox"
                                          aria-autocomplete="list"
                                          placeholder="Search filters..."
                                          style={{ width: 243 }}
                                        />
                                      </li>
                                    </ul>
                                  </span>
                                </span>
                                <span
                                  className="dropdown-wrapper"
                                  aria-hidden="true"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary">
                        <div className="summary-box">
                          <div className="found">
                            <p>
                              <span className="found-label">
                                products Found{" "}
                              </span>{" "}
                              <span
                                className="number odometer odometer-auto-theme"
                                data-max={93}
                              />
                            </p>
                            <div className="odometer-inside">
                              <span className="odometer-digit">
                                <span className="odometer-digit-spacer">8</span>
                                <span className="odometer-digit-inner">
                                  <span className="odometer-ribbon">
                                    <span className="odometer-ribbon-inner">
                                      <span className="odometer-value">9</span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <span className="odometer-digit">
                                <span className="odometer-digit-spacer">8</span>
                                <span className="odometer-digit-inner">
                                  <span className="odometer-ribbon">
                                    <span className="odometer-ribbon-inner">
                                      <span className="odometer-value">3</span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </div>
                            <p />
                          </div>
                          <div
                            className="chosen-filters"
                            style={{ display: "none" }}
                          >
                            <h3>Active filters</h3>
                          </div>
                          <div
                            className="filters-clear filter-row"
                            style={{ display: "none" }}
                          >
                            <a href="#" className="clear-form">
                              <span className="filter-value">
                                Clear all filters
                                <span className="filter-value-remove">×</span>
                              </span>
                            </a>
                          </div>
                        </div>
                        <div className="summary-actions">
                          <p>Adjust search criteria or</p>
                          <button type="submit">Show products</button>
                          <a className="close-modal" href="/">
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterModal2;
