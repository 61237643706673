import React from "react";
// import "../Styles/elemenets/_tiles-grid.scss";
// import "../Styles/elemenets/tile.css";

// import "../Styles/elemenets/_tiles-content.scss";

const TileGrid = () => {
  const LT = require("../media/content/LT.png");
  const LD = require("../media/content/LD.png");
  const RT = require("../media/content/RT.png");
  const RD = require("../media/content/RD.png");

  return (
    <div className="">
    
{/* <div class="container text-center">
  <div class="row">
    <div class="col-lg-6 col-md-12 col-sm-12 col-xsm-12" style={{border:"1px solid red"}} >
      <div class="p-3"><img className="" src={LT} alt="" /></div>
    </div>
    <div class="col-lg-6 col-md-12 col-sm-12 col-xsm-12" style={{border:""}}>
      <div class="p-3"> <img className="" src={LD} alt="" /> </div>
    </div>
    <div class="col-lg-6 col-md-12 col-sm-12 col-xsm-12" style={{border:"1px solid black"}}>
      <div class="p-3"><img className="" src={RT} alt="" /></div>
    </div>
    <div class="col-lg-6 col-md-12 col-sm-12 col-xsm-12" style={{border:"1px solid black"}}>
      <div class="p-3"><img className="" src={RD} alt="" /></div>
    </div>
    
  </div>
</div> */}

<div className="container me-md-5 text-center mt-5 d-lg-flex d-md-flex d-sm-none d-xsm-none ">
  <div  className="row mt-2 col-lg-6 col-md-6 col-sm-12 col-xsm-12 display-md-1"><><img className="me-md-5" src={"./LT.jpg"} alt="" /> </><div className="row mt-2 col-lg-12 col-md-12 col-sm-12 col-xsm-12" ><img className="ms-lg-3 ms-md-3" src={"./LD.jpg"} alt="" /></div></div>
  <div className="row mt-2 col-lg-6 col-md-6 col-sm-12 col-xsm-12 " ><img className="ms-lg-3 ms-md-3" src={"./RT2.png"} alt="" /><div className="row mt-2 col-lg-12 col-md-12 col-sm-12 col-xsm-12" ><img className=" ms-lg-3 ms-md-3" src={"./RD2.png"} alt="" /> </div></div>

{/* <div className="row mt-2" style={{border:"1px solid black"}}><img className="" src={LD} alt="" /></div>
<div className="row mt-2" style={{border:"1px solid black"}}><img className="" src={RD} alt="" /></div> */}
</div> 
    </div>
  );
};

export default TileGrid;
