import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { toast } from "react-toastify";
import MetaData from "../Components/MetaData";
import { Container, Row, Col, Card, ListGroup, Button } from "react-bootstrap";
import { MdOutlineSettings } from "react-icons/md";
import { BiMoneyWithdraw } from "react-icons/bi";
import { SiWebmoney } from "react-icons/si";
import { FaUsers } from "react-icons/fa";
import { GiSkullCrossedBones } from "react-icons/gi";

import { MdWifiProtectedSetup } from "react-icons/md";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime); // fromNow()  // 4 hours ago

const AllUser = () => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  useEffect(() => {
    handleAllUser();
  }, []);
  const handleAllUser = async (e) => {
    try {
      setLoading(true);
      const { data } = await axios.get("/admin/users");
      if (data?.error) {
        setLoading(false);
        toast.error(data.error, { position: "top-center", autoClose: 3000 });
      } else {
        console.log(data.AllUser);
        setUsers(data.AllUser);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const setAllUsers = () => {
    const data = {
      columns: [
        {
          label: <span
          style={{
            color: "#73aa43",
            textDecorationLine: "underline",
          }}
        >
          User Id
        </span>,
          field: "id",
          sort: "asc",
        },
        {
          label: <span
          style={{
            color: "#73aa43",
            textDecorationLine: "underline",
          }}
        >
          Name
        </span>,
          field: "name",
          sort: "asc",
        },
        {
          label: <span
          style={{
            color: "#73aa43",
            textDecorationLine: "underline",
          }}
        >
          UserName
        </span>,
          field: "username",
          sort: "asc"
        },
        {
          label: <span
          style={{
            color: "#73aa43",
            textDecorationLine: "underline",
          }}
        >
          Referral Link
        </span>,
          field: "refrelLink",
          sort: "asc",
        },
        {
          label: <span
          style={{
            color: "#73aa43",
            textDecorationLine: "underline",
          }}
        >
          Balance
        </span>,
          field: "balance",
        },
        {
          label: <span
          style={{
            color: "#73aa43",
            textDecorationLine: "underline",
          }}
        >
          Signup Time
        </span>,
          field: "Signup",
          sort: "asc",
        },
        // {
        //     label: "Total Amount",
        //     field: "Amount",
        // },
      ],
      rows: [],
    };
    users?.forEach((user) => {
      data.rows.push({
        id: user._id,
        name: `${user.first_name} ${user.last_name}`,
        username: user.username,
        refrelLink: user.refrelUserName,
        balance: user.balance && Number.parseFloat(user.balance).toFixed(2),
        Signup: dayjs(user?.createdAt).fromNow(),
      });
    });
    return data;
  };
  return (
    <div>
      {/* <h1 className='text-center'>All Users</h1> */}
      <Fragment>
        <MetaData title={"All User"} />
        <div className="row">
          <div className="col-12 col-md-3 mt-5">
            <Card className="mt-5">
              <Card.Header
                style={{
                  color: "#73aa43",
                  textDecorationLine: "underline",
                  fontWeight: "bold",
                }}
              >
                Admin Menu{" "}
              </Card.Header>
              <ListGroup variant="flush">
                {/* <ListGroup.Item> Dashboard</ListGroup.Item> */}
                <ListGroup.Item>
                  <Link
                    to="/admin/all-users"
                    onMouseEnter={(e) => (
                      (e.target.style.color = "#73aa43"),
                      (e.target.style.textDecoration = "underline"),
                      (e.target.style.fontWeight = "bold")

                    )} // Change text color to grey on hover
                    onMouseLeave={(e) => (
                      (e.target.style.color = "black"),
                      (e.target.style.textDecoration = "none"),
                      (e.target.style.fontWeight = "normal")

                    )} // Change text color back to black on mouse leave
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <FaUsers className="mx-1" />
                    <span className="mx-2"> All Users </span>
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Link
                    onMouseEnter={(e) => (
                      (e.target.style.color = "#73aa43"),
                      (e.target.style.textDecoration = "underline"),
                      (e.target.style.fontWeight = "bold")

                    )} // Change text color to grey on hover
                    onMouseLeave={(e) => (
                      (e.target.style.color = "black"),
                      (e.target.style.textDecoration = "none"),
                      (e.target.style.fontWeight = "normal")

                    )} // Change text color back to black on mouse leave
                    style={{ textDecoration: "none", color: "black" }}
                    to="/admin/all-investments"
                  >
                    <SiWebmoney
                      className="mx-1"
                      onMouseEnter={(e) => (
                        (e.target.style.color = "#73aa43"),
                        (e.target.style.textDecoration = "underline")
                        )} // Change text color to grey on hover
                      onMouseLeave={(e) => (
                        (e.target.style.color = "black"),
                        (e.target.style.textDecoration = "none")
                      )} // Change text color back to black on mouse leave
                    />
                    <span className="mx-2"> All Investments</span>
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Link
                    onMouseEnter={(e) => (
                      (e.target.style.color = "#73aa43"),
                      (e.target.style.textDecoration = "underline"),
                      (e.target.style.fontWeight = "bold")

                    )} // Change text color to grey on hover
                    onMouseLeave={(e) => (
                      (e.target.style.color = "black"),
                      (e.target.style.textDecoration = "none"),
                      (e.target.style.fontWeight = "normal")

                    )} // Change text color back to black on mouse leave
                    style={{ textDecoration: "none", color: "black" }}
                    to="/admin/approve-investments"
                  >
                    <MdWifiProtectedSetup className="mx-1" />
                    <span className="mx-1"> Approve Investment</span>
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Link
                    onMouseEnter={(e) => (
                      (e.target.style.color = "#73aa43"),
                      (e.target.style.textDecoration = "underline"),
                      (e.target.style.fontWeight = "bold")

                    )} // Change text color to grey on hover
                    onMouseLeave={(e) => (
                      (e.target.style.color = "black"),
                      (e.target.style.textDecoration = "none"),
                      (e.target.style.fontWeight = "normal")

                    )} // Change text color back to black on mouse leave
                    style={{ textDecoration: "none", color: "black" }}
                    to="/admin/rejectdeposit"
                  >
                    <GiSkullCrossedBones className="mx-1" />
                    <span className="mx-1"> Reject Investment</span>
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Link
                    onMouseEnter={(e) => (
                      (e.target.style.color = "#73aa43"),
                      (e.target.style.textDecoration = "underline"),
                      (e.target.style.fontWeight = "bold")

                    )} // Change text color to grey on hover
                    onMouseLeave={(e) => (
                      (e.target.style.color = "black"),
                      (e.target.style.textDecoration = "none"),
                      (e.target.style.fontWeight = "normal")

                    )} // Change text color back to black on mouse leave
                    style={{ textDecoration: "none", color: "black" }}
                    to="/admin/withdraw"
                  >
                    <BiMoneyWithdraw className="mx-1" />{" "}
                    <span>Withdrawal Requests</span>
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Link
                    onMouseEnter={(e) => (
                      (e.target.style.color = "#73aa43"),
                      (e.target.style.textDecoration = "underline"),
                      (e.target.style.fontWeight = "bold")
                    )} // Change text color to grey on hover
                    onMouseLeave={(e) => (
                      (e.target.style.color = "black"),
                      (e.target.style.textDecoration = "none"),
                      (e.target.style.fontWeight = "normal")
                    )} // Change text color back to black on mouse leave
                    style={{ textDecoration: "none", color: "black" }}
                    to="/admin/chatHistory"
                  >
                    <MdWifiProtectedSetup className="mx-1" />
                    <span className="mx-1"> Chat History</span>
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Link
                    onMouseEnter={(e) => (
                      (e.target.style.color = "#73aa43"),
                      (e.target.style.textDecoration = "underline"),
                      (e.target.style.fontWeight = "bold")

                    )} // Change text color to grey on hover
                    onMouseLeave={(e) => (
                      (e.target.style.color = "black"),
                      (e.target.style.textDecoration = "none"),
                      (e.target.style.fontWeight = "normal")

                    )} // Change text color back to black on mouse leave
                    to="/admin/admin-setting"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <MdOutlineSettings className="mx-1" />
                    <span className="mx-1"> Admin Settings</span>
                  </Link>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </div>
          <div className="col-12 col-md-9">
            <Fragment>
              <h1
                className=" text-center display-3"
                style={{
                  color: "#73aa43",
                  textDecorationLine: "underline",
                  textShadow: "2px 2px 4px #73aa43",
                }}
              >
                {" "}
                Show All Users{" "}
              </h1>
              <MDBDataTable
                data={setAllUsers()}
                className="px-3 py-3 box-cart purple"
                bordered
                striped
                hover
              />
            </Fragment>
          </div>
        </div>
      </Fragment>
    </div>
  );
};

export default AllUser;
