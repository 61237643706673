import React, { useState } from "react";
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Alert,
} from "react-bootstrap";
import MetaData from "../../Components/MetaData";
import { useAuth } from "../../context/auth";

const NewWithDraw = () => {
  const [amount, setAmount] = useState("");
  const [balance, setBalance] = useState(''); // This should be fetched from a backend in a real application
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [auth, setAuth] = useAuth("");

  const handleWithdraw = (event) => {
    event.preventDefault();
    const numAmount = parseFloat(amount);
    if (numAmount > 0 && numAmount <= balance) {
      setBalance(auth?.user?.balance);
      setShowSuccess(true);
      setShowError(false);
      setAmount(""); // Reset amount after successful withdrawal
      window.location.reload();
    } else {
      setShowError(true);
      setShowSuccess(false);
    }
  };

  const canWithdraw =
    balance >= 10 && amount > 0 && parseFloat(amount) <= balance;

  return (
    <Container className="py-5 display-6">
      <MetaData title="WithDraw" />
      <h2 className="text-center mb-4">Withdraw Funds</h2>
      {showSuccess && (
        <Alert
          variant="success"
          onClose={() => setShowSuccess(false)}
          dismissible
        >
          Withdrawal of ${amount} successful!
        </Alert>
      )}
      {showError && (
        <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
          Error processing withdrawal. Please enter a valid amount.
        </Alert>
      )}
      <Form onSubmit={handleWithdraw}>
        <InputGroup className="mb-3">
          <InputGroup.Text>$</InputGroup.Text>
          <FormControl
            type="number"
            placeholder="Enter amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            min="1"
            max={balance}
            step="0.01"
            required
          />
        </InputGroup>
        <div className="d-grid">
          <Button type="submit" size="lg" disabled={!canWithdraw}>
            Withdraw
          </Button>
        </div>
      </Form>
      <div className="mt-3 text-center">
        <p>{`You current commision is ${
          balance && Number.parseFloat(balance).toFixed(2)
        }`}</p>
      </div>
    </Container>
  );
};

export default NewWithDraw;
