import React, { useState } from "react";
import "../Styles/SignUp.css";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link } from "react-router-dom";
import MetaData from "../Components/MetaData";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";

const SignUp = () => {
  // const [input, setInput] = useState({
  //   refrelUserName: "",
  //   first_name: "",
  //   last_name: "",
  //   email: "",
  //   country: "",
  //   city: "",
  //   address: "",
  //   phone_number: "",
  //   password: "",
  //   confirmPassword: "",
  // });
  const navigate = useNavigate();
  const [refrelUserName, setRefrelUserName] = useState("");
  const [first_name, setFirst_Name] = useState("");
  const [last_name, setLast_Name] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");

  const [loading, setLoading] = useState(false);

  const signupHandler = async (e) => {
    e.preventDefault();
    console.log("object");

    try {
      setLoading(true);
      const { data } = await axios.post(`/pre-signup`, {
        refrelUserName,
        first_name,
        last_name,
        email,
        country,
        city,
        address,
        phone_number,
        password,
        confirm_password,
      });
      if (data?.error) {
        toast.error(data.error, { position: "top-center", autoClose: 3000 });
        setLoading(false);
      } else {
        setLoading(false);
        toast.info("Please check your email to activate your account", {
          position: "top-center",
          autoClose: 3000,
        });
        navigate("/auth/otp-verification");
        console.log(data);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong. Try again!!", {
        position: "top-center",
        autoClose: 3000,
      });
      setLoading(false);
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(true);
  };
  const togglePasswordUnVisibility = () => {
    setShowPassword(false);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(true);
  };
  const toggleConfirmPasswordUnVisibility = () => {
    setShowConfirmPassword(false);
  };
  return (
    <div className="align-items-center d-flex justify-content-center ">
      <MetaData title="SignUp" />
      <form className="form_main display-6 fs-3 w-50 " onSubmit={signupHandler}>
        <p
          className="display-3 fs-3"
          style={{
            color: "black",
            //  textShadow: "2px 2px 4px #73aa43"
          }}
        >
          {" "}
          SIGN UP
        </p>
        {loading && (
          <p
            className="text-center"
            style={{ color: "#73aa43", marginRight: "75px" }}
          >
            {" "}
            <div className="spinner "></div>{" "}
          </p>
        )}

        <div className="inputContainer">
          <svg
            viewBox="0 0 24 24"
            fill="#2e2e2e"
            height="14"
            width="14"
            xmlns="http://www.w3.org/2000/svg"
            className="inputIcon"
          >
            <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM12 6C9.79 6 8 7.79 8 10C8 11.86 9.25 13.47 11 13.88V17H13V13.88C14.75 13.47 16 11.86 16 10C16 7.79 14.21 6 12 6Z" />
          </svg>

          <input
            placeholder="ReffrelUserName"
            id="username"
            className="inputField"
            type="text"
            value={refrelUserName}
            onChange={(e) => {
              setRefrelUserName(e.target.value);
            }}
          />
        </div>
        <div className="inputContainer">
          <svg
            viewBox="0 0 24 24"
            fill="#2e2e2e"
            height="14"
            width="14"
            xmlns="http://www.w3.org/2000/svg"
            className="inputIcon"
          >
            <path d="M19 2H5C3.9 2 3 2.9 3 4V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V4C21 2.9 20.1 2 19 2ZM18 17H6V16H18V17ZM18 14H6V13H18V14ZM18 11H6V10H18V11Z" />
          </svg>

          <input
            placeholder="FirstName"
            id="username"
            className="inputField"
            type="text"
            value={first_name}
            onChange={(e) => {
              setFirst_Name(e.target.value);
            }}
          />
        </div>
        <div className="inputContainer">
          <svg
            viewBox="0 0 24 24"
            fill="#2e2e2e"
            height="14"
            width="14"
            xmlns="http://www.w3.org/2000/svg"
            className="inputIcon"
          >
            <path d="M19 2H5C3.9 2 3 2.9 3 4V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V4C21 2.9 20.1 2 19 2ZM5 20V8H19V20H5ZM10 14H14V16H10V14ZM10 10H14V12H10V10Z" />
          </svg>

          <input
            placeholder="LastName"
            id="username"
            className="inputField"
            type="text"
            value={last_name}
            onChange={(e) => {
              setLast_Name(e.target.value);
            }}
          />
        </div>
        <div className="inputContainer">
          <svg
            viewBox="0 0 16 16"
            fill="#2e2e2e"
            height={16}
            width={16}
            xmlns="http://www.w3.org/2000/svg"
            className="inputIcon"
          >
            <path d="M13.106 7.222c0-2.967-2.249-5.032-5.482-5.032-3.35 0-5.646 2.318-5.646 5.702 0 3.493 2.235 5.708 5.762 5.708.862 0 1.689-.123 2.304-.335v-.862c-.43.199-1.354.328-2.29.328-2.926 0-4.813-1.88-4.813-4.798 0-2.844 1.921-4.881 4.594-4.881 2.735 0 4.608 1.688 4.608 4.156 0 1.682-.554 2.769-1.416 2.769-.492 0-.772-.28-.772-.76V5.206H8.923v.834h-.11c-.266-.595-.881-.964-1.6-.964-1.4 0-2.378 1.162-2.378 2.823 0 1.737.957 2.906 2.379 2.906.8 0 1.415-.39 1.709-1.087h.11c.081.67.703 1.148 1.503 1.148 1.572 0 2.57-1.415 2.57-3.643zm-7.177.704c0-1.197.54-1.907 1.456-1.907.93 0 1.524.738 1.524 1.907S8.308 9.84 7.371 9.84c-.895 0-1.442-.725-1.442-1.914z" />
          </svg>

          <input
            placeholder="Email"
            id="username"
            className="inputField"
            type="text"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className="inputContainer">
          <svg
            viewBox="0 0 24 24"
            fill="#2e2e2e"
            height="14"
            width="14"
            xmlns="http://www.w3.org/2000/svg"
            className="inputIcon"
          >
            <path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 20C7.6 20 4 16.4 4 12C4 7.6 7.6 4 12 4C16.4 4 20 7.6 20 12C20 16.4 16.4 20 12 20ZM12 6C9.8 6 8 7.8 8 10C8 12.2 9.8 14 12 14C14.2 14 16 12.2 16 10C16 7.8 14.2 6 12 6Z" />
          </svg>

          {/* <svg
  viewBox="0 0 24 24"
  fill="#2e2e2e"
  height="14"
  width="14"
  xmlns="http://www.w3.org/2000/svg"
  className="inputIcon"
>
  <path d="M12 2C7.58 2 4 5.58 4 10C4 14.42 7.58 18 12 18C16.42 18 20 14.42 20 10C20 5.58 16.42 2 12 2ZM12 16C8.69 16 6 13.31 6 10C6 6.69 8.69 4 12 4C15.31 4 18 6.69 18 10C18 13.31 15.31 16 12 16ZM12 6C9.79 6 8 7.79 8 10C8 12.21 9.79 14 12 14C14.21 14 16 12.21 16 10C16 7.79 14.21 6 12 6Z" />
</svg> */}

          <input
            placeholder="Country"
            id="username"
            className="inputField"
            type="text"
            value={country}
            onChange={(e) => {
              setCountry(e.target.value);
            }}
          />
        </div>
        <div className="inputContainer">
          <svg
            viewBox="0 0 24 24"
            fill="#2e2e2e"
            height="14"
            width="14"
            xmlns="http://www.w3.org/2000/svg"
            className="inputIcon"
          >
            <path d="M19 2H5C3.9 2 3 2.9 3 4V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V4C21 2.9 20.1 2 19 2ZM5 20V8H19V20H5ZM10 14H14V16H10V14ZM10 10H14V12H10V10Z" />
          </svg>

          <input
            placeholder="City"
            id="username"
            className="inputField"
            type="text"
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
            }}
          />
        </div>
        <div className="inputContainer">
          <svg
            viewBox="0 0 24 24"
            fill="#2e2e2e"
            height="14"
            width="14"
            xmlns="http://www.w3.org/2000/svg"
            className="inputIcon"
          >
            <path d="M12 2C7.03 2 3 6.03 3 11C3 14.25 5.52 17.48 9.64 20.61L11.3 22L13 20.61C17.15 17.48 19.71 14.25 19.71 11C19.71 6.03 15.69 2 12 2ZM12 15C9.24 15 7 12.76 7 10C7 7.24 9.24 5 12 5C14.76 5 17 7.24 17 10C17 12.76 14.76 15 12 15ZM12 7C10.9 7 10 7.9 10 9C10 10.1 10.9 11 12 11C13.1 11 14 10.1 14 9C14 7.9 13.1 7 12 7Z" />
          </svg>

          <input
            placeholder="Address"
            id="username"
            className="inputField"
            type="text"
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
        </div>
        <div className="inputContainer">
          <svg
            viewBox="0 0 24 24"
            fill="#2e2e2e"
            height="14"
            width="14"
            xmlns="http://www.w3.org/2000/svg"
            className="inputIcon"
          >
            <path d="M19 2H5C3.9 2 3 2.9 3 4V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V4C21 2.9 20.1 2 19 2ZM5 20V8H19V20H5ZM10 14H14V16H10V14ZM10 10H14V12H10V10Z" />
          </svg>

          <input
            placeholder="PhoneNumber"
            id="username"
            className="inputField"
            type="text"
            value={phone_number}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
          />
        </div>
        <div className="inputContainer">
          <svg
            viewBox="0 0 16 16"
            fill="#2e2e2e"
            height={16}
            width={16}
            xmlns="http://www.w3.org/2000/svg"
            className="inputIcon"
          >
            <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
          </svg>
          <input
            placeholder="Password"
            id="password"
            className="inputField"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span className="eye-icon">
            {showPassword ? (
              <FaEyeSlash onClick={togglePasswordUnVisibility} />
            ) : (
              <FaEye onClick={togglePasswordVisibility} />
            )}
          </span>
        </div>
        <div className="inputContainer">
          <svg
            viewBox="0 0 16 16"
            fill="#2e2e2e"
            height={16}
            width={16}
            xmlns="http://www.w3.org/2000/svg"
            className="inputIcon"
          >
            <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
          </svg>
          <input
            placeholder="ConfirmPassword"
            id="password"
            className="inputField"
            type={showConfirmPassword ? "text" : "password"}
            value={confirm_password}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <span className="eye-icon">
            {showConfirmPassword ? (
              <FaEyeSlash onClick={toggleConfirmPasswordUnVisibility} />
            ) : (
              <FaEye onClick={toggleConfirmPasswordVisibility} />
            )}
          </span>
        </div>
        <button id="button" type="submit" disabled={loading}>
          {loading ? "...Loading" : "Submit"}
        </button>
        <div className="signupContainer">
          <p className="signin"   style={{textDecoration:"underline"}}>Already have an account ?</p>{" "}
          <Link to="/login">Login</Link>
        </div>
      </form>
    </div>
  );
};

export default SignUp;
