import React, { useEffect, useState } from "react";
import { useAuth } from "../context/auth";
import MetaData from "../Components/MetaData";
import axios from "axios";
import { Badge } from "antd";
import { toast } from "react-toastify";
import profile from "./profile.css";
import { FaCopy } from "react-icons/fa";
import { LuCopy } from "react-icons/lu";
import "../Components/dashBoard.css"


const Profile = () => {
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [refrelLink, setRefrelLink] = useState("");
  const [balance, setBalance] = useState("");
  const [Level, setLevel] = useState([]);

  const [id, setId] = useState("");

  const [loading, setLoading] = useState("");

  const [headfirst_name, setHeadFirst_name] = useState("");
  const [headlast_name, setHeadLast_name] = useState("");

  const [memberfirst_name, setMemberFirst_name] = useState([]);
  const [memberlast_name, setMemberLast_name] = useState("");

  const [auth, setAuth] = useAuth();

  // useEffect(() => {

  //     if(auth?.user || auth?.YourHead) {
  //       setFirst_name(auth.user?.first_name);
  //       setLast_name(auth.user?.last_name);
  //       setRefrelLink(auth.user.refrelUserName)
  //         setHeadFirst_name(auth.YourHead?.first_name);
  //         setHeadLast_name(auth.YourHead?.last_name);
  //     }
  //   }, []);

  useEffect(() => {
    handleProfile();
  }, []);
  const handleProfile = async (e) => {
    try {
      setLoading(true);
      const { data } = await axios.get("/userprofile");
      if (data?.error) {
        setLoading(false);
        toast.error(data.error, { position: "top-center", autoClose: 3000 });
        if (
          data.error === "Your authentication time is over please login again"
        ) {
          localStorage.removeItem("auth");
          window.location.reload();
        }
      } else {
        console.log(data.Team);

        setAuth({ ...auth, user: data.user, Team: data.Team });

        let dataFromLS = JSON.parse(localStorage.getItem("auth"));
        dataFromLS.user = data.user;
        dataFromLS.team = data.Team;
        localStorage.setItem("auth", JSON.stringify(dataFromLS));
        console.log(auth)
        // setLoading(false);
        // toast.success("Profile Updating successfully");
        if (auth?.user || auth?.YourHead || auth?.team) {
          setFirst_name(auth.user?.first_name);
          setLast_name(auth.user?.last_name);
          setRefrelLink(auth.user.refrelUserName);
          setId(auth.user._id);
          setBalance(auth.user.balance);
          setLevel(auth?.user?.userLevel);
          // console.log(auth?.user?.userLevel[0]?.level);
          // console.log(auth?.user?.userLevel);

          setHeadFirst_name(auth?.userHead?.first_name);
          setHeadLast_name(auth.userHead?.last_name);

          setMemberFirst_name(data?.Team);
        }
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  console.log(Level);

  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(refrelLink)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
      })
      .catch((err) => console.error("Failed to copy:", err));
  };
  return (
    <div style={{ backgroundColor: "white" }}>
      <MetaData title="Profile" />

      <div className="container">
        <div className="row align-items-center Button2">
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <div
              className="card smooth-shadow-sm rounded-5 Button2"
              style={{
                //  border: "2px solid black",
                 borderStyle: "double",
                    borderWidth: "6px",
                    borderColor: "black",
                    // borderColor: "#73aa43",
                   }}
            >
              <div
                className="d-flex align-items-center justify-content-between
    pt-4 pb-6 px-4"
              >
                <div className="d-flex align-items-center">
                  <div
                    className="avatar-xxl avatar-indicators avatar-online me-2
        position-relative d-flex justify-content-end 
        align-items-end "
                  >
                    <img
                      style={{ color: "black" }}
                      src="./profileLogo3.jpg"
                      className="avatar-xxl
        rounded-circle border border-2 "
                      alt="Image"
                    />
                  </div>
                  <div className="lh-1">
                    <h2 className="mb-0">
                      <h1
                        style={{
                          color: "black",
                          textDecorationLine: "underline",
                          // textShadow: "2px 2px 4px #73aa43",
                        }}
                      >
                        {" "}
                        <b>{` ${first_name} ${last_name}  `}</b>
                      </h1>
                      <br />

                      <a href="#!" className="text-decoration-none"></a>
                    </h2>
                    <p className="mb-0 d-block">
                      {" "}
                      <h3>
                        <b style={{ color: "black" }}>SPONSORED : </b>
                        {`${headfirst_name} ${headlast_name}`}
                      </h3>
                      <h3>
                        {" "}
                        <b style={{ color: "black" }}>CURRENT COMMISSION : </b>
                        {` ${
                          balance && Number.parseFloat(balance).toFixed(2)
                        }$`}
                      </h3>
                    </p>
                  </div>
                </div>
              </div>
              <ul className="nav nav-lt-tab px-4" id="pills-tab" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    href="#"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    <h3 style={{ color: "black" }}>
                      {" "}
                      <p className="text-center ">
                        <b class=""> USER REFRRAL : </b>
                        {` ${refrelLink}   `}
                       
                        <button
                          style={{ border: "1px solid black" }}
                          onClick={copyToClipboard}
                        >
                          {copied ? "Copied!" : <LuCopy />}
                        </button>
                      </p>
                    </h3>
                    <br />{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <h2
          className="text-center mt-3 display-2"
          style={{
            color: "black",
            // color: "#73aa43",
            textDecorationLine: "underline",
            // textShadow: "3px 3px 4px #73aa43",
          }}
        >
          TEAM MEMBERS
        </h2>
        {/* <Badge.Ribbon 
     text={`${ad?.type} for ${ad.action}`}
     color={`${ ad?.action === "Sell"  ? "blue" : "orange"}`}
   > */}
        <div className="py-6">
          <div className="row " style={{ "--bs-gap": ".25rem 1rem;" }}>
            {
              memberfirst_name?.map((member) => (
                <div className="col-lg-3 mt-3 col-sm-6 col-md-4 Button2 ">
                  {/* card */}
                  <div
                    className="card  rounded-5"
                    style={{
                      borderStyle: "double",
                      borderWidth: "6px",
                      borderColor: "black",
                      // borderColor: "#73aa43",
                      // border: "2px solid black",
                    }}
                  >
                    <div>
                      {Level?.map((user, userIndex) => (
                        <div>
                          <Badge.Ribbon
                            key={userIndex}
                            text={` ${
                              member._id === user.userId
                                ? ` Level : ${user.level}`
                                : ""
                            } `}
                            color={"black"}
                          ></Badge.Ribbon>
                        </div>
                      ))}

                      <h1 className="text-center mt-3">
                        <div
                          style={{
                            color: "black",
                            textDecorationLine: "underline",
                          }}
                        >
                          {" "}
                          {id === member.YourHead ? "DIRECT" : "INDIRECT"}
                        </div>
                      </h1>
                      <hr style={{ color: "black", height: "3px" }} />
                    </div>
                    {/* avatar */}
                    {/* <div className="avatar avatar-xl mt-n7 ms-4">
                    <img
                      src="https://bootdey.com/img/Content/avatar/avatar1.png"
                      alt="Image"
                      className="rounded-circle border-4
        border-white-color-40"
                    />
                  </div> */}
                    {/* card body */}
                    <div className="card-body ">
                      {/* Title */}

                      <h3 className="mb-1 d-flex justify-content-between">
                        <b style={{ color: "black" }}>NAME:</b>
                        {` ${member.first_name} ${member.last_name}`}
                      </h3>
                      <h3 className="mb-1 d-flex justify-content-between">
                        {" "}
                        <b style={{ color: "black" }}>REFERRAL USER: </b>
                        {`   ${member.refrelUserName} `}
                      </h3>
                      <h3 className="mb-1 d-flex justify-content-between">
                        {" "}
                        <b style={{ color: "black" }}> TOTAL AMOUNT: </b>
                        {`   ${member.totalAmount} `}
                      </h3>
                      <h3 className="mb-1 d-flex justify-content-between">
                        {" "}
                        <b style={{ color: "black" }}>CURRENT COMMISSION: </b>
                        {`   ${ member.balance && Number.parseFloat(member.balance).toFixed(2)} `}
                        
                      </h3>
                      <p></p>
                      <div>{/* Dropdown */}</div>
                    </div>
                  </div>
                </div>
              ))
              // : (
              //   <h3
              //     className="text-center mt-3 display-2"
              //     style={{
              //       color: "#73aa43",
              //       textDecorationLine: "underline",
              //       textShadow: "3px 3px 4px #73aa43",
              //     }}
              //   >
              //     No Team Members Found !
              //   </h3>
              // )
            }{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
