import React from "react";
import ZiajaFirstPage from "./ZiajaFirstPage";
import "../Styles/_FilterModal.scss";
import "../Styles/_odameter.scss";
const FirstPage = () => {
  return (
    <div>
      <ZiajaFirstPage />
      <div className="container mt-5 w-100">
        <div className="row">
          <div className="col-lg-8 col-md-12 ">
            <h4> Category</h4> <br />
            <div className="d-flex justify-align-content-around flex-wrap grid gap-5">
              <div
                class="card border-dark "
                style={{
                  width: "18rem",
                  borderRadius: "0px",
                  cursor: "pointer",
                }}
              >
                <div class="card-body">
                  <p class="card-text">
                    Body Care <span className="float-end">160</span>
                  </p>
                  {/* <p className="float-end">160</p> */}
                </div>
              </div>
              <div
                class="card border-dark"
                style={{ width: "18rem", borderRadius: "0px" }}
              >
                <div class="card-body">
                  <p class="card-text">
                    FACE CARE<span className="float-end">171</span>
                  </p>
                </div>
              </div>
              <div
                class="card border-dark"
                style={{ width: "18rem", borderRadius: "0px" }}
              >
                <div class="card-body">
                  <p class="card-text">
                    HAIR CARE<span className="float-end">49</span>
                  </p>
                </div>
              </div>
              <div
                class="card border-dark"
                style={{ width: "18rem", borderRadius: "0px" }}
              >
                <div class="card-body">
                  <p class="card-text">
                    ORAL HYGIENE <span className="float-end">8</span>
                  </p>
                </div>
              </div>
              <div
                class="card border-dark"
                style={{ width: "18rem", borderRadius: "0px" }}
              >
                <div class="card-body">
                  <p class="card-text">
                    VEGAN <span className="float-end">316</span>
                  </p>
                </div>
              </div>
              {/* <div
                style={{
                  backgroundColor: "black",
                  width: "20rem",
                  height: "20rem",
                  marginLeft: "135px",
                }}
              >
                400
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* <div
        style={{
          backgroundColor: "black",
          width: "20rem",
          height: "20rem",
        }}
      >
        400 */}
      {/* </div> */}
      <div class="summary">
        <div class="summary-box">
          <div class="found">
            <p>
              <span class="found-label">products Found </span>{" "}
              <span class="number odometer odometer-auto-theme" data-max="400">
                <div class="odometer-inside">
                  <span class="odometer-digit">
                    <span class="odometer-digit-spacer">8</span>
                    <span class="odometer-digit-inner">
                      <span class="odometer-ribbon">
                        <span class="odometer-ribbon-inner">
                          <span class="odometer-value">4</span>
                        </span>
                      </span>
                    </span>
                  </span>
                  <span class="odometer-digit">
                    <span class="odometer-digit-spacer">8</span>
                    <span class="odometer-digit-inner">
                      <span class="odometer-ribbon">
                        <span class="odometer-ribbon-inner">
                          <span class="odometer-value">0</span>
                        </span>
                      </span>
                    </span>
                  </span>
                  <span class="odometer-digit">
                    <span class="odometer-digit-spacer">8</span>
                    <span class="odometer-digit-inner">
                      <span class="odometer-ribbon">
                        <span class="odometer-ribbon-inner">
                          <span class="odometer-value">0</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
              </span>
            </p>
          </div>

          <div class="chosen-filters" style={{ display: "none" }}>
            <h3>Active filters</h3>
          </div>

          <div class="filters-clear filter-row" style={{ display: "none" }}>
            <a href="#" class="clear-form">
              <span class="filter-value">
                Clear all filters
                <span class="filter-value-remove">×</span>
              </span>
            </a>
          </div>
        </div>

        <div class="summary-actions">
          <p>Adjust search criteria or</p>

          <button type="submit">Show products</button>

          <a class="close-modal" href="/">
            Cancel
          </a>
        </div>
      </div>
    </div>
  );
};

export default FirstPage;
