import React,{useEffect, useState} from "react";
import FullWidthSlider from "../Components/FullWidthSlider";
import { Carousel } from 'react-responsive-3d-carousel'

// import ProductsModal from "../Modal/ProductsModal";
import "../App.css";
// import "./check.cs/s"
import Footer from "../Components/Footer";
import "../Styles/elemenets/_social-block.scss";
import TileGrid from "../Components/TileGrid";
import NewFullWidthSlider from "../Components/NewFullWidthSlider";
import MetaData from "../Components/MetaData";
import { toast } from "react-toastify";
import axios from "axios";
import { useAuth } from "../context/auth";
import { Avatar } from "antd";
import Resizer from "react-image-file-resizer";


const Home = () => {
  const [auth, setAuth] = useAuth("");

  const containerStyle = {
    top: 0,
    left: 0,
    width: "98vw",
    height: "250vh",
    backgroundColor: "white",
    overflowX: "hidden",
  };
  // const [Picture1 , setPicture1] = useState()
  // const [Pictures, setPictures] = useState();

  // useEffect(() => {
  //   handleAllPicture();
  // }, []);
  // const handleAllPicture = async (e) => {
  //   try {
  //   //   setLoading(true);
  //     const { data } = await axios.get("/homePicture");
  //     if (data?.error) {
  //       // setLoading(false);
  //       toast.error(data.error, { position: "top-center", autoClose: 3000 });
  //     } else {
  //       console.log(data.Picture);
  //       setPictures(data.Picture);
  //     }
  //   } catch (err) {
  //     // setLoading(false);
  //     console.log(err);
  //   }
  // };


  // const [uploading, setUploading] = useState(false);
  // const [Image, setImage] = useState("");


  // const handleImageUpload1 = async (e) => {
  //   try {
      
  //     let file = e.target.files[0];
  //     if (file) {
  //       setUploading(true);
  //       new Promise(() => {
  //         Resizer.imageFileResizer(
  //           file,
  //           200,
  //           200,
  //           "JPEG",
  //           100,
  //           0,
  //           async (uri) => {
  //             console.log("UPLOAD Image:", uri);
  //             const { data } = await axios.post("/upload-image", {
  //               image: uri,
  //             });
  //             console.log(data);
  //       //       localStorage.setItem("image1", JSON.stringify(data));
  //       // let dataFromLS = JSON.parse(localStorage.getItem("image1"));

  //             // setAuth({...auth , picture1:dataFromLS.Location})
  //             console.log(auth)
  //             // console.log("yes",data.Location)
              
  //             setImage(data);
  //             setAuth({...auth , picture1:data.Location})
  //             console.log(auth)
  //             setUploading(false);
  //           },
  //           "base64"
  //         );
  //       });
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     // 'setUploading'(false);
  //   }
  // };

  // let dataFromLS1 = JSON.parse(localStorage.getItem("image1"));

  return (
    <div className="homepage " style={{overflowX:"hidden"} } >
      {/* <div chjlass="header_bottom">
        <div class="container-fluid">
          <nav class="navbar navbar-expand-lg custom_nav-container ">
            <a class="navbar-brand" href="index.html">
              <span>
                Inance
              </span>
            </a>

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class=""> </span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ">
                <li class="nav-item active">
                  <a class="nav-link" href="index.html">Home <span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="about.html"> About</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="service.html">Services</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="contact.html">Contact Us</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div> */}
      <MetaData title="HomePage" />
      {/* <FullWidthSlider /> */}
      
      <NewFullWidthSlider/>
        {/* { auth?.user?.role.includes("Admin") ? <div className="inputContainer">
              <label className="upload mb-3">
                {uploading ? "Processing..." : "Upload Picture 1"}
                <input
                  hidden
                  type="file"
                  accept="image/*"
                  multiple
                  // disabled={auth?.user?.role.includes("Admin")}
                  onChange={handleImageUpload1}
                />
              </label>
              <a href={Image?.Location} className="mx-3">
                (
                <Avatar
                  src={Image?.Location}
                  // alt={invest.picture?[0].Key}
                  shape="square"
                  size="72"
                  className="mx-1"
                  // onClick={()=> deleteImageUpload()}
                />
                ){" "}
              </a>
            </div> : ""} */}

{/* <Carousel statusColor="red" statusSize="large" depth={10}> */}
    {/* <a href="">  <img src="./fimage5.jpg" alt="example-image-1" /></a> */}
      {/* <img src="./fimage3.jpg" alt="example-image-1" /> */}
      {/* <img src="./fimage4.jpg" alt="example-image-1" /> */}
      {/* <video src="https://example-video-url" muted autoPlay loop /> */}
      {/* <iframe src="https://www.youtube.com/embed/example" title="YouTube video player" frameborder="0"/> */}
      {/* <div>
        <p>Hello</p>
      </div> */}
    {/* </Carousel> */}

      <section className="cms-block h-100 cms-block-1314 cms-block-homepage-before-grid cms-block-home_top "></section>
      <div className="wrapper-fs">
        <div className="wrapper">
          <TileGrid />
        </div>
      </div>
      <section
        className="cms-block cms-block-1321 cms-block-homepage-bottom cms-block-home_bottom "
        style={{ backgroundColor: "white" }}
      >
        <p style={{ textAlign: "center" }}>
          &nbsp;&nbsp;
          <br />
          <span style={{ fontSize: 28 }}>@ziaja_international</span>
        </p>
        <p style={{ textAlign: "center" }}>
          <span style={{ fontSize: 20 }}>
            Follow us on&nbsp;
            <a
              href="https://www.instagram.com/ziaja_international/"
              style={{ textDecoration: "none", color: "black" }}
            >
              Instagram
            </a>{" "}
            &amp;{" "}
            <a
              href="https://pl-pl.facebook.com/ziajainternational"
              style={{ textDecoration: "none", color: "black" }}
            >
              Facebook
            </a>
            <br />
          </span>
        </p>
        <div className="instagram-feed">
          {/* <div className="instagram-items">
            <div className="instagram-item">
              <a href="https://www.instagram.com/p/C5GxJ4mL-zp/">
                <img
                  className="img-responsive"
                  src="https://scontent.cdninstagram.com/v/t51.29350-15/434803746_940457337395366_1494272603677966580_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=18de74&_nc_ohc=Qxn3FM4K3IEAX-jrQF-&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfCn_pCt_Yr2Hrt-NxWtFmzOt6mOAJp401cbA63v3e8-xQ&oe=66109CD3"
                  alt="Instagram"
                />
              </a>
            </div>
            <div className="instagram-item">
              <a href="https://www.instagram.com/p/C48dJ1MMZG-/">
                <img
                  className="img-responsive"
                  src="https://scontent.cdninstagram.com/v/t51.29350-15/434314381_1918305441960240_2228369545267853438_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=18de74&_nc_ohc=R5kM8SEHOOAAX8Ce1b0&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDCZHxJta4IwM4rxz7kQxGzkD5QWXEEFTffdI5dIa9svw&oe=6610A677"
                  alt="Instagram"
                />
              </a>
            </div>
            <div className="instagram-item">
              <a href="https://www.instagram.com/p/C4x3bA7t45i/">
                <img
                  className="img-responsive"
                  src="https://scontent.cdninstagram.com/v/t39.30808-6/434044963_932038302262656_7841659363262334076_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=18de74&_nc_ohc=cN9JP9dEEmIAX-Vi8Zh&_nc_oc=AdgHidMXJqPQtWM_UlngxBc61S92QCQ9K8GT9xu1mDTUxPtYUfcJ-RIqFsnxuf3yWMQ&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfA7b765Y3AXdaUiWsudV7tWffhvZPx8UUUArHJKqKe12w&oe=661099E7"
                  alt="Instagram"
                />
              </a>
            </div>
            <div className="instagram-item">
              <a href="https://www.instagram.com/p/C4vlr12Ljpg/">
                <img
                  className="img-responsive"
                  src="https://scontent.cdninstagram.com/v/t51.29350-15/434060514_1125936995211623_3313831887838848889_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=18de74&_nc_ohc=axBkTlQGr7EAX_FEMzX&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfD6B1uIHqHeF3d7pjeYzIVLKRwBiRe3C1GQIzdahXB04Q&oe=6610945F"
                  alt="Instagram"
                />
              </a>
            </div>
            <div className="instagram-item">
              <a href="https://www.instagram.com/p/C4qQ0yZt3Vy/">
                <img
                  className="img-responsive"
                  src="https://scontent.cdninstagram.com/v/t39.30808-6/433622487_929880302478456_61463118277231577_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=18de74&_nc_ohc=GFj07q9E9PUAX84o7E9&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfCUbU9VeeB3_BGOcGT80aCjFT-9MHSXtug0HNnVf-EsZQ&oe=66109767"
                  alt="Instagram"
                />
              </a>
            </div>
            <div className="instagram-item">
              <a href="https://www.instagram.com/p/C4nBKbErc0a/">
                <img
                  className="img-responsive"
                  src="https://scontent.cdninstagram.com/v/t51.29350-15/433435079_1081514642926251_6164870350389102456_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=18de74&_nc_ohc=6YFcrh8MsLcAX_muReK&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfAicMgDg0mjaEq75-s6yJef8662_OEXuxA41--GMz9Bxg&oe=6610850F"
                  alt="Instagram"
                />
              </a>
            </div>
            <div className="instagram-item">
              <a href="https://www.instagram.com/p/C4iZtwzKjKX/">
                <img
                  className="img-responsive"
                  src="https://scontent.cdninstagram.com/v/t39.30808-6/432403442_927506769382476_6926939053095999897_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=18de74&_nc_ohc=AZ_Cm16XUpMAX-X_FwX&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfBzb9l7uiF3iggLQsGFPdvpzS7r5FclMrNF1lwVaixlEw&oe=66107BB3"
                  alt="Instagram"
                />
              </a>
            </div>
            <div className="instagram-item">
              <a href="https://www.instagram.com/p/C4a6VkmMxSa/">
                <img
                  className="img-responsive"
                  src="https://scontent.cdninstagram.com/v/t51.29350-15/432283976_964190201236800_7805094089992526877_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=18de74&_nc_ohc=lRrgX6loeqIAX8qynla&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfD8Jfl8u1lF9nh_rwYOOPEKni-t5WFCBuVL1mOkmGABew&oe=66107F5E"
                  alt="Instagram"
                />
              </a>
            </div>
            <div className="instagram-item">
              <a href="https://www.instagram.com/p/C4U8Bx-L2m-/">
                <img
                  className="img-responsive"
                  src="https://scontent.cdninstagram.com/v/t51.29350-15/431989197_391655990335832_7422628926497540375_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=18de74&_nc_ohc=oAFxMbYlCUMAX8MiqU_&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfBE10Etu-mvBkf6WPa_fQ7IMsfjFHe77sufYWHnpETAUg&oe=6610761B"
                  alt="Instagram"
                />
              </a>
            </div>
            
          </div> */}

          <div className="instagram-items">
            <div className="instagram-item">
              <a href="https://www.instagram.com/p/C5yPfRVsNsW/">
                <img
                  className="img-responsive"
                  src="https://scontent.cdninstagram.com/v/t39.30808-6/465378797_1119350543531430_1605670392192076391_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=18de74&_nc_ohc=fwNCVaned8kQ7kNvgGgj_Nw&_nc_zt=23&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&_nc_gid=ApNCPovrh5-SdTgdrRZObIJ&oh=00_AYCdsp8XB5Q0PAXDbxsyHniMSty1CB73ytwwMSMmvg7qMw&oe=673A6D53"
                  alt="Instagram"
                />
              </a>
            </div>
            <div className="instagram-item">
              <a href="https://www.instagram.com/p/C5qlFK4tStg/">
                <img
                  className="img-responsive"
                  src="https://scontent.cdninstagram.com/v/t39.30808-6/465875161_1115975587202259_450389282387361969_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=18de74&_nc_ohc=HzYZTuBbis0Q7kNvgGvvTWd&_nc_zt=23&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&_nc_gid=ApNCPovrh5-SdTgdrRZObIJ&oh=00_AYDd5WPFZhoCYVgsXMigOZaUIBswNzlVh0lhR3W21fTjcA&oe=673A5F15"
                  alt="Instagram"
                />
              </a>
            </div>
            <div className="instagram-item">
              <a href="https://www.instagram.com/p/C5ny90oMxBN/">
                <img
                  className="img-responsive"
                  src="https://scontent.cdninstagram.com/v/t39.30808-6/465553287_1115972513869233_5449078935349139973_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=18de74&_nc_ohc=DOpapRHIKU0Q7kNvgF6vi3N&_nc_zt=23&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&_nc_gid=ApNCPovrh5-SdTgdrRZObIJ&oh=00_AYCgH2wUsYPDSewcjZ782Tquy5prS3Kd41iVF_A_c1bbwA&oe=673A5744"
                  alt="Instagram"
                />
              </a>
            </div>
            <div className="instagram-item">
              <a href="https://www.instagram.com/p/C5i18cSNgJ7/">
                <img
                  className="img-responsive"
                  src="https://scontent.cdninstagram.com/v/t51.29350-15/465684486_1507217043320363_6252498557669264898_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=18de74&_nc_ohc=rBYrhIYX6QkQ7kNvgHxs4s9&_nc_zt=23&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&_nc_gid=ApNCPovrh5-SdTgdrRZObIJ&oh=00_AYCq8AdDZsDVdVvj_Gx020t-pcD5ENagZ23mS46RYE2SPw&oe=673A537E"
                  alt="Instagram"
                />
              </a>
            </div>
            <div className="instagram-item">
              <a href="https://www.instagram.com/p/C5c8hWBoFV0/">
                <img
                  className="img-responsive"
                  src="https://scontent.cdninstagram.com/v/t51.75761-15/465258246_18463320688056523_5728404988120822871_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=18de74&_nc_ohc=776Bo-CYtzYQ7kNvgHVbjlz&_nc_zt=23&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&_nc_gid=ApNCPovrh5-SdTgdrRZObIJ&oh=00_AYBTp-xbNVohkcCisKTz59X_dHVZZgADVPTnCMgbodYFfA&oe=673A5420"
                  alt="Instagram"
                />
              </a>
            </div>
            <div className="instagram-item">
              <a href="https://www.instagram.com/p/C5YhrjwNIWF/">
                <img
                  className="img-responsive"
                  src="https://scontent.cdninstagram.com/v/t51.29350-15/464977298_3918606315080298_1832366081536400744_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=18de74&_nc_ohc=jrVNZ15zVIAQ7kNvgFPKfqU&_nc_zt=23&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&_nc_gid=ApNCPovrh5-SdTgdrRZObIJ&oh=00_AYA01BcYDKW3zFygkcR8rk6Oxr2oHZv0imdTYngVvVW02g&oe=673A65C3"
                  alt="Instagram"
                />
              </a>
            </div>
            <div className="instagram-item">
              <a href="https://www.instagram.com/p/C5TcAE4Nhm_/">
                <img
                  className="img-responsive"
                  src="https://scontent.cdninstagram.com/v/t39.30808-6/463759237_1104016088398209_8132976427063496757_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=18de74&_nc_ohc=1UF-GgGrzsMQ7kNvgH9iMor&_nc_zt=23&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&_nc_gid=ApNCPovrh5-SdTgdrRZObIJ&oh=00_AYA-XsAj27BnQbFGD5Gpq-GOXoXzdCeKue7ovarQ9eArow&oe=673A6AFC"
                  alt="Instagram"
                />
              </a>
            </div>
            <div className="instagram-item">
              <a href="https://www.instagram.com/p/C5GxJ4mL-zp/">
                <img
                  className="img-responsive"
                  src="https://scontent.cdninstagram.com/v/t39.30808-6/464008580_1102247255241759_3820260029002521188_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=18de74&_nc_ohc=pBOe-O7f9YEQ7kNvgE7UwEx&_nc_zt=23&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&_nc_gid=ApNCPovrh5-SdTgdrRZObIJ&oh=00_AYCNDat5oN3YiQMLOqBB4g2pfBbbWiuhJNzmqAQpsKHq2A&oe=673A69E3"
                  alt="Instagram"
                />
              </a>
            </div>
            <div className="instagram-item">
              <a href="https://www.instagram.com/p/C48dJ1MMZG-/">
                <img
                  className="img-responsive"
                  src="https://scontent.cdninstagram.com/v/t51.75761-15/463956261_18460426606056523_5243123378740053590_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=18de74&_nc_ohc=sE4mejgPZV4Q7kNvgGYgeNm&_nc_zt=23&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&_nc_gid=ApNCPovrh5-SdTgdrRZObIJ&oh=00_AYCheMxlcGp59IQezImWOMTMtfUXkyPWe64z8ExXxSY8mQ&oe=673A81C7"
                  alt="Instagram"
                />
              </a>
            </div>
            <div className="instagram-item">
              <a href="https://www.instagram.com/p/C4x3bA7t45i/">
                <img
                  className="img-responsive"
                  src="https://scontent.cdninstagram.com/v/t51.75761-15/463736921_18459882277056523_247180949001321611_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=18de74&_nc_ohc=K5-YoxjjNx4Q7kNvgFBCk3V&_nc_zt=23&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&_nc_gid=ApNCPovrh5-SdTgdrRZObIJ&oh=00_AYAuKbczBGPqk30K_k0SpUw-SMzN1gz_6MGDus3cf8UCFg&oe=673A51E8"
                  alt="Instagram"
                />
              </a>
            </div>
          </div>
        </div>
        <p style={{ textAlign: "right" }}>
          <span style={{ fontSize: 18 }}>
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "} */}
            <br />
          </span>
        </p>
        {/* <div className="row">
       <div className="col-9">
      <hr  style={{border:"1px solid black"}}/>
      </div>
      <div className="col-2 ">
      <a
                  href="https://pl-pl.facebook.com/ziajainternational"
                  className="m-2"
                >
                  <img
                    src={require("../media/facebook_new.png")}
                    alt="Facebook"
                  />
                </a>
                <a
                  href="https://www.instagram.com/ziaja_international/"
                  className="ms-2"
                >
                  <img
                  className=""
                    src={require("../media/instagram_new.png")}
                    alt="Instagram"
                  />
                                  
                                  <hr  style={{border:"1px solid black"}}/>
                </a>
               
      </div>
      
      <div className="col-3">
      <hr  style={{border:"1px solid black"}}/>
      </div>
      </div> */}
      </section>
      {/* <ProductsModal /> */}
      <Footer />
      
    </div>
  );
};

export default Home;
