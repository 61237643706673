import {useEffect, useState} from "react";
import {Outlet} from  "react-router-dom";
import axios from 'axios';
import { useAuth } from "../context/auth";
import { toast } from "react-toastify";


 function PrivateRoute() {
    /* context */
    const [auth, setAuth] = useAuth();
    /* states */
    const [ok, setOk] =  useState(false);
    /* make back-end request useEffect */
    useEffect(()=>{
        if (auth?.token) fetchCurrentUser()

    }, [auth?.token]);

    const fetchCurrentUser = async() => {
        try {
          const {data} = await axios.get("/loggedIn-user", {
              headers: {
                 Authorization: auth?.token
              }
              
          });
          if(data?.error){
            setOk(false);
            toast.info(data?.error ,  { position: "top-center", autoClose: 3000 })

          }
          setOk(true);
          
        } catch (err){
          setOk(false);
        }
    };

    return ok ? <Outlet /> : <h1>You are not allow to access this without login</h1>;

}

export default PrivateRoute;